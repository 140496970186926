import React, { FC } from 'react';
import { createMuiTheme, MuiThemeProvider } from '@material-ui/core';

const theme = createMuiTheme({
  palette: {
    primary: { main: '#223a7e' },
    secondary: { main: '#d82622' },
  },
});

export const ThemeProvider: FC = ({ children }) => {
  return <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>;
};
