import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { StateContext } from '../context';
import { ApiService, AuthService, User } from '../core';
import { withModal } from './withModal';

interface Props {
  data: User;
}

export const UserRemove: FC<Props> = withModal(({ data, closeModal }) => {
  const [canRemove, setCanRemove] = useState(false);
  const { withLoading, updateRegistrations, updateGroups } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRegistration = withLoading(async () => {
    try {
      await AuthService.adminDeleteUser({ Username: data.cognitoId! });
      await ApiService.updateUser({
        input: { id: data.id, expectedVersion: data.version, cognitoId: null, accessRole: null },
      });
      await Promise.all(
        data.groupsRegistrar?.items
          ?.map((item) => item?.groupId || '')
          .map((groupId) => ApiService.deleteUserGroupRegistrar({ input: { userId: data.id, groupId } })) || []
      );
      updateRegistrations();
      updateGroups();
      closeModal();
      enqueueSnackbar(`Successfully removed user access for ${data.firstName} ${data.lastName}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Failed to remove user access for ${data.firstName} ${data.lastName}`, { variant: 'error' });
    }
  });

  return (
    <>
      <Typography variant="overline" color="secondary">
        Remove user access
      </Typography>
      <Box height="10px" />
      <Typography color="primary">
        Are you sure you want to remove user access for{' '}
        <b>
          {data.firstName} {data.lastName}
        </b>
        ?
      </Typography>
      <Box height="20px" />
      <Typography color="primary">
        To confirm, type <i>Remove</i> in the box:
      </Typography>
      <Box height="10px" />
      <TextField
        variant="outlined"
        placeholder="Remove"
        onChange={(event) => setCanRemove(event.target.value === 'Remove')}
        fullWidth
      />
      <Box height="30px" />
      <Box display="flex" justifyContent="flex-end">
        <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={!canRemove}
          color="secondary"
          variant="contained"
          style={{ margin: '0.5rem' }}
          onClick={() => deleteRegistration()}
        >
          Remove
        </Button>
      </Box>
    </>
  );
});
