import { Hub } from 'aws-amplify';
import { CreateUserInput, DeleteUserInput, UpdateUserInput } from '../API';
import { ApiService } from './api.service';
import { HubChannel, HubEvent } from './enums';
import { User } from './graphql.types';

export abstract class RegistrationService {
  static create = (user: CreateUserInput, groupIds: string[] = [], registrarGroupIds: string[] = []): Promise<User> => {
    return ApiService.createUser({ input: user })
      .then((userCreated) => {
        return Promise.all([
          ...groupIds.map((groupId) => {
            return ApiService.createUserGroup({ input: { userId: userCreated!.id, groupId } });
          }),
          ...registrarGroupIds.map((groupId) => {
            return ApiService.createUserGroupRegistrar({ input: { userId: userCreated!.id, groupId } });
          }),
        ])
          .then(() => userCreated!)
          .catch((errorCreateUserGroup) => {
            const { id, version: expectedVersion, groups } = userCreated!;
            return RegistrationService.delete(
              { id, expectedVersion },
              groups?.items?.map((item) => item!.groupId)
            )
              .catch(() => {
                throw errorCreateUserGroup;
              })
              .then(() => {
                throw errorCreateUserGroup;
              });
          });
      })
      .catch((_error) => {
        throw new Error(`Unable to create registration.`);
      })
      .then((user) => {
        Hub.dispatch(HubChannel.Registration, { event: HubEvent.Create, data: { user: user.id } });
        return user;
      });
  };

  static delete = (user: DeleteUserInput, groupIds: string[] = []): Promise<User> => {
    return ApiService.getUser({ id: user.id! }).then((getUser) =>
      ApiService.deleteUser({ input: user })
        .then((userDeleted) => {
          return Promise.all(
            groupIds.map((groupId) => {
              return ApiService.deleteUserGroup({ input: { userId: userDeleted!.id, groupId } }).catch(() => {});
            })
          ).then(() => getUser!);
        })
        .catch((_error) => {
          throw new Error(`Unable to delete registration.`);
        })
        .then((user) => {
          Hub.dispatch(HubChannel.Registration, { event: HubEvent.Delete, data: { user } });
          return user;
        })
    );
  };

  static update = (user: UpdateUserInput, groupIds: string[] = [], existingGroupIds: string[] = []): Promise<User> => {
    return ApiService.updateUser({ input: user })
      .then((userUpdated) => {
        const createUserGroups = groupIds.filter((x) => !existingGroupIds.includes(x));
        const deleteUserGroups = existingGroupIds.filter((y) => !groupIds.includes(y));
        return Promise.all(
          createUserGroups
            .map((groupId) => ApiService.createUserGroup({ input: { userId: userUpdated!.id, groupId } }))
            .concat(
              deleteUserGroups.map((groupId) =>
                ApiService.deleteUserGroup({ input: { userId: userUpdated!.id, groupId } })
              )
            )
        ).then(() => userUpdated!);
      })
      .catch((_error) => {
        throw new Error(`An error occurred while updating the registration.`);
      })
      .then((user) => {
        Hub.dispatch(HubChannel.Registration, { event: HubEvent.Update, data: { user: user.id } });
        return user;
      });
  };

  static updateRegistrarGroups = (
    userId: string,
    groupRegistrarIds: string[] = [],
    existingGroupRegistrarIds: string[] = [],
    existingGroupMemberIds: string[] = []
  ): Promise<string> => {
    const createUserGroups = groupRegistrarIds.filter((x) => !existingGroupRegistrarIds.includes(x));
    const deleteUserGroups = existingGroupRegistrarIds.filter((y) => !groupRegistrarIds.includes(y));
    return Promise.all([
      createUserGroups
        .map((groupId) => ApiService.createUserGroupRegistrar({ input: { userId, groupId } }))
        .concat(deleteUserGroups.map((groupId) => ApiService.deleteUserGroupRegistrar({ input: { userId, groupId } }))),
      createUserGroups
        .filter((groupId) => !existingGroupMemberIds.find((x) => x === groupId))
        .map((groupId) => ApiService.createUserGroup({ input: { userId, groupId } }))
        .concat(
          deleteUserGroups
            .filter((groupId) => existingGroupMemberIds.find((x) => x === groupId))
            .map((groupId) => ApiService.deleteUserGroup({ input: { userId, groupId } }))
        ),
    ])
      .then(() => userId)
      .catch((_error) => {
        throw new Error(`An error occurred while updating the registration.`);
      })
      .then((user) => {
        Hub.dispatch(HubChannel.Registration, { event: HubEvent.Update, data: { user: userId } });
        return user;
      });
  };
}
