import React, { FC, ReactElement, useContext } from 'react';
import { ModalContext } from '../context';

interface Props {
  openModal: (content?: ReactElement, width?: number) => void;
  closeModal: () => void;
}

export const withModal = <T extends object>(Component: FC<T & Props>): FC<T> => (props) => {
  const { openModal, closeModal } = useContext(ModalContext);
  return <Component {...props} openModal={openModal} closeModal={closeModal} />;
};
