import { Avatar, createStyles, makeStyles, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { AccessRole, ACCESS_ROLE } from '../core';

const useStyles = makeStyles((theme) =>
  createStyles({
    [AccessRole.Admin]: {
      color: theme.palette.secondary.contrastText,
      backgroundColor: theme.palette.secondary.main,
    },
    [AccessRole.Registrar]: {
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.primary.main,
    },
    [AccessRole.GroupLeader]: {},
  })
);

interface Props {
  accessRole: AccessRole;
}

export const AccessAvatar: FC<Props> = ({ accessRole }) => {
  const classes = useStyles();
  return (
    <Tooltip title={ACCESS_ROLE[accessRole]} arrow>
      <Avatar className={classes[accessRole]} variant="rounded" style={{ width: '18px', height: '18px' }}>
        {ACCESS_ROLE[accessRole][0]}
      </Avatar>
    </Tooltip>
  );
};
