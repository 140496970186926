import { Chip, ChipProps } from '@material-ui/core';
import React, { FC } from 'react';
import { AccessRole, ACCESS_ROLE } from '../core';

interface Props extends ChipProps {
  role: AccessRole;
}

export const AccessChip: FC<Props> = ({ role, ...rest }) => {
  return (
    <Chip
      size="small"
      label={ACCESS_ROLE[role]}
      color={role === AccessRole.Admin ? 'secondary' : role === AccessRole.Registrar ? 'primary' : 'default'}
      variant="default"
      {...rest}
    />
  );
};
