import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import React, { FC, useState } from 'react';
import { InputText, InputTextProps } from './InputText';

interface SelectOption {
  inputValue?: string;
  label: string;
}

const filter = createFilterOptions<SelectOption>();

type Props = InputTextProps & {
  options: string[];
  shouldSortOptions?: boolean;
  freeSolo?: boolean;
};

export const InputSelect: FC<Props> = (props) => {
  const {
    autoComplete,
    defaultValue,
    freeSolo,
    fullWidth = true,
    label,
    name,
    options,
    shouldSortOptions = true,
    rules,
    variant,
    ...rest
  } = props;
  const [value, setValue] = useState<SelectOption | null>(defaultValue ? { label: defaultValue as string } : null);
  let uniqueOptions = options.filter((value, index, array) => value && array.indexOf(value) === index);
  uniqueOptions = shouldSortOptions ? uniqueOptions.sort() : uniqueOptions;

  const commonProps = {
    autoComplete: true,
    autoSelect: true,
    freeSolo,
    fullWidth,
    openOnFocus: true,
    renderInput: (params: any) => <InputText {...params} name={name} label={label} variant={variant} rules={rules} />,
    ...rest,
  };

  return freeSolo ? (
    <Autocomplete
      {...commonProps}
      getOptionLabel={(option) => option.inputValue || option.label || (option as any)}
      filterOptions={(options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== '' && !filtered.map((option) => option.label).includes(params.inputValue)) {
          filtered.push({
            inputValue: params.inputValue,
            label: `Add "${params.inputValue}"`,
          });
        }
        return filtered;
      }}
      onChange={(_event, newValue) => {
        if (typeof newValue === 'string') {
          setValue({ label: newValue });
        } else if (newValue && newValue.inputValue) {
          setValue({ label: newValue.inputValue });
        } else {
          setValue(newValue);
        }
      }}
      options={uniqueOptions.map((option) => ({ label: option })) as SelectOption[]}
      renderOption={(option) => option.label}
      value={value}
    />
  ) : (
    <Autocomplete
      {...commonProps}
      defaultValue={defaultValue}
      options={uniqueOptions}
      onChange={commonProps.onChange as any}
    />
  );
};
