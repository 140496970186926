import { Hub } from 'aws-amplify';
import { ActivityService } from './activity.service';
import { HubChannel } from './enums';

export abstract class HubService {
  static initalize = () => {
    Hub.listen(HubChannel.Registration, async ({ payload }) => {
      const { event, data } = payload;
      await ActivityService.logRegistration(event, data.user);
    });
    Hub.listen(HubChannel.Error, async ({ payload }) => {
      const { message } = payload;
      await ActivityService.logError(message);
    });
  };
}
