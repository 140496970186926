import { Box, Button, Chip, IconButton, SvgIconProps, SvgIconTypeMap, Tooltip, Typography } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import {
  AddBox,
  AttachMoney,
  BugReport,
  Check,
  CheckRounded,
  ChevronLeft,
  Clear,
  DeleteOutline,
  Edit,
  Visibility,
  VisibilityOff,
} from '@material-ui/icons';
import { Hub } from 'aws-amplify';
import MaterialTable, { Column, EditComponentProps, MTableEditField } from 'material-table';
import { useSnackbar } from 'notistack';
import React, { FC, ReactElement, useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { StateContext } from '../context';
import {
  AccessRole,
  ApiService,
  CustomUser,
  DiscountService,
  EMOJI2,
  formatCurrency,
  Group,
  HubChannel,
  HubEvent,
  isPermitted,
  MasterSwitchItem,
  PermissionName,
  PRICING,
  RegistrationService,
  Totals,
  UpdateUserInput,
  useMaxTableBodyHeight,
  User,
} from '../core';

interface Props {
  isGroupRegistrar?: boolean;
}

export const GroupRegistration: FC<Props> = ({ isGroupRegistrar = false }) => {
  const { user, permissions, groups, registrations, masterSwitchs, updateRegistrations, updateUser } =
    useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();
  const { id: groupId } = useParams<{ id?: string }>();
  const [group, setGroup] = useState<Group | undefined>();
  const [totals, setTotals] = useState<Totals>({ retail: 0, discount: 0, actual: 0, groupItemized: [] });
  const [filteredRegistrations, setFilteredRegistrations] = useState<CustomUser[]>([]);
  const [isGroupMember, setIsGroupMember] = useState<boolean>(false);
  const [canEditReceptions, setCanEditReceptions] = useState<boolean>(
    isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop)
  );
  const history = useHistory();
  const maxBodyHeight = useMaxTableBodyHeight();
  const [isFridayDinnerSoldOut, setIsFridayDinnerSoldOut] = useState<boolean>(false);
  const [isFridayLunchSoldOut, setIsFridayLunchSoldOut] = useState<boolean>(false);
  const [isSaturdayDinnerSoldOut, setIsSaturdayDinnerSoldOut] = useState<boolean>(false);
  const [isSaturdayLunchSoldOut, setIsSaturdayLunchSoldOut] = useState<boolean>(false);

  useEffect(() => {
    setIsFridayDinnerSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.FridayDinnerSoldOut) || false);
    setIsFridayLunchSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.FridayLunchSoldOut) || false);
    setIsSaturdayDinnerSoldOut(
      masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.SaturdayDinnerSoldOut) || false
    );
    setIsSaturdayLunchSoldOut(
      masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.SaturdayLunchSoldOut) || false
    );
  }, [masterSwitchs]);

  useEffect(() => {
    setCanEditReceptions(isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop));
  }, [user, permissions]);

  useEffect(() => {
    setTotals(DiscountService.calculateTotals(filteredRegistrations, group?.discountPackage?.discounts?.items || []));
  }, [filteredRegistrations, group]);

  useEffect(() => {
    setFilteredRegistrations(
      (registrations || []).filter((x) => !groupId || x.groups?.items?.map((item) => item?.group.id).includes(groupId))
    );
  }, [registrations, groupId]);

  useEffect(() => {
    setGroup(groups?.find((group) => group.id === groupId));
  }, [groups, groupId]);

  useEffect(() => {
    setIsGroupMember(!!user?.groups?.items.find((item) => item.groupId === groupId));
  }, [user, groupId]);

  const columns: Column<CustomUser>[] = [
    {
      title: 'First Name',
      field: 'firstName',
      align: 'left',
      editComponent: (props) => <MTableEditField {...props} autoFocus />,
    },
    { title: 'Last Name', field: 'lastName', align: 'left' },
    {
      title: 'Total Due',
      render: (user) => {
        if (!totals) return 'Loading...';
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const totalDue = singleItemized ? formatCurrency(DiscountService.getIndividualAmountDue(singleItemized)) : 0;
        return (
          <Chip
            icon={<AttachMoney />}
            label={<Typography style={{ marginLeft: '-8px' }}>{totalDue}</Typography>}
            variant="outlined"
            color="primary"
            style={{ marginLeft: '4px' }}
            size="small"
          />
        );
      },
    },
    {
      hidden: !canEditReceptions,
      title: renderHeader(`Thursday Reception ${EMOJI2.Thursday}`),
      field: 'thursdayReception',
      type: 'boolean',
      render: (user) => renderChecked(!!user.thursdayReception, PRICING.Reception, false),
      editComponent: (props) => renderCheckedEdit(props, !canEditReceptions),
    },
    {
      hidden: !canEditReceptions,
      title: renderHeader(`Friday Reception ${EMOJI2.Friday}`),
      field: 'fridayReception',
      type: 'boolean',
      render: (user) => renderChecked(!!user.fridayReception, PRICING.Reception, false),
      editComponent: (props) => renderCheckedEdit(props, !canEditReceptions),
    },
    {
      hidden: !canEditReceptions,
      title: renderHeader(`Friday Workshop ${EMOJI2.Workshop}`),
      field: 'fridayWorkshop',
      type: 'boolean',
      render: (user) => renderChecked(!!user.fridayWorkshop, PRICING.Workshop, false),
      editComponent: (props) => renderCheckedEdit(props, !canEditReceptions),
    },
    {
      title: renderHeader(`Friday Speakers $${PRICING.Speakers}`),
      field: 'fridaySpeakers',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.fridaySpeakers ?? PRICING.Speakers;
        return renderChecked(!!user.fridaySpeakers, cost, cost < PRICING.Speakers);
      },
      editComponent: (props) =>
        renderCheckedEdit({
          ...props,
          onChange: (selected) => {
            props.onChange(selected);
            props.onRowDataChange({
              ...props.rowData,
              fridaySpeakers: selected,
              fridayBreakfast: false,
              fridayLunch: false,
              fridayDinner: false,
            });
          },
        }),
    },
    {
      title: renderHeader(`Friday Breakfast $${PRICING.Breakfast}`),
      field: 'fridayBreakfast',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.fridayBreakfast ?? PRICING.Breakfast;
        return renderChecked(!!user.fridayBreakfast, cost, cost < PRICING.Breakfast);
      },
      editComponent: (props) => renderCheckedEdit(props, !props.rowData.fridaySpeakers),
    },
    {
      title: renderHeader(`Friday Lunch $${PRICING.Lunch}`, isFridayLunchSoldOut),
      field: 'fridayLunch',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.fridayLunch ?? PRICING.Lunch;
        return renderChecked(!!user.fridayLunch, cost, cost < PRICING.Lunch);
      },
      editComponent: (props) =>
        renderCheckedEdit(
          props,
          !props.rowData.fridaySpeakers ||
            (!props.rowData.fridayLunch && isFridayLunchSoldOut && user?.accessRole !== AccessRole.Admin)
        ),
    },
    {
      title: renderHeader(`Friday Dinner $${PRICING.Dinner}`, isFridayDinnerSoldOut),
      field: 'fridayDinner',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.fridayDinner ?? PRICING.Dinner;
        return renderChecked(!!user.fridayDinner, cost, cost < PRICING.Dinner);
      },
      editComponent: (props) =>
        renderCheckedEdit(
          props,
          !props.rowData.fridaySpeakers ||
            (!props.rowData.fridayDinner && isFridayDinnerSoldOut && user?.accessRole !== AccessRole.Admin)
        ),
    },
    {
      title: renderHeader(`Saturday Speakers $${PRICING.Speakers}`),
      field: 'saturdaySpeakers',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.saturdaySpeakers ?? PRICING.Speakers;
        return renderChecked(!!user.saturdaySpeakers, cost, cost < PRICING.Speakers);
      },
      editComponent: (props) =>
        renderCheckedEdit({
          ...props,
          onChange: (selected) => {
            props.onChange(selected);
            props.onRowDataChange({
              ...props.rowData,
              saturdaySpeakers: selected,
              saturdayBreakfast: false,
              saturdayLunch: false,
              saturdayDinner: false,
            });
          },
        }),
    },
    {
      title: renderHeader(`Saturday Breakfast $${PRICING.Breakfast}`),
      field: 'saturdayBreakfast',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.saturdayBreakfast ?? PRICING.Breakfast;
        return renderChecked(!!user.saturdayBreakfast, cost, cost < PRICING.Breakfast);
      },
      editComponent: (props) => renderCheckedEdit(props, !props.rowData.saturdaySpeakers),
    },
    {
      title: renderHeader(`Saturday Lunch $${PRICING.Lunch}`, isSaturdayLunchSoldOut),
      field: 'saturdayLunch',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.saturdayLunch ?? PRICING.Lunch;
        return renderChecked(!!user.saturdayLunch, cost, cost < PRICING.Lunch);
      },
      editComponent: (props) =>
        renderCheckedEdit(
          props,
          !props.rowData.saturdaySpeakers ||
            (!props.rowData.saturdayLunch && isSaturdayLunchSoldOut && user?.accessRole !== AccessRole.Admin)
        ),
    },
    {
      title: renderHeader(`Saturday Dinner $${PRICING.Dinner}`, isSaturdayDinnerSoldOut),
      field: 'saturdayDinner',
      type: 'boolean',
      render: (user) => {
        const singleItemized = totals.groupItemized.find((singleItemized) => singleItemized.userId === user.id);
        const cost = singleItemized?.saturdayDinner ?? PRICING.Dinner;
        return renderChecked(!!user.saturdayDinner, cost, cost < PRICING.Dinner);
      },
      editComponent: (props) =>
        renderCheckedEdit(
          props,
          !props.rowData.saturdaySpeakers ||
            (!props.rowData.saturdayDinner && isSaturdayDinnerSoldOut && user?.accessRole !== AccessRole.Admin)
        ),
    },
  ];

  return (
    <>
      <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
        <Box width="100%">
          {!!groupId && (
            <Button
              color="primary"
              variant="text"
              startIcon={<ChevronLeft />}
              onClick={() => history.goBack()}
              style={{ margin: '0.5rem' }}
            >
              Back
            </Button>
          )}
          {isGroupRegistrar && (
            <Button
              color="primary"
              variant="outlined"
              startIcon={isGroupMember ? <VisibilityOff /> : <Visibility />}
              onClick={async () => {
                return ApiService[isGroupMember ? 'deleteUserGroup' : 'createUserGroup']({
                  input: { userId: user!.id, groupId: groupId! },
                })
                  .then(() => updateUser())
                  .catch(() =>
                    enqueueSnackbar(
                      `Error: Unable to ${isGroupMember ? 'hide' : 'show'} your individual registration.`,
                      {
                        variant: 'error',
                      }
                    )
                  )
                  .then(() => {
                    Hub.dispatch(HubChannel.Registration, { event: HubEvent.Update, data: { user: user!.id } });
                    return user;
                  });
              }}
              style={{ margin: '0.5rem' }}
            >
              {isGroupMember ? 'Hide' : 'Show'} My Registration With This Group
            </Button>
          )}
          {group || !groups ? (
            <MaterialTable
              title={
                <>
                  <Box display="flex" flexWrap="wrap" alignItems="center" marginRight="12px">
                    <Typography variant="h6" style={{ paddingRight: '16px' }}>
                      {group?.groupName}
                    </Typography>
                    {!!group?.discountPackageId && (
                      <Box display="flex" flexDirection="column" alignItems="center" marginX="4px">
                        <Typography variant="caption">Retail</Typography>
                        <Chip
                          icon={<AttachMoney />}
                          label={
                            <Typography style={{ marginLeft: '-8px' }}>{formatCurrency(totals.retail)}</Typography>
                          }
                          variant="outlined"
                          size="small"
                        />
                      </Box>
                    )}
                    {!!group?.discountPackageId && (
                      <Tooltip
                        title={<Typography variant="subtitle2">{group?.discountPackage?.name || 'None'}</Typography>}
                        arrow
                        placement="top"
                        enterDelay={0}
                      >
                        <Box display="flex" flexDirection="column" alignItems="center" marginX="4px">
                          <Typography variant="caption" color="secondary">
                            Discount
                          </Typography>
                          <Chip
                            icon={<AttachMoney />}
                            label={
                              <Typography style={{ marginLeft: '-8px' }}>{formatCurrency(totals.discount)}</Typography>
                            }
                            variant="outlined"
                            color="secondary"
                            size="small"
                          />
                        </Box>
                      </Tooltip>
                    )}
                    <Box display="flex" flexDirection="column" alignItems="center">
                      <Typography variant="caption" color="primary">
                        Total Due
                      </Typography>
                      <Chip
                        icon={<AttachMoney />}
                        label={<Typography style={{ marginLeft: '-8px' }}>{formatCurrency(totals.actual)}</Typography>}
                        variant="outlined"
                        color="primary"
                        style={{ marginLeft: '4px' }}
                        size="small"
                      />
                    </Box>
                  </Box>
                </>
              }
              columns={columns.map((c) => ({ disableClick: true, ...c }))}
              data={filteredRegistrations}
              onRowClick={() => {}}
              options={{
                search: false,
                paging: false,
                maxBodyHeight,
                toolbarButtonAlignment: 'left',
                addRowPosition: 'first',
                draggable: false,
              }}
              components={{
                Action: (props) => {
                  const action = typeof props.action === 'function' ? props.action(props.data) : props.action;
                  const { Icon, iconProps } = mapTooltip(action.tooltip, action.disabled);
                  return action.hidden ? null : (
                    <Tooltip
                      title={action.tooltip}
                      arrow
                      disableFocusListener={props.disabled}
                      disableHoverListener={props.disabled}
                    >
                      <span>
                        <IconButton
                          onClick={(event) => {
                            if (action.tooltip === 'Add') document.getElementsByTagName('table')[0].scrollIntoView();
                            action.onClick(event, props.data);
                          }}
                          disabled={action.disabled || props.disabled}
                        >
                          <Icon {...iconProps} />
                        </IconButton>
                      </span>
                    </Tooltip>
                  );
                },
              }}
              editable={{
                onRowAdd: (newData) => {
                  const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
                  if (!newData.firstName || newData.firstName.trim() === '')
                    snackbar.push({ message: 'First Name required', variant: 'error' });
                  if (!newData.lastName || newData.lastName.trim() === '')
                    snackbar.push({ message: 'Last Name required', variant: 'error' });
                  if (snackbar.length > 0) {
                    snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
                    return Promise.reject();
                  }
                  if (!newData.fridaySpeakers && !newData.saturdaySpeakers) {
                    enqueueSnackbar('Please select at least one item when adding a new registration.', {
                      variant: 'error',
                    });
                    return Promise.reject();
                  }
                  return RegistrationService.create(
                    {
                      firstName: newData.firstName,
                      lastName: newData.lastName,
                      thursdayReception: canEditReceptions ? newData.thursdayReception : undefined,
                      fridayReception: canEditReceptions ? newData.fridayReception : undefined,
                      fridayWorkshop: canEditReceptions ? newData.fridayWorkshop : undefined,
                      fridaySpeakers: newData.fridaySpeakers,
                      fridayBreakfast: newData.fridayBreakfast,
                      fridayLunch: newData.fridayLunch,
                      fridayDinner: newData.fridayDinner,
                      saturdaySpeakers: newData.saturdaySpeakers,
                      saturdayBreakfast: newData.saturdayBreakfast,
                      saturdayLunch: newData.saturdayLunch,
                      saturdayDinner: newData.saturdayDinner,
                    },
                    [groupId!]
                  )
                    .then(() => updateRegistrations())
                    .catch((error) => {
                      enqueueSnackbar(`😔 Something went wrong. Refresh the page, then try again.`, {
                        variant: 'error',
                      });
                      return Promise.reject(error);
                    });
                },
                onRowUpdate: (newData, oldData) => {
                  const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
                  if (!newData.firstName || newData.firstName.trim() === '')
                    snackbar.push({ message: 'First Name required', variant: 'error' });
                  if (!newData.lastName || newData.lastName.trim() === '')
                    snackbar.push({ message: 'Last Name required', variant: 'error' });
                  if (snackbar.length > 0) {
                    snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
                    return Promise.reject();
                  }
                  const baseInput: UpdateUserInput = {
                    id: oldData!.id,
                    expectedVersion: oldData!.version,
                    firstName: newData.firstName,
                    lastName: newData.lastName,
                    fridaySpeakers: newData.fridaySpeakers,
                    fridayBreakfast: newData.fridayBreakfast,
                    fridayLunch: newData.fridayLunch,
                    fridayDinner: newData.fridayDinner,
                    saturdaySpeakers: newData.saturdaySpeakers,
                    saturdayBreakfast: newData.saturdayBreakfast,
                    saturdayLunch: newData.saturdayLunch,
                    saturdayDinner: newData.saturdayDinner,
                  };
                  return RegistrationService.update(
                    canEditReceptions
                      ? {
                          ...baseInput,
                          thursdayReception: canEditReceptions ? newData.thursdayReception : undefined,
                          fridayReception: canEditReceptions ? newData.fridayReception : undefined,
                          fridayWorkshop: canEditReceptions ? newData.fridayWorkshop : undefined,
                        }
                      : baseInput,
                    oldData?.groups?.items?.map((item) => item!.groupId),
                    oldData?.groups?.items?.map((item) => item!.groupId)
                  )
                    .then(() => updateRegistrations())
                    .catch((error) => {
                      enqueueSnackbar(`😔 Something went wrong. Refresh the page, then try again.`, {
                        variant: 'error',
                      });
                      return Promise.reject(error);
                    });
                },
                onRowDelete: (oldData) =>
                  RegistrationService.delete({ id: oldData.id, expectedVersion: oldData.version }, [groupId!])
                    .then(() => updateRegistrations())
                    .catch((error) => {
                      enqueueSnackbar(`😔 Something went wrong. Refresh the page, then try again.`, {
                        variant: 'error',
                      });
                      return Promise.reject(error);
                    }),
                isDeletable: (rowData) => !rowData.cognitoId,
              }}
            />
          ) : (
            <Typography variant="h4">
              <span role="img" aria-label="weary face">
                😩
              </span>{' '}
              Group Not Found!
            </Typography>
          )}
        </Box>
      </Box>
    </>
  );
};

const renderHeader = (header: string, isSoldOut: boolean = false): ReactElement => (
  <Box display="flex" flexDirection="column">
    {header.split(' ').map((value, index) => (
      <Box
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        key={header + index}
        style={index === 2 ? { color: 'gray' } : undefined}
      >
        <span>{value}&nbsp;</span>
        {index === 2 && isSoldOut && (
          <Typography variant="caption" color="secondary">
            Sold out!
          </Typography>
        )}
      </Box>
    ))}
  </Box>
);

const renderChecked = (checked: boolean, cost: number, isDiscounted: boolean): ReactElement => {
  if (!checked) return <></>;
  return (
    <Tooltip
      title={<Typography variant="subtitle2">${formatCurrency(cost)}</Typography>}
      arrow
      placement="right"
      enterDelay={0}
    >
      <CheckRounded color={isDiscounted ? 'secondary' : 'primary'} />
    </Tooltip>
  );
};

const renderCheckedEdit = (props: EditComponentProps<User>, disabled: boolean = false) => (
  <MTableEditField {...{ ...props, disabled }} />
);

const mapTooltip = (
  tooltip: string,
  disabled: boolean = false
): {
  Icon: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  iconProps: SvgIconProps;
} => {
  switch (tooltip) {
    case 'Add':
      return { Icon: AddBox, iconProps: { color: 'primary' } };
    case 'Edit':
    case 'Edit All':
      return { Icon: Edit, iconProps: { color: 'primary' } };
    case 'Delete':
      return { Icon: DeleteOutline, iconProps: { color: disabled ? 'disabled' : 'secondary' } };
    case 'Save':
    case 'Save all changes':
      return { Icon: Check, iconProps: { style: { color: 'green' } } };
    case 'Cancel':
    case 'Discard all changes':
      return { Icon: Clear, iconProps: {} };
    default:
      return { Icon: BugReport, iconProps: {} };
  }
};
