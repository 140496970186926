import React, { FC, useContext, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { ModalProvider, StateContext } from '../context';
import { AccessRole, PermissionName } from '../core';
import { ActivityHistory } from './ActivityHistory';
import { Discounts } from './Discounts';
import { Downloads } from './Downloads';
import { ErrorLog } from './ErrorLog';
import { Groups } from './Groups';
import { Individuals } from './Individuals';
import { MasterSwitches } from './MasterSwitches';
import { Menu } from './Menu';
import { Registrations } from './Registrations';
import { Roles } from './Roles';
import { Users } from './Users';

export const User: FC = () => {
  const { user } = useContext(StateContext);
  const [canManageGroupLeaders] = useState<boolean>(
    !!user?.permissions?.items
      ?.map((i) => i?.permission.permissionName)
      .find((pName) => pName === PermissionName.ManageGroupLeaders)
  );
  const [canViewActivityHistory] = useState<boolean>(
    !!user?.permissions?.items
      ?.map((i) => i?.permission.permissionName)
      .find((pName) => pName === PermissionName.ViewActivityLog)
  );

  const { Admin, Registrar: Volunteer, GroupLeader: Group } = AccessRole;
  const accessRole = user?.accessRole as AccessRole;

  return (
    <ModalProvider>
      <Menu />
      <Switch>
        <Route path="/users">
          {[Admin].includes(accessRole) || canManageGroupLeaders ? <Users /> : <Redirect to="/" />}
        </Route>
        <Route path="/groups">
          {[Admin, Volunteer, Group].includes(accessRole) ? (
            <Groups isGroupRegistrar={[Group].includes(accessRole)} />
          ) : (
            <Redirect to="/" />
          )}
        </Route>
        <Route path="/discounts">{[Admin].includes(accessRole) ? <Discounts /> : <Redirect to="/" />}</Route>
        <Route path="/roles">{[Admin].includes(accessRole) ? <Roles /> : <Redirect to="/" />}</Route>
        <Route path="/registrations">
          {[Admin, Volunteer].includes(accessRole) ? <Registrations /> : <Redirect to="/" />}
        </Route>
        <Route path="/downloads">{[Admin].includes(accessRole) ? <Downloads /> : <Redirect to="/" />}</Route>
        <Route path="/activity">
          {[Admin].includes(accessRole) || canViewActivityHistory ? <ActivityHistory /> : <Redirect to="/" />}
        </Route>
        <Route path="/errors">{[Admin].includes(accessRole) ? <ErrorLog /> : <Redirect to="/" />}</Route>
        <Route path="/switches">{[Admin].includes(accessRole) ? <MasterSwitches /> : <Redirect to="/" />}</Route>
        <Route path="/individuals">{[Admin].includes(accessRole) ? <Individuals /> : <Redirect to="/" />}</Route>
        <Route path="/">
          {[Admin, Volunteer].includes(accessRole) ? <Redirect to="/registrations" /> : <Redirect to="/groups" />}
        </Route>
      </Switch>
    </ModalProvider>
  );
};
