import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { Controller, useFormContext, ValidationRules } from 'react-hook-form';

interface RadioGroupOption {
  label: string;
  value: string;
}

interface Props {
  name: string;
  options: RadioGroupOption[];
  defaultValue?: string;
  rules?: ValidationRules;
  label?: string;
  hideLabel?: boolean;
  row?: boolean;
}

export const InputRadioGroup: FC<Props> = (props) => {
  const {
    name,
    options,
    defaultValue = '',
    rules = {},
    label = name?.replace(/([A-Z])/g, ' $1').trim(),
    hideLabel = false,
    row,
  } = props;

  rules.required = rules.required === true ? `${label} required` : rules.required;

  const { control } = useFormContext();
  const [value, setValue] = useState<string>(defaultValue);

  return (
    <Controller
      name={name}
      control={control}
      rules={rules}
      defaultValue={defaultValue}
      as={
        <FormControl component="fieldset">
          {!hideLabel && <FormLabel component="legend">{`${label}${rules.required ? ' *' : ''}`}</FormLabel>}
          <RadioGroup row={row} name={name} value={value} onChange={(e) => setValue(e.target.value)}>
            {options.map(({ label, value }, idx) => (
              <FormControlLabel
                id={`radio-${label.replace(/ /g, '')}`}
                key={idx}
                value={value}
                control={<Radio />}
                label={label}
              />
            ))}
          </RadioGroup>
        </FormControl>
      }
    />
  );
};
