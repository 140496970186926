import { Box, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { StateContext } from '../context';
import { ApiService, Role } from '../core';
import { Form } from './Form';
import { InputText } from './InputText';
import { withModal } from './withModal';

interface DataRoleForm {
  RoleName: string;
}

interface Props {
  existingData?: Role;
}

export const RoleForm: FC<Props> = withModal(({ closeModal, existingData }) => {
  const { withLoading, updateRoles, updateRegistrations } = useContext(StateContext);
  const form = useForm<DataRoleForm>();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = form.handleSubmit(
    withLoading<[DataRoleForm]>(async (data) => {
      try {
        const mappedData = {
          roleName: data.RoleName,
        };
        if (existingData) {
          await ApiService.updateRole({
            input: { ...mappedData, id: existingData.id, expectedVersion: existingData.version },
          });
        } else {
          await ApiService.createRole({ input: mappedData });
        }
        updateRoles();
        updateRegistrations();
        closeModal();
        enqueueSnackbar(`Successfully ${existingData ? 'updated' : 'created new'} role ${data.RoleName}`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(`Failed to ${existingData ? 'update' : 'create new'} role.`, { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          Add new convention role
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexGrow={1} margin="2px">
          <InputText
            name="RoleName"
            defaultValue={existingData?.roleName}
            rules={{ required: true }}
            autoFocus={!existingData}
          />
        </Box>
        <Box display="flex" marginTop="20px" justifyContent="center">
          <Button type="submit" color="primary" variant="contained" style={{ margin: '0.5rem' }}>
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
