import { Chip, ChipProps } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { StateContext } from '../context';

interface Props extends ChipProps {
  groupName: string;
  isRegistrar?: boolean;
}

export const GroupChip: FC<Props> = ({ groupName, isRegistrar, ...rest }) => {
  const history = useHistory();
  const { groups } = useContext(StateContext);
  const groupId = groups?.find((group) => group.groupName === groupName)?.id;

  return (
    <Chip
      label={groupName}
      size="small"
      variant={isRegistrar ? 'default' : 'outlined'}
      style={{ marginRight: '0.25rem' }}
      clickable={!!groupId}
      onClick={() => history.push(`/groups/${groupId}`)}
      {...rest}
    />
  );
};
