import { Chip } from '@material-ui/core';
import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText, InputTextProps } from './InputText';

type Props = Omit<InputTextProps, 'phone'> &
  Omit<AutocompleteProps<string, true, false, false>, 'multiple' | 'renderInput'>;

export const InputSelectMultiple: FC<Props> = (props) => {
  const { register, setValue } = useFormContext();
  const {
    autoComplete,
    defaultValue,
    fullWidth = true,
    id,
    label,
    name = '',
    options,
    rules,
    variant,
    ...rest
  } = props;
  const uniqueOptions = options.filter((value, index, array) => value && array.indexOf(value) === index).sort();

  useEffect(() => {
    register(name);
    setValue(name, defaultValue || []);
  }, [register, setValue, name, defaultValue]);

  return (
    <Autocomplete
      id={`${id}-input`}
      multiple
      autoComplete
      autoSelect
      fullWidth={fullWidth}
      openOnFocus
      defaultValue={defaultValue}
      options={uniqueOptions}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <InputText {...params} name={name} label={label} variant={variant} rules={rules} inputRef={() => {}} />
      )}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Chip id={`${id}-chip-${index}`} label={option} size={props.size} {...getTagProps({ index })} />
        ))
      }
      onChange={(_event, value) => setValue(name, value)}
      {...rest}
    />
  );
};
