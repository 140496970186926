import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { ApiService, DiscountPackage } from '../core';
import { ModalConfirm } from './ModalConfirm';
import { withModal } from './withModal';

interface Props {
  data: DiscountPackage;
}
export const DiscountDelete: FC<Props> = withModal(({ data, closeModal }) => {
  const { withLoading, updateDiscountPackages, updateGroups } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const deleteDiscountPackage = withLoading(async () => {
    try {
      await Promise.all([
        // delete discounts associated with discount package
        ...(data.discounts?.items.map((discount) =>
          ApiService.deleteDiscount({ input: { id: discount.id, expectedVersion: discount.version } })
        ) || []),
        // remove discount package from groups
        ...(data.groups?.items.map((group) =>
          ApiService.updateGroup({ input: { id: group.id, expectedVersion: group.version, discountPackageId: null } })
        ) || []),
      ]);
      // delete discount package
      await ApiService.deleteDiscountPackage({ input: { id: data.id, expectedVersion: data.version } });
      updateDiscountPackages();
      updateGroups();
      closeModal();
      enqueueSnackbar(`Successfully deleted discount package ${data.name}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete discount package ${data.name}`, { variant: 'error' });
    }
  });

  return (
    <ModalConfirm
      title="Delete Discount Package"
      word="Delete"
      onConfirm={deleteDiscountPackage}
      message={
        <>
          Are you sure you want to delete <b>{data.name}</b>?
        </>
      }
    ></ModalConfirm>
  );
});
