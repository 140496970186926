import Fuse from 'fuse.js';

interface SearchConfig<T> {
  options: Fuse.IFuseOptions<T>;
  limit: number;
}

const DEFAULT_OPTIONS: Fuse.IFuseOptions<any> = {
  // isCaseSensitive: boolean; // false
  // includeScore: boolean; // false
  // shouldSort: boolean; // true
  // includeMatches: boolean; // false
  // findAllMatches: boolean; // false
  // minMatchCharLength: number; // 1
  // location: number; // 0
  // threshold: number; // 0.6
  threshold: 0.5, // 0.6
  // distance: number; // 100
  // useExtendedSearch: boolean; // false
  // ignoreLocation: boolean; // false
  // ignoreFieldNorm: boolean; // false
  // fieldNormWeight: number; // 1
  // keys: string[]; // ["title", "author.firstName"]
};

const DEFAULT_CONFIG = {
  options: DEFAULT_OPTIONS,
  limit: 3,
};

export abstract class SearchService {
  static fuzzyFind = <T = string, R = T>(
    input: R,
    list: T[],
    config: Partial<SearchConfig<T>> = {}
  ): Fuse.FuseResult<T>[] => {
    const { options, limit } = { ...DEFAULT_CONFIG, ...config };
    const fuse = new Fuse<T>(list, options);
    return fuse.search(input, { limit });
  };
}
