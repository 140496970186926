import { ApiService } from './api.service';
import { AuthService } from './auth.service';
import { User } from './graphql.types';
import { LoggingService } from './logging.service';

export abstract class ActivityService {
  static logRegistration = async (event: string, user: User | string): Promise<void> => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      const registration = typeof user === 'string' ? await ApiService.getUser({ id: user }) : user;
      if (!registration) throw new Error(`registration ${user} not found`);
      const {
        id: registrationId,
        firstName,
        lastName,
        email,
        phone,
        thursdayReception,
        fridayReception,
        fridayWorkshop,
        fridaySpeakers,
        fridayBreakfast,
        fridayLunch,
        fridayDinner,
        saturdaySpeakers,
        saturdayBreakfast,
        saturdayLunch,
        saturdayDinner,
        notes,
      } = registration;
      const groups = registration.groups?.items?.map((item) => item?.group.groupName || '');
      const role = registration.role?.roleName;
      await ApiService.createRegistrationHistory({
        input: {
          user: cognitoUser?.attributes?.name || 'N/A',
          event,
          registrationId,
          firstName,
          lastName,
          email,
          phone,
          groups,
          role,
          thursdayReception,
          fridayReception,
          fridayWorkshop,
          fridaySpeakers,
          fridayBreakfast,
          fridayLunch,
          fridayDinner,
          saturdaySpeakers,
          saturdayBreakfast,
          saturdayLunch,
          saturdayDinner,
          notes,
        },
      });
    } catch (error) {
      LoggingService.error('ActivityService#logRegistration', (_error) => null)(error);
    }
  };

  static logError = async (message: string | undefined): Promise<void> => {
    try {
      const cognitoUser = await AuthService.currentAuthenticatedUser();
      await ApiService.createErrorHistory({
        input: {
          user: cognitoUser?.attributes?.name || 'N/A',
          message,
        },
      });
    } catch (error) {
      // error log the error log?
    }
  };
}
