import React, { FC, useContext, useEffect } from 'react';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import { LoadingBackdrop, Login, User } from './components';
import { Providers, StateContext } from './context';
import { usePostHog } from 'posthog-js/react';

const App: FC = () => {
  const { user } = useContext(StateContext);
  const posthog = usePostHog();

  useEffect(() => {
    if (user) {
      posthog.identify(user.id, {
        user_id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
    }
  }, [user, posthog]);

  return (
    <Switch>
      <Route path="/login">
        {user ? <Redirect to="/" /> : user === null ? <Login /> : <LoadingBackdrop open={true} />}
      </Route>
      <Route path="/">
        {user ? <User /> : user === null ? <Redirect to="/login" /> : <LoadingBackdrop open={true} />}
      </Route>
    </Switch>
  );
};

export default () => (
  <Providers>
    <Router>
      <App />
    </Router>
  </Providers>
);
