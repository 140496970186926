import React, { FC } from 'react';

import { SnackbarProvider } from './SnackbarProvider';
import { ThemeProvider } from './ThemeProvider';
import { StateProvider } from './StateContext';

export const Providers: FC = ({ children }) => {
  return (
    <SnackbarProvider>
      <StateProvider>
        <ThemeProvider>{children}</ThemeProvider>
      </StateProvider>
    </SnackbarProvider>
  );
};
