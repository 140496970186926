import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { RegistrationService, User } from '../core';
import { ModalConfirm } from './ModalConfirm';
import { withModal } from './withModal';

interface Props {
  data: User;
}
export const RegistrationDelete: FC<Props> = withModal(({ data, closeModal }) => {
  const { withLoading, updateRegistrations } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const deleteRegistration = withLoading(async () => {
    try {
      const user = { id: data.id, expectedVersion: data.version };
      const groupIds = data.groups?.items?.map((item) => item!.groupId);
      await RegistrationService.delete(user, groupIds);
      updateRegistrations();
      closeModal();
      const msgSuccess = `Successfully deleted registration for ${data.firstName} ${data.lastName}`;
      enqueueSnackbar(msgSuccess, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete registration for ${data.firstName} ${data.lastName}`, { variant: 'error' });
    }
  });

  return (
    <ModalConfirm
      title="Delete registration"
      word="Delete"
      onConfirm={deleteRegistration}
      message={
        <>
          Are you sure you want to delete{' '}
          <b>
            {data.firstName} {data.lastName}
          </b>
          ?
        </>
      }
    ></ModalConfirm>
  );
});
