import { Box, Button, Typography } from '@material-ui/core';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';
import { StateContext } from '../context';
import { ConferenceDay, CustomUser, DownloadType, NameBadgeService } from '../core';
import { Form } from './Form';
import { InputSelectMultipleComplex, SelectOptionComplex } from './InputSelectMultipleComplex';
import { withModal } from './withModal';

interface DataNameBadgeSelect {
  Names: SelectOptionComplex[];
}

interface Props {
  day: ConferenceDay;
}

export const NameBadgeSelect: FC<Props> = withModal(({ day, closeModal }) => {
  const { registrations, withLoading, updateDownloads } = useContext(StateContext);
  const form = useForm<DataNameBadgeSelect>();

  const onSubmit = form.handleSubmit(async (data) => {
    const users = NameBadgeService.mapUsersSnapshot(
      day,
      data.Names.map((x) => registrations?.find((r) => r.id === x.inputValue)) as CustomUser[]
    );
    await withLoading(NameBadgeService.download)(day, DownloadType.Individual, users);
    updateDownloads();
    closeModal();
  });

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          Individual Name Badges - {day}
        </Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexGrow={1} margin="5px">
            <Typography variant="caption">Missing someone? They are not registered for {day} events.</Typography>
          </Box>
          <Box display="flex" flexGrow={1} margin="2px">
            <InputSelectMultipleComplex
              name="Names"
              options={(registrations || [])
                .filter((r) => {
                  if (day === ConferenceDay.Thursday) return !!r.thursdayReception;
                  if (day === ConferenceDay.Friday) return !!r.fridaySpeakers;
                  if (day === ConferenceDay.Saturday) return !!r.saturdaySpeakers;
                  return false;
                })
                .map((r) => ({
                  value: `${r.firstName} ${r.lastName}`,
                  inputValue: r.id,
                }))}
              filterSelectedOptions
            />
          </Box>
          <Box display="flex" marginTop="20px" justifyContent="center">
            <Button type="submit" color="primary" variant="contained" style={{ margin: '0.5rem' }}>
              Download
            </Button>
            <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
              Cancel
            </Button>
          </Box>
        </Box>
      </Form>
    </>
  );
});
