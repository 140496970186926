import { Badge, Box, Button, createStyles, Theme, withStyles } from '@material-ui/core';
import { GetAppRounded } from '@material-ui/icons';
import React, { FC, useContext, useEffect, useState } from 'react';
import { StateContext } from '../context';
import {
  BadgeDownload,
  ConferenceDay,
  CustomUser,
  downloadCsv,
  downloadTableList,
  DownloadType,
  Maybe,
  NameBadgeService,
  UserSnapshot,
} from '../core';
import { DownloadHistoryTable } from './DownloadHistoryTable';
import { NameBadgeSelect } from './NameBadgeSelect';
import { withModal } from './withModal';

const StyledBadge = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      right: -20,
      top: 13,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

const StyledBadgeLeft = withStyles((theme: Theme) =>
  createStyles({
    badge: {
      left: -48,
      top: 13,
      border: `1px solid ${theme.palette.background.paper}`,
      padding: '0 4px',
    },
  })
)(Badge);

interface Props {}

export const Downloads: FC<Props> = withModal(({ openModal }) => {
  const { registrations, withLoading, downloads, updateDownloads, groups } = useContext(StateContext);
  const [reprints, setReprints] = useState<Record<ConferenceDay, UserSnapshot[]>>({
    [ConferenceDay.Thursday]: [],
    [ConferenceDay.Friday]: [],
    [ConferenceDay.Saturday]: [],
  });
  const [removes, setRemoves] = useState<Record<ConferenceDay, UserSnapshot[]>>({
    [ConferenceDay.Thursday]: [],
    [ConferenceDay.Friday]: [],
    [ConferenceDay.Saturday]: [],
  });

  useEffect(() => {
    const latestThursday = getLatest(ConferenceDay.Thursday, registrations);
    const latestFriday = getLatest(ConferenceDay.Friday, registrations);
    const latestSaturday = getLatest(ConferenceDay.Saturday, registrations);
    const inPrintThursday = getInPrint(ConferenceDay.Thursday, downloads);
    const inPrintFriday = getInPrint(ConferenceDay.Friday, downloads);
    const inPrintSaturday = getInPrint(ConferenceDay.Saturday, downloads);
    setReprints({
      [ConferenceDay.Thursday]: mapReprint(latestThursday, inPrintThursday),
      [ConferenceDay.Friday]: mapReprint(latestFriday, inPrintFriday),
      [ConferenceDay.Saturday]: mapReprint(latestSaturday, inPrintSaturday),
    });
    setRemoves({
      [ConferenceDay.Thursday]: mapRemove(latestThursday, inPrintThursday),
      [ConferenceDay.Friday]: mapRemove(latestFriday, inPrintFriday),
      [ConferenceDay.Saturday]: mapRemove(latestSaturday, inPrintSaturday),
    });
  }, [registrations, downloads]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%" maxWidth="1000px" display="flex" flexDirection="column" alignItems="center">
        <Box display="flex" width="100%">
          {Object.values(ConferenceDay).map((day, idx) => (
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<GetAppRounded />}
              onClick={async () => {
                if (registrations) {
                  const users = NameBadgeService.mapUsersSnapshot(day, registrations);
                  await withLoading(NameBadgeService.download)(day, DownloadType.All, users);
                  updateDownloads();
                }
              }}
              disabled={!registrations}
              style={{ margin: '0.5rem' }}
              fullWidth
              key={idx}
            >
              {day} - All
            </Button>
          ))}
        </Box>
        <Box display="flex" width="100%">
          {Object.values(ConferenceDay).map((day, idx) => (
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<GetAppRounded />}
              onClick={async () => {
                if (registrations) {
                  await withLoading(NameBadgeService.download)(day, DownloadType.Reprint, reprints[day], removes[day]);
                  updateDownloads();
                }
              }}
              disabled={!registrations}
              style={{ margin: '0.5rem' }}
              fullWidth
              key={idx}
            >
              <StyledBadgeLeft
                badgeContent={removes[day].length}
                color="primary"
                anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              >
                <StyledBadge badgeContent={reprints[day].length} color="secondary">
                  {day} - Reprint
                </StyledBadge>
              </StyledBadgeLeft>
            </Button>
          ))}
        </Box>
        <Box display="flex" width="100%">
          {Object.values(ConferenceDay).map((day, idx) => (
            <Button
              color="primary"
              variant="contained"
              size="medium"
              startIcon={<GetAppRounded />}
              onClick={() => openModal(<NameBadgeSelect day={day} />)}
              disabled={!registrations}
              style={{ margin: '0.5rem' }}
              fullWidth
              key={idx}
            >
              {day} - Individual
            </Button>
          ))}
        </Box>
        <Box display="flex" width="100%">
          {Object.values(ConferenceDay).map((day, idx) => (
            <Button
              color="default"
              variant="contained"
              size="medium"
              startIcon={<GetAppRounded />}
              onClick={() => NameBadgeService.downloadBlanks(day)}
              disabled={!registrations}
              style={{ margin: '0.5rem' }}
              fullWidth
              key={idx}
            >
              {day} Blanks
            </Button>
          ))}
        </Box>

        <Box display="flex" width="100%">
          <Button
            color="default"
            variant="contained"
            size="medium"
            startIcon={<GetAppRounded />}
            onClick={() => NameBadgeService.downloadPress()}
            disabled={!registrations}
            style={{ margin: '0.5rem' }}
            fullWidth
          >
            {'Press Passes'}
          </Button>
          <Button
            color="default"
            variant="contained"
            size="medium"
            startIcon={<GetAppRounded />}
            onClick={() => registrations && downloadCsv(registrations)}
            disabled={!registrations}
            style={{ margin: '0.5rem' }}
            fullWidth
          >
            {'Download .CSV'}
          </Button>
        </Box>
        <Box display="flex" width="100%">
          {Object.values(ConferenceDay).map((day, idx) => (
            <Button
              color="default"
              variant="contained"
              size="medium"
              startIcon={<GetAppRounded />}
              onClick={() => downloadTableList(day, registrations!, groups!)}
              disabled={!registrations}
              style={{ margin: '0.5rem' }}
              fullWidth
              key={idx}
            >
              {day} Table Lists
            </Button>
          ))}
        </Box>
        <Box height="20px" />
        <Box width="100%">
          <DownloadHistoryTable />
        </Box>
      </Box>
    </Box>
  );
});

const mapReprint = (latest: UserSnapshot[], inPrint: UserSnapshot[]): UserSnapshot[] => {
  const reprint: UserSnapshot[] = [];
  latest.forEach((x) => {
    const match = inPrint.find((y) => y.id === x.id);
    if (!match) return reprint.push(x);
    if (x.name !== match.name) return reprint.push(x);
    if (x.role !== match.role) return reprint.push(x);
    if (x.b !== match.b) return reprint.push(x);
    if (x.l !== match.l) return reprint.push(x);
    if (x.d !== match.d) return reprint.push(x);
    if (x.w !== match.w) return reprint.push(x);
    if (x.r !== match.r) return reprint.push(x);
  });
  return reprint;
};

const mapRemove = (latest: UserSnapshot[], inPrint: UserSnapshot[]): UserSnapshot[] => {
  const remove: UserSnapshot[] = [];
  inPrint.forEach((x) => {
    const match = latest.find((y) => y.id === x.id);
    if (!match) return remove.push(x);
    if (x.name !== match.name) return remove.push(x);
  });
  return remove;
};

const getInPrint = (day: ConferenceDay, downloads: Maybe<BadgeDownload[]> = []): UserSnapshot[] => {
  const downloadsPrintedSorted = (downloads || [])
    .filter((x) => x.day === day && x.printed)
    .sort((a, b) => +new Date(a.createdAt) - +new Date(b.createdAt));
  const inPrint = downloadsPrintedSorted.reduce((previous, current) => {
    const update: UserSnapshot[] = JSON.parse(current.usersUpdate);
    const remove: UserSnapshot[] = JSON.parse(current.usersRemove);
    const keep = previous.filter(
      (p) =>
        !remove
          .concat(update)
          .map((remup) => remup.id)
          .includes(p.id)
    );
    return [...keep, ...update];
  }, [] as UserSnapshot[]);
  return inPrint;
};

const getLatest = (day: ConferenceDay, registrations: Maybe<CustomUser[]> = []): UserSnapshot[] => {
  return NameBadgeService.mapUsersSnapshot(day, registrations || []);
};
