import React, { useRef } from 'react';
import { SnackbarProvider as NotistackSnackbarProvider } from 'notistack';
import { IconButton } from '@material-ui/core';
import { Close } from '@material-ui/icons';

export const SnackbarProvider = ({ children }: any) => {
  const notistackRef = useRef<any>();
  const onClickDismiss = (key: any) => () => notistackRef.current.closeSnackbar(key);

  return (
    <NotistackSnackbarProvider
      ref={notistackRef}
      autoHideDuration={5000}
      preventDuplicate
      hideIconVariant
      anchorOrigin={{ horizontal: 'center', vertical: 'top' }}
      action={(key) => (
        <IconButton onClick={onClickDismiss(key)} color="inherit">
          <Close />
        </IconButton>
      )}
    >
      {children}
    </NotistackSnackbarProvider>
  );
};
