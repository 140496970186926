import { Box, Button, Typography } from '@material-ui/core';
import { AddRounded, Visibility } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useEffect, useState } from 'react';
import { FieldError, useForm } from 'react-hook-form';
import { CreateUserInput } from '../API';
import { StateContext } from '../context';
import { ConferenceItem, ID, RegistrationService, REGISTRATION_ARRAY, SearchService, User } from '../core';
import { Form } from './Form';
import { InputSelect } from './InputSelect';
import { InputSelectMultiple } from './InputSelectMultiple';
import { InputText } from './InputText';
import { RegistrationSelectGroup } from './RegistrationSelectGroup';
import { withModal } from './withModal';

export interface ShowHideFields {
  role: boolean;
  contact: boolean;
  notes: boolean;
}
export interface DataRegistrationForm {
  FirstName: string;
  LastName: string;
  Groups: string[];
  ConventionRole?: string;
  Email?: string;
  Phone?: string;
  ThursdayReception: boolean;
  FridayReception: boolean;
  FridayWorkshop: boolean;
  FridaySpeakers: boolean;
  FridayBreakfast: boolean;
  FridayLunch: boolean;
  FridayDinner: boolean;
  SaturdaySpeakers: boolean;
  SaturdayBreakfast: boolean;
  SaturdayLunch: boolean;
  SaturdayDinner: boolean;
  Notes?: string;
}

interface PropsRegistrationForm {
  existingData?: User;
}

export const RegistrationForm: FC<PropsRegistrationForm> = withModal(({ existingData, closeModal }) => {
  const { withLoading, updateRegistrations, updateGroups, registrations, groups, roles } = useContext(StateContext);
  const [otherRegistrations] = useState(
    existingData ? registrations?.filter((x) => x.id !== existingData.id) : registrations
  );
  const [show, setShow] = useState<ShowHideFields>({ role: false, contact: false, notes: false });
  const [firstName, setFirstName] = useState<string>(existingData?.firstName || '');
  const [lastName, setLastName] = useState<string>(existingData?.lastName || '');
  const [duplicates, setDuplicates] = useState<string[]>([]);
  const form = useForm<DataRegistrationForm>();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    const name = `${firstName} ${lastName}`;
    setDuplicates(
      name.length > 1
        ? SearchService.fuzzyFind(
            name,
            (otherRegistrations || []).map((user) => `${user.firstName} ${user.lastName}`)
          ).map((result) => result.item)
        : []
    );
  }, [firstName, lastName, otherRegistrations]);

  const onSubmit = form.handleSubmit(
    withLoading<[DataRegistrationForm]>(async (data) => {
      try {
        const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
        const mappedData: CreateUserInput = {
          firstName: data.FirstName.trim(),
          lastName: data.LastName.trim(),
          userRoleId: data.ConventionRole
            ? roles?.find((role) => role.roleName === data.ConventionRole)?.id || (existingData ? null : undefined)
            : existingData
            ? null
            : undefined,
          email: data.Email?.trim(),
          phone: data.Phone?.replace(/\D/g, ''),
          thursdayReception: data.ThursdayReception,
          fridayReception: data.FridayReception,
          fridayWorkshop: data.FridayWorkshop,
          fridaySpeakers: data.FridaySpeakers,
          fridayBreakfast: data.FridayBreakfast,
          fridayLunch: data.FridayLunch,
          fridayDinner: data.FridayDinner,
          saturdaySpeakers: data.SaturdaySpeakers,
          saturdayBreakfast: data.SaturdayBreakfast,
          saturdayLunch: data.SaturdayLunch,
          saturdayDinner: data.SaturdayDinner,
          notes: data.Notes,
        };
        if (existingData) {
          const { id, version: expectedVersion } = existingData;
          const groupIds = groups?.filter((group) => data.Groups.includes(group.groupName)).map((group) => group.id);
          const existingGroupIds = existingData.groups?.items?.map((item) => item!.groupId);
          await RegistrationService.update({ ...mappedData, id, expectedVersion }, groupIds, existingGroupIds);
        } else {
          if (
            !data.ThursdayReception &&
            !data.FridaySpeakers &&
            !data.FridayBreakfast &&
            !data.FridayLunch &&
            !data.FridayReception &&
            !data.FridayDinner &&
            !data.SaturdaySpeakers &&
            !data.SaturdayBreakfast &&
            !data.SaturdayLunch &&
            !data.SaturdayDinner
          ) {
            enqueueSnackbar(`Please select at least one item when adding a new registration.`, { variant: 'error' });
            return;
          }
          await RegistrationService.create(
            mappedData,
            groups!.filter((g) => data.Groups.includes(g.groupName)).map((group) => group.id)
          );
        }
        updateRegistrations();
        updateGroups();
        closeModal();
        snackbar.unshift({
          message: `Successfully ${existingData ? 'updated' : 'created'} registration for ${data.FirstName} ${
            data.LastName
          }`,
          variant: 'success',
        });
        snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
      } catch (error) {
        enqueueSnackbar(
          `Failed to ${existingData ? 'update' : 'create'} registration${
            existingData ? ` for ${existingData.firstName} ${existingData.lastName}` : ''
          }`,
          { variant: 'error' }
        );
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) =>
        enqueueSnackbar((item[1] as FieldError | undefined)?.message, { variant: 'error' })
      );
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          {existingData ? 'Edit registration' : 'Add new registration'}
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" flexWrap="wrap">
            <Box display="flex" flexGrow={1} margin="2px">
              <InputText
                id={ID.Form_Registration_FirstName}
                name="FirstName"
                defaultValue={existingData?.firstName}
                rules={{ required: true, validate: (x) => x.trim() !== '' || 'First Name required' }}
                autoFocus={!existingData}
                size="small"
                onChange={(event) => setFirstName(event.target.value)}
              />
            </Box>
            <Box display="flex" flexGrow={2} margin="2px">
              <InputText
                id={ID.Form_Registration_LastName}
                name="LastName"
                defaultValue={existingData?.lastName}
                rules={{ required: true, validate: (x) => x.trim() !== '' || 'Last Name required' }}
                size="small"
                onChange={(event) => setLastName(event.target.value)}
              />
            </Box>
          </Box>
          <Box display="flex" margin="5px">
            <Typography variant="body2">Similar:&nbsp;</Typography>
            <Typography variant="body2" color="secondary">
              {duplicates.join(', ')}
            </Typography>
          </Box>
          <Box display="flex" margin="2px">
            <InputSelectMultiple
              name="Groups"
              defaultValue={existingData?.groups?.items?.map((item) => item?.group.groupName || '')}
              options={(groups || []).map((group) => group.groupName)}
              filterSelectedOptions
              size="small"
            />
          </Box>
          {show.role && (
            <Box display="flex" margin="2px">
              <InputSelect
                name="ConventionRole"
                defaultValue={existingData?.role?.roleName}
                options={(roles || []).map((role) => role.roleName)}
                size="small"
              />
            </Box>
          )}
          {show.contact && (
            <Box display="flex" flexWrap="wrap">
              <Box display="flex" flexGrow={2} margin="2px">
                <InputText
                  name="Email"
                  label={'Email' + (!!existingData?.accessRole ? ' (readonly)' : '')}
                  defaultValue={existingData?.email}
                  InputProps={{ readOnly: !!existingData?.accessRole }}
                  rules={{
                    validate: {
                      unique: (email) =>
                        !email ||
                        otherRegistrations?.every((other) => other.email !== email) ||
                        'Email is already used for another registration',
                    },
                  }}
                  size="small"
                />
              </Box>
              <Box display="flex" flexGrow={1} margin="2px">
                <InputText phone defaultValue={existingData?.phone} size="small" />
              </Box>
            </Box>
          )}
          {show.notes && (
            <Box display="flex" margin="2px">
              <InputText id={ID.Form_Registration_Notes} name="Notes" defaultValue={existingData?.notes} size="small" />
            </Box>
          )}
          <Box display="flex" margin="2px">
            {!show.role && (
              <Button
                color="primary"
                size="small"
                startIcon={existingData ? <Visibility /> : <AddRounded />}
                onClick={() => setShow((p) => ({ ...p, role: true }))}
                style={{ margin: '0.25rem' }}
              >
                Role
              </Button>
            )}
            {!show.contact && (
              <Button
                color="primary"
                size="small"
                startIcon={existingData ? <Visibility /> : <AddRounded />}
                onClick={() => setShow((p) => ({ ...p, contact: true }))}
                style={{ margin: '0.25rem' }}
              >
                Contact
              </Button>
            )}
            {!show.notes && (
              <Button
                color="primary"
                size="small"
                startIcon={existingData ? <Visibility /> : <AddRounded />}
                onClick={() => setShow((p) => ({ ...p, notes: true }))}
                style={{ margin: '0.25rem' }}
              >
                Notes
              </Button>
            )}
          </Box>
        </Box>
        <Box display="flex" flexWrap="wrap" flexGrow={1} margin="10px">
          {REGISTRATION_ARRAY.map(([grouping, items], idx) => {
            return (
              <RegistrationSelectGroup
                key={idx}
                grouping={grouping}
                register={form.register}
                existingSelections={
                  existingData &&
                  items.reduce(
                    (p, c) =>
                      grouping === ConferenceItem.Reception
                        ? {
                            ...p,
                            [c.toLowerCase()]: existingData[`${c.toLowerCase()}${grouping}` as keyof User],
                          }
                        : {
                            ...p,
                            [c.toLowerCase()]: existingData[`${grouping.toLowerCase()}${c}` as keyof User],
                          },
                    {}
                  )
                }
              />
            );
          })}
        </Box>
        <Box height="8px" />
        <Box display="flex" justifyContent="center">
          <Button
            id={ID.Form_Registration_Submit}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem' }}
          >
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
