import { API } from 'aws-amplify';
import { graphqlOperation, GraphQLResult } from '@aws-amplify/api-graphql';
import Observable, { ZenObservable } from 'zen-observable-ts';

import {
  CreateBadgeDownloadMutation,
  CreateBadgeDownloadMutationVariables,
  CreateDiscountMutation,
  CreateDiscountMutationVariables,
  CreateDiscountPackageMutation,
  CreateDiscountPackageMutationVariables,
  CreateErrorHistoryMutation,
  CreateErrorHistoryMutationVariables,
  CreateGroupMutation,
  CreateGroupMutationVariables,
  CreateMasterSwitchMutation,
  CreateMasterSwitchMutationVariables,
  CreateRegistrationHistoryMutation,
  CreateRegistrationHistoryMutationVariables,
  CreateRoleMutation,
  CreateRoleMutationVariables,
  CreateUserGroupMutation,
  CreateUserGroupMutationVariables,
  CreateUserGroupRegistrarMutation,
  CreateUserGroupRegistrarMutationVariables,
  CreateUserMutation,
  CreateUserMutationVariables,
  CreateUserPermissionMutation,
  CreateUserPermissionMutationVariables,
  DeleteBadgeDownloadMutation,
  DeleteBadgeDownloadMutationVariables,
  DeleteDiscountMutation,
  DeleteDiscountMutationVariables,
  DeleteDiscountPackageMutation,
  DeleteDiscountPackageMutationVariables,
  DeleteGroupMutation,
  DeleteGroupMutationVariables,
  DeleteMasterSwitchMutation,
  DeleteMasterSwitchMutationVariables,
  DeleteUserGroupMutation,
  DeleteUserGroupMutationVariables,
  DeleteUserGroupRegistrarMutation,
  DeleteUserGroupRegistrarMutationVariables,
  DeleteUserMutation,
  DeleteUserMutationVariables,
  DeleteUserPermissionMutation,
  DeleteUserPermissionMutationVariables,
  ErrorHistory,
  GetDeveloperCredsQuery,
  GetDeveloperCredsQueryVariables,
  GetGroupByNameQuery,
  GetGroupByNameQueryVariables,
  GetUserByCognitoIdQuery,
  GetUserByCognitoIdQueryVariables,
  GetUserQuery,
  GetUserQueryVariables,
  ListAppPermissionsQuery,
  ListAppPermissionsQueryVariables,
  ListBadgeDownloadsQuery,
  ListBadgeDownloadsQueryVariables,
  ListDiscountPackagesQuery,
  ListDiscountPackagesQueryVariables,
  ListErrorHistorysQuery,
  ListErrorHistorysQueryVariables,
  ListGroupsQuery,
  ListGroupsQueryVariables,
  ListMasterSwitchsQuery,
  ListMasterSwitchsQueryVariables,
  ListRegistrationHistorysQuery,
  ListRegistrationHistorysQueryVariables,
  ListRolesQuery,
  ListRolesQueryVariables,
  ListUsersQuery,
  ListUsersQueryVariables,
  UpdateBadgeDownloadMutation,
  UpdateBadgeDownloadMutationVariables,
  UpdateDiscountMutation,
  UpdateDiscountMutationVariables,
  UpdateDiscountPackageMutation,
  UpdateDiscountPackageMutationVariables,
  UpdateGroupMutation,
  UpdateGroupMutationVariables,
  UpdateRoleMutation,
  UpdateRoleMutationVariables,
  UpdateUserMutation,
  UpdateUserMutationVariables,
  UserGroupRegistrar,
} from '../API';
import {
  AppPermission,
  BadgeDownload,
  DeveloperCreds,
  DiscountPackage,
  Group,
  MasterSwitch,
  Maybe,
  RegistrationHistory,
  Role,
  User,
  UserGroup,
  UserPermission,
} from './graphql.types';
import { LoggingService } from './logging.service';
import {
  createBadgeDownload,
  createDiscount,
  createDiscountPackage,
  createErrorHistory,
  createGroup,
  createMasterSwitch,
  createRegistrationHistory,
  createRole,
  createUser,
  createUserGroup,
  createUserGroupRegistrar,
  createUserPermission,
  deleteBadgeDownload,
  deleteDiscount,
  deleteDiscountPackage,
  deleteGroup,
  deleteMasterSwitch,
  deleteUser,
  deleteUserGroup,
  deleteUserGroupRegistrar,
  deleteUserPermission,
  getDeveloperCreds,
  getGroupByNameCustom,
  getUserByCognitoIdCustom,
  getUserCustom,
  listAppPermissions,
  listBadgeDownloads,
  listDiscountPackagesCustom,
  listErrorHistorys,
  listGroupsCustom,
  listMasterSwitchs,
  listRegistrationHistorys,
  listRoles,
  listUsersCustom,
  onCreateMasterSwitch,
  onDeleteMasterSwitch,
  updateBadgeDownload,
  updateDiscount,
  updateDiscountPackage,
  updateGroup,
  updateRole,
  updateUser,
} from '../graphql';

export abstract class ApiService {
  static getUser = (variables: GetUserQueryVariables): Promise<Maybe<User>> =>
    (API.graphql(graphqlOperation(getUserCustom, variables)) as Promise<GraphQLResult<GetUserQuery>>).then(
      (result) => result.data?.getUser || null,
      LoggingService.error('ApiService#getUser', (_error) => null)
    );

  static getUserByCognitoId = (variables: GetUserByCognitoIdQueryVariables): Promise<Maybe<User>> =>
    (
      API.graphql(graphqlOperation(getUserByCognitoIdCustom, variables)) as Promise<
        GraphQLResult<GetUserByCognitoIdQuery>
      >
    ).then(
      (result) => result.data?.getUserByCognitoId?.items?.shift() || null,
      LoggingService.error('ApiService#getUserByCognitoId', (_error) => null)
    );

  static listUsers = (variables?: ListUsersQueryVariables): Promise<Maybe<User[]>> =>
    (
      API.graphql(graphqlOperation(listUsersCustom, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListUsersQuery>
      >
    )
      .then((result) => {
        const items = (result.data?.listUsers?.items as User[]) || null;
        const nextToken = result.data?.listUsers?.nextToken;
        if (!nextToken) return items;
        return ApiService.listUsers({ nextToken }).then((nextItems) => nextItems?.concat(items));
      })
      .catch(LoggingService.error('ApiService#listUsers', (_error) => null));

  static listGroups = (variables?: ListGroupsQueryVariables): Promise<Maybe<Group[]>> =>
    (
      API.graphql(graphqlOperation(listGroupsCustom, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListGroupsQuery>
      >
    ).then(
      (result) => (result.data?.listGroups?.items as Group[]) || null,
      LoggingService.error('ApiService#listGroups', (_error) => null)
    );

  static listRoles = (variables?: ListRolesQueryVariables): Promise<Maybe<Role[]>> =>
    (
      API.graphql(graphqlOperation(listRoles, { limit: 1000, ...variables })) as Promise<GraphQLResult<ListRolesQuery>>
    ).then(
      (result) => (result.data?.listRoles?.items as Role[]) || null,
      LoggingService.error('ApiService#listRoles', (_error) => null)
    );

  static listPermissions = (variables?: ListAppPermissionsQueryVariables): Promise<Maybe<AppPermission[]>> =>
    (
      API.graphql(graphqlOperation(listAppPermissions, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListAppPermissionsQuery>
      >
    ).then(
      (result) => (result.data?.listAppPermissions?.items as AppPermission[]) || null,
      LoggingService.error('ApiService#listPermissions', (_error) => null)
    );

  static createUserPermission = (variables?: CreateUserPermissionMutationVariables): Promise<Maybe<UserPermission[]>> =>
    (
      API.graphql(graphqlOperation(createUserPermission, variables)) as Promise<
        GraphQLResult<CreateUserPermissionMutation>
      >
    ).then(
      (result) => result.data?.createUserPermission || null,
      LoggingService.error('ApiService#createUserPermission', (_error) => null)
    );

  static deleteUserPermission = (variables?: DeleteUserPermissionMutationVariables): Promise<Maybe<UserPermission[]>> =>
    (
      API.graphql(graphqlOperation(deleteUserPermission, variables)) as Promise<
        GraphQLResult<DeleteUserPermissionMutation>
      >
    ).then(
      (result) => result.data?.deleteUserPermission || null,
      LoggingService.error('ApiService#deleteUserPermission', (_error) => null)
    );

  static createUserGroup = (variables?: CreateUserGroupMutationVariables): Promise<Maybe<UserGroup[]>> =>
    (API.graphql(graphqlOperation(createUserGroup, variables)) as Promise<GraphQLResult<CreateUserGroupMutation>>).then(
      (result) => result.data?.createUserGroup || null,
      LoggingService.error('ApiService#createUserGroup', (_error) => null)
    );

  static createUserGroupRegistrar = (
    variables?: CreateUserGroupRegistrarMutationVariables
  ): Promise<Maybe<UserGroupRegistrar[]>> =>
    (
      API.graphql(graphqlOperation(createUserGroupRegistrar, variables)) as Promise<
        GraphQLResult<CreateUserGroupRegistrarMutation>
      >
    ).then(
      (result) => result.data?.createUserGroupRegistrar || null,
      LoggingService.error('ApiService#createUserGroupRegistrar', (_error) => null)
    );

  static deleteUserGroup = (variables?: DeleteUserGroupMutationVariables): Promise<Maybe<UserGroup[]>> =>
    (API.graphql(graphqlOperation(deleteUserGroup, variables)) as Promise<GraphQLResult<DeleteUserGroupMutation>>).then(
      (result) => result.data?.deleteUserGroup || null,
      LoggingService.error('ApiService#deleteUserGroup', (_error) => null)
    );

  static deleteUserGroupRegistrar = (
    variables?: DeleteUserGroupRegistrarMutationVariables
  ): Promise<Maybe<UserGroupRegistrar[]>> =>
    (
      API.graphql(graphqlOperation(deleteUserGroupRegistrar, variables)) as Promise<
        GraphQLResult<DeleteUserGroupRegistrarMutation>
      >
    ).then(
      (result) => result.data?.deleteUserGroupRegistrar || null,
      LoggingService.error('ApiService#deleteUserGroupRegistrar', (_error) => null)
    );

  static getDeveloperCreds = (variables: GetDeveloperCredsQueryVariables): Promise<Maybe<DeveloperCreds>> =>
    (
      API.graphql(graphqlOperation(getDeveloperCreds, variables)) as Promise<GraphQLResult<GetDeveloperCredsQuery>>
    ).then(
      (result) => result.data?.getDeveloperCreds || null,
      LoggingService.error('ApiService#getDeveloperCreds', (_error) => null)
    );

  static createUser = (variables: CreateUserMutationVariables): Promise<Maybe<User>> =>
    (API.graphql(graphqlOperation(createUser, variables)) as Promise<GraphQLResult<CreateUserMutation>>).then(
      (result) => result.data?.createUser || null,
      LoggingService.error('ApiService#createUser', (error) => {
        throw error;
      })
    );

  static createGroup = (variables: CreateGroupMutationVariables): Promise<Maybe<Group>> =>
    (API.graphql(graphqlOperation(createGroup, variables)) as Promise<GraphQLResult<CreateGroupMutation>>).then(
      (result) => result.data?.createGroup || null,
      LoggingService.error('ApiService#createGroup', (error) => {
        throw error;
      })
    );

  static deleteGroup = (variables: DeleteGroupMutationVariables): Promise<Maybe<Group>> =>
    (API.graphql(graphqlOperation(deleteGroup, variables)) as Promise<GraphQLResult<DeleteGroupMutation>>).then(
      (result) => result.data?.deleteGroup || null,
      LoggingService.error('ApiService#deleteGroup', (error) => {
        throw error;
      })
    );

  static createRole = (variables: CreateRoleMutationVariables): Promise<Maybe<Role>> =>
    (API.graphql(graphqlOperation(createRole, variables)) as Promise<GraphQLResult<CreateRoleMutation>>).then(
      (result) => result.data?.createRole || null,
      LoggingService.error('ApiService#createRole', (error) => {
        throw error;
      })
    );

  static updateUser = (variables: UpdateUserMutationVariables): Promise<Maybe<User>> =>
    (API.graphql(graphqlOperation(updateUser, variables)) as Promise<GraphQLResult<UpdateUserMutation>>).then(
      (result) => result.data?.updateUser || null,
      LoggingService.error('ApiService#updateUser', (error) => {
        throw error;
      })
    );

  static updateGroup = (variables: UpdateGroupMutationVariables): Promise<Maybe<Group>> =>
    (API.graphql(graphqlOperation(updateGroup, variables)) as Promise<GraphQLResult<UpdateGroupMutation>>).then(
      (result) => result.data?.updateGroup || null,
      LoggingService.error('ApiService#updateGroup', (error) => {
        throw error;
      })
    );

  static updateRole = (variables: UpdateRoleMutationVariables): Promise<Maybe<Role>> =>
    (API.graphql(graphqlOperation(updateRole, variables)) as Promise<GraphQLResult<UpdateRoleMutation>>).then(
      (result) => result.data?.updateRole || null,
      LoggingService.error('ApiService#updateRole', (error) => {
        throw error;
      })
    );

  static deleteUser = (variables: DeleteUserMutationVariables): Promise<Maybe<User>> =>
    (API.graphql(graphqlOperation(deleteUser, variables)) as Promise<GraphQLResult<DeleteUserMutation>>).then(
      (result) => result.data?.deleteUser || null,
      LoggingService.error('ApiService#deleteUser', (error) => {
        throw error;
      })
    );

  static getGroupByName = (variables: GetGroupByNameQueryVariables): Promise<Maybe<Group>> =>
    (
      API.graphql(graphqlOperation(getGroupByNameCustom, variables)) as Promise<GraphQLResult<GetGroupByNameQuery>>
    ).then(
      (result) => result.data?.getGroupByName?.items?.shift() || null,
      LoggingService.error('ApiService#getGroupByName', (error) => {
        throw error;
      })
    );

  static createBadgeDownload = (variables: CreateBadgeDownloadMutationVariables): Promise<Maybe<BadgeDownload>> =>
    (
      API.graphql(graphqlOperation(createBadgeDownload, variables)) as Promise<
        GraphQLResult<CreateBadgeDownloadMutation>
      >
    ).then(
      (result) => result.data?.createBadgeDownload || null,
      LoggingService.error('ApiService#createBadgeDownload', (error) => {
        throw error;
      })
    );

  static listBadgeDownloads = (variables?: ListBadgeDownloadsQueryVariables): Promise<Maybe<BadgeDownload[]>> =>
    (
      API.graphql(graphqlOperation(listBadgeDownloads, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListBadgeDownloadsQuery>
      >
    ).then(
      (result) => (result.data?.listBadgeDownloads?.items as BadgeDownload[]) || null,
      LoggingService.error('ApiService#listBadgeDownloads', (_error) => null)
    );

  static updateBadgeDownload = (variables: UpdateBadgeDownloadMutationVariables): Promise<Maybe<BadgeDownload>> =>
    (
      API.graphql(graphqlOperation(updateBadgeDownload, variables)) as Promise<
        GraphQLResult<UpdateBadgeDownloadMutation>
      >
    ).then(
      (result) => result.data?.updateBadgeDownload || null,
      LoggingService.error('ApiService#updateBadgeDownload', (error) => {
        throw error;
      })
    );

  static deleteBadgeDownload = (variables: DeleteBadgeDownloadMutationVariables): Promise<Maybe<BadgeDownload>> =>
    (
      API.graphql(graphqlOperation(deleteBadgeDownload, variables)) as Promise<
        GraphQLResult<DeleteBadgeDownloadMutation>
      >
    ).then(
      (result) => result.data?.deleteBadgeDownload || null,
      LoggingService.error('ApiService#deleteBadgeDownload', (error) => {
        throw error;
      })
    );

  static listMasterSwitchs = (variables?: ListMasterSwitchsQueryVariables): Promise<Maybe<MasterSwitch[]>> =>
    (
      API.graphql(graphqlOperation(listMasterSwitchs, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListMasterSwitchsQuery>
      >
    ).then(
      (result) => (result.data?.listMasterSwitchs?.items as MasterSwitch[]) || null,
      LoggingService.error('ApiService#listMasterSwitchs', (_error) => null)
    );

  static createMasterSwitch = (variables: CreateMasterSwitchMutationVariables): Promise<Maybe<MasterSwitch>> =>
    (
      API.graphql(graphqlOperation(createMasterSwitch, variables)) as Promise<GraphQLResult<CreateMasterSwitchMutation>>
    ).then(
      (result) => result.data?.createMasterSwitch || null,
      LoggingService.error('ApiService#createMasterSwitch', (error) => {
        throw error;
      })
    );

  static deleteMasterSwitch = (variables: DeleteMasterSwitchMutationVariables): Promise<Maybe<MasterSwitch>> =>
    (
      API.graphql(graphqlOperation(deleteMasterSwitch, variables)) as Promise<GraphQLResult<DeleteMasterSwitchMutation>>
    ).then(
      (result) => result.data?.deleteMasterSwitch || null,
      LoggingService.error('ApiService#deleteMasterSwitch', (error) => {
        throw error;
      })
    );

  static onCreateMasterSwitch = (next: (value: any) => void): ZenObservable.Subscription =>
    (API.graphql(graphqlOperation(onCreateMasterSwitch)) as Observable<any>).subscribe({
      next,
      error: (error) => console.warn(error),
    });

  static onDeleteMasterSwitch = (next: (value: any) => void): ZenObservable.Subscription =>
    (API.graphql(graphqlOperation(onDeleteMasterSwitch)) as Observable<any>).subscribe({
      next,
      error: (error) => console.warn(error),
    });

  static createRegistrationHistory = (
    variables: CreateRegistrationHistoryMutationVariables
  ): Promise<Maybe<RegistrationHistory>> =>
    (
      API.graphql(graphqlOperation(createRegistrationHistory, variables)) as Promise<
        GraphQLResult<CreateRegistrationHistoryMutation>
      >
    ).then(
      (result) => result.data?.createRegistrationHistory || null,
      LoggingService.error('ApiService#createRegistrationHistory', (error) => {
        throw error;
      })
    );

  static listRegistrationHistorys = (
    variables?: ListRegistrationHistorysQueryVariables
  ): Promise<RegistrationHistory[]> =>
    (
      API.graphql(graphqlOperation(listRegistrationHistorys, { limit: 2000, ...variables })) as Promise<
        GraphQLResult<ListRegistrationHistorysQuery>
      >
    )
      .then((result) => {
        const items = (result.data?.listRegistrationHistorys?.items || []) as RegistrationHistory[];
        const nextToken = result.data?.listRegistrationHistorys?.nextToken;
        if (!nextToken) return items;
        return ApiService.listRegistrationHistorys({ nextToken }).then((nextItems) => nextItems.concat(items));
      })
      .catch(LoggingService.error('ApiService#listRegistrationHistorys', (_error) => null));

  static createErrorHistory = (variables: CreateErrorHistoryMutationVariables): Promise<Maybe<ErrorHistory>> =>
    (
      API.graphql(graphqlOperation(createErrorHistory, variables)) as Promise<GraphQLResult<CreateErrorHistoryMutation>>
    ).then(
      (result) => result.data?.createErrorHistory || null,
      (error) => console.log(error) as any
    );

  static listErrorHistorys = (variables?: ListErrorHistorysQueryVariables): Promise<ErrorHistory[]> =>
    (
      API.graphql(graphqlOperation(listErrorHistorys, { limit: 2000, ...variables })) as Promise<
        GraphQLResult<ListErrorHistorysQuery>
      >
    )
      .then((result) => {
        const items = (result.data?.listErrorHistorys?.items || []) as ErrorHistory[];
        const nextToken = result.data?.listErrorHistorys?.nextToken;
        if (!nextToken) return items;
        return ApiService.listErrorHistorys({ nextToken }).then((nextItems) => nextItems.concat(items));
      })
      .catch(LoggingService.error('ApiService#listErrorHistorys', (_error) => null));

  static createDiscountPackage = (variables: CreateDiscountPackageMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (
      API.graphql(graphqlOperation(createDiscountPackage, variables)) as Promise<
        GraphQLResult<CreateDiscountPackageMutation>
      >
    ).then(
      (result) => result.data?.createDiscountPackage || null,
      LoggingService.error('ApiService#createDiscountPackage', (error) => {
        throw error;
      })
    );

  static updateDiscountPackage = (variables: UpdateDiscountPackageMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (
      API.graphql(graphqlOperation(updateDiscountPackage, variables)) as Promise<
        GraphQLResult<UpdateDiscountPackageMutation>
      >
    ).then(
      (result) => result.data?.updateDiscountPackage || null,
      LoggingService.error('ApiService#updateDiscountPackage', (error) => {
        throw error;
      })
    );

  static deleteDiscountPackage = (variables: DeleteDiscountPackageMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (
      API.graphql(graphqlOperation(deleteDiscountPackage, variables)) as Promise<
        GraphQLResult<DeleteDiscountPackageMutation>
      >
    ).then(
      (result) => result.data?.deleteDiscountPackage || null,
      LoggingService.error('ApiService#deleteDiscountPackage', (error) => {
        throw error;
      })
    );

  static listDiscountPackages = (variables?: ListDiscountPackagesQueryVariables): Promise<Maybe<DiscountPackage[]>> =>
    (
      API.graphql(graphqlOperation(listDiscountPackagesCustom, { limit: 1000, ...variables })) as Promise<
        GraphQLResult<ListDiscountPackagesQuery>
      >
    ).then(
      (result) => (result.data?.listDiscountPackages?.items as DiscountPackage[]) || null,
      LoggingService.error('ApiService#listDiscountPackages', (_error) => null)
    );

  static createDiscount = (variables: CreateDiscountMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (API.graphql(graphqlOperation(createDiscount, variables)) as Promise<GraphQLResult<CreateDiscountMutation>>).then(
      (result) => result.data?.createDiscount || null,
      LoggingService.error('ApiService#createDiscount', (error) => {
        throw error;
      })
    );

  static updateDiscount = (variables: UpdateDiscountMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (API.graphql(graphqlOperation(updateDiscount, variables)) as Promise<GraphQLResult<UpdateDiscountMutation>>).then(
      (result) => result.data?.updateDiscount || null,
      LoggingService.error('ApiService#updateDiscount', (error) => {
        throw error;
      })
    );

  static deleteDiscount = (variables: DeleteDiscountMutationVariables): Promise<Maybe<DiscountPackage>> =>
    (API.graphql(graphqlOperation(deleteDiscount, variables)) as Promise<GraphQLResult<DeleteDiscountMutation>>).then(
      (result) => result.data?.deleteDiscount || null,
      LoggingService.error('ApiService#deleteDiscount', (error) => {
        throw error;
      })
    );
}
