import { Box, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { useForm } from 'react-hook-form';

import { StateContext } from '../context';
import { ID, RegistrationService, User } from '../core';
import { Form } from './Form';
import { InputSelectMultiple } from './InputSelectMultiple';
import { withModal } from './withModal';

interface DataUserUpdate {
  Groups: string[];
}

interface Props {
  existingData: User;
}

export const UserUpdate: FC<Props> = withModal(({ existingData, closeModal }) => {
  const { withLoading, updateRegistrations, updateGroups, groups } = useContext(StateContext);
  const form = useForm<DataUserUpdate>();
  const { enqueueSnackbar } = useSnackbar();

  const onSubmit = form.handleSubmit(
    withLoading<[DataUserUpdate]>(async (data) => {
      try {
        const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
        const userId = existingData.id;
        const groupRegistrarIds = groups
          ?.filter((group) => data.Groups.includes(group.groupName))
          .map((group) => group.id);
        const existingGroupRegistrarIds = existingData.groupsRegistrar?.items?.map((item) => item!.groupId);
        const existingGroupMemberIds = existingData.groups?.items?.map((item) => item!.groupId);
        await RegistrationService.updateRegistrarGroups(
          userId,
          groupRegistrarIds,
          existingGroupRegistrarIds,
          existingGroupMemberIds
        );
        updateRegistrations();
        updateGroups();
        closeModal();
        snackbar.unshift({
          message: `Successfully updated groups for ${existingData.firstName} ${existingData.lastName}`,
          variant: 'success',
        });
        snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
      } catch (error) {
        enqueueSnackbar('Failed to add new user.', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1][0]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          Edit {existingData.firstName} {existingData.lastName}
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        {[
          <InputSelectMultiple
            id={ID.Form_UserEdit_Groups}
            name="Groups"
            defaultValue={existingData.groupsRegistrar?.items?.map((item) => item?.group.groupName || '')}
            options={(groups || []).map((group) => group.groupName)}
            filterSelectedOptions
            size="small"
          />,
        ]
          .filter((x) => !!x)
          .map((field, idx) => (
            <Box key={idx} display="flex" margin="2px">
              {field}
            </Box>
          ))}
        <Box display="flex" marginTop="20px" justifyContent="center">
          <Button
            id={ID.Form_UserEdit_Submit}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem' }}
          >
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
