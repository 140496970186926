import { ActivityService } from './activity.service';

export abstract class LoggingService {
  static error =
    (name: string, fn: (error: any) => any) =>
    (error: any): any => {
      console.log('error', name, error);
      ActivityService.logError(JSON.stringify({ name, error }));
      return fn(error);
    };
}
