export const listUsersCustom = /* GraphQL */ `
  query ListUsersCustom($filter: ModelUserFilterInput, $limit: Int, $nextToken: String) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        cognitoId
        donorPerfectId
        accessRole
        accessGrantedBy {
          id
        }
        firstName
        lastName
        email
        phone
        groups {
          items {
            groupId
            group {
              id
              groupName
            }
          }
          nextToken
        }
        groupsRegistrar {
          items {
            groupId
            group {
              id
              groupName
            }
          }
          nextToken
        }
        role {
          id
          roleName
        }
        permissions {
          items {
            permission {
              id
              permissionName
            }
          }
          nextToken
        }
        thursdayReception
        fridayReception
        fridayWorkshop
        fridaySpeakers
        fridayBreakfast
        fridayLunch
        fridayDinner
        saturdaySpeakers
        saturdayBreakfast
        saturdayLunch
        saturdayDinner
        notes
        version
      }
      nextToken
    }
  }
`;
export const getUserCustom = /* GraphQL */ `
  query GetUserCustom($id: ID!) {
    getUser(id: $id) {
      id
      cognitoId
      accessRole
      firstName
      lastName
      email
      phone
      groups {
        items {
          groupId
          group {
            id
            groupName
          }
        }
        nextToken
      }
      groupsRegistrar {
        items {
          groupId
          group {
            id
            groupName
          }
        }
        nextToken
      }
      role {
        id
        roleName
      }
      permissions {
        items {
          permission {
            id
            permissionName
          }
        }
        nextToken
      }
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      version
    }
  }
`;
export const listGroupsCustom = /* GraphQL */ `
  query ListGroupsCustom($filter: ModelGroupFilterInput, $limit: Int, $nextToken: String) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupName
        managedBy {
          id
          firstName
          lastName
        }
        notes
        users {
          items {
            userId
          }
          nextToken
        }
        registrars {
          items {
            userId
          }
          nextToken
        }
        discountPackageId
        discountPackage {
          name
          discounts {
            items {
              priority
              type
              discountItem
              discountCount
              discountRate
              creditAmount
            }
          }
        }
        version
      }
      nextToken
    }
  }
`;
export const getUserByCognitoIdCustom = /* GraphQL */ `
  query GetUserByCognitoIdCustom(
    $cognitoId: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserByCognitoId(
      cognitoId: $cognitoId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        cognitoId
        accessRole
        firstName
        lastName
        email
        phone
        groups {
          items {
            groupId
            group {
              id
              groupName
            }
          }
          nextToken
        }
        groupsRegistrar {
          items {
            groupId
            group {
              id
              groupName
              managedBy {
                id
                firstName
                lastName
              }
              discountPackageId
              discountPackage {
                name
              }
              users {
                items {
                  userId
                }
              }
            }
          }
          nextToken
        }
        userRoleId
        role {
          id
          roleName
          version
        }
        permissions {
          items {
            permission {
              id
              permissionName
            }
          }
          nextToken
        }
        thursdayReception
        fridayReception
        fridayWorkshop
        fridaySpeakers
        fridayBreakfast
        fridayLunch
        fridayDinner
        saturdaySpeakers
        saturdayBreakfast
        saturdayLunch
        saturdayDinner
        notes
        version
      }
      nextToken
    }
  }
`;
export const getGroupByNameCustom = /* GraphQL */ `
  query GetGroupByNameCustom(
    $groupName: String
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getGroupByName(
      groupName: $groupName
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupName
        notes
        users(limit: 1500) {
          items {
            user {
              id
              cognitoId
              accessRole
              firstName
              lastName
              email
              phone
              groups {
                items {
                  group {
                    id
                    groupName
                  }
                }
                nextToken
              }
              role {
                id
                roleName
              }
              thursdayReception
              fridayReception
              fridayWorkshop
              fridaySpeakers
              fridayBreakfast
              fridayLunch
              fridayDinner
              saturdaySpeakers
              saturdayBreakfast
              saturdayLunch
              saturdayDinner
              notes
              version
            }
          }
          nextToken
        }
        version
      }
      nextToken
    }
  }
`;
export const listDiscountPackagesCustom = /* GraphQL */ `
  query ListDiscountPackagesCustom($filter: ModelDiscountPackageFilterInput, $limit: Int, $nextToken: String) {
    listDiscountPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        discounts {
          items {
            id
            type
            priority
            creditAmount
            discountItem
            discountRate
            discountCount
            version
          }
          nextToken
        }
        groups {
          items {
            id
            groupName
            version
          }
          nextToken
        }
        createdAt
        updatedAt
        version
      }
      nextToken
    }
  }
`;
