/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getUser = /* GraphQL */ `query GetUser($id: ID!) {
  getUser(id: $id) {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<APITypes.GetUserQueryVariables, APITypes.GetUserQuery>;
export const listUsers = /* GraphQL */ `query ListUsers(
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListUsersQueryVariables, APITypes.ListUsersQuery>;
export const getGroup = /* GraphQL */ `query GetGroup($id: ID!) {
  getGroup(id: $id) {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<APITypes.GetGroupQueryVariables, APITypes.GetGroupQuery>;
export const listGroups = /* GraphQL */ `query ListGroups(
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListGroupsQueryVariables,
  APITypes.ListGroupsQuery
>;
export const getRole = /* GraphQL */ `query GetRole($id: ID!) {
  getRole(id: $id) {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<APITypes.GetRoleQueryVariables, APITypes.GetRoleQuery>;
export const listRoles = /* GraphQL */ `query ListRoles(
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListRolesQueryVariables, APITypes.ListRolesQuery>;
export const getAppPermission = /* GraphQL */ `query GetAppPermission($id: ID!) {
  getAppPermission(id: $id) {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetAppPermissionQueryVariables,
  APITypes.GetAppPermissionQuery
>;
export const listAppPermissions = /* GraphQL */ `query ListAppPermissions(
  $filter: ModelAppPermissionFilterInput
  $limit: Int
  $nextToken: String
) {
  listAppPermissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListAppPermissionsQueryVariables,
  APITypes.ListAppPermissionsQuery
>;
export const getDeveloperCreds = /* GraphQL */ `query GetDeveloperCreds($name: String!) {
  getDeveloperCreds(name: $name) {
    name
    region
    accessKeyId
    secretAccessKey
    userPoolId
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDeveloperCredsQueryVariables,
  APITypes.GetDeveloperCredsQuery
>;
export const listDeveloperCredss = /* GraphQL */ `query ListDeveloperCredss(
  $name: String
  $filter: ModelDeveloperCredsFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listDeveloperCredss(
    name: $name
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      name
      region
      accessKeyId
      secretAccessKey
      userPoolId
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDeveloperCredssQueryVariables,
  APITypes.ListDeveloperCredssQuery
>;
export const getBadgeDownload = /* GraphQL */ `query GetBadgeDownload($id: ID!) {
  getBadgeDownload(id: $id) {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetBadgeDownloadQueryVariables,
  APITypes.GetBadgeDownloadQuery
>;
export const listBadgeDownloads = /* GraphQL */ `query ListBadgeDownloads(
  $filter: ModelBadgeDownloadFilterInput
  $limit: Int
  $nextToken: String
) {
  listBadgeDownloads(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      day
      type
      printed
      usersUpdate
      usersRemove
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListBadgeDownloadsQueryVariables,
  APITypes.ListBadgeDownloadsQuery
>;
export const getRegistrationHistory = /* GraphQL */ `query GetRegistrationHistory($id: ID!) {
  getRegistrationHistory(id: $id) {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRegistrationHistoryQueryVariables,
  APITypes.GetRegistrationHistoryQuery
>;
export const listRegistrationHistorys = /* GraphQL */ `query ListRegistrationHistorys(
  $filter: ModelRegistrationHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listRegistrationHistorys(
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      user
      event
      createdAt
      registrationId
      firstName
      lastName
      email
      phone
      groups
      role
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListRegistrationHistorysQueryVariables,
  APITypes.ListRegistrationHistorysQuery
>;
export const getErrorHistory = /* GraphQL */ `query GetErrorHistory($id: ID!) {
  getErrorHistory(id: $id) {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetErrorHistoryQueryVariables,
  APITypes.GetErrorHistoryQuery
>;
export const listErrorHistorys = /* GraphQL */ `query ListErrorHistorys(
  $filter: ModelErrorHistoryFilterInput
  $limit: Int
  $nextToken: String
) {
  listErrorHistorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      user
      createdAt
      message
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListErrorHistorysQueryVariables,
  APITypes.ListErrorHistorysQuery
>;
export const getDiscount = /* GraphQL */ `query GetDiscount($id: ID!) {
  getDiscount(id: $id) {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountQueryVariables,
  APITypes.GetDiscountQuery
>;
export const listDiscounts = /* GraphQL */ `query ListDiscounts(
  $filter: ModelDiscountFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscounts(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      type
      priority
      creditAmount
      discountItem
      discountRate
      discountCount
      packageId
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscountsQueryVariables,
  APITypes.ListDiscountsQuery
>;
export const getDiscountPackage = /* GraphQL */ `query GetDiscountPackage($id: ID!) {
  getDiscountPackage(id: $id) {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountPackageQueryVariables,
  APITypes.GetDiscountPackageQuery
>;
export const listDiscountPackages = /* GraphQL */ `query ListDiscountPackages(
  $filter: ModelDiscountPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  listDiscountPackages(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListDiscountPackagesQueryVariables,
  APITypes.ListDiscountPackagesQuery
>;
export const getMasterSwitch = /* GraphQL */ `query GetMasterSwitch($key: String!) {
  getMasterSwitch(key: $key) {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetMasterSwitchQueryVariables,
  APITypes.GetMasterSwitchQuery
>;
export const listMasterSwitchs = /* GraphQL */ `query ListMasterSwitchs(
  $key: String
  $filter: ModelMasterSwitchFilterInput
  $limit: Int
  $nextToken: String
  $sortDirection: ModelSortDirection
) {
  listMasterSwitchs(
    key: $key
    filter: $filter
    limit: $limit
    nextToken: $nextToken
    sortDirection: $sortDirection
  ) {
    items {
      key
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.ListMasterSwitchsQueryVariables,
  APITypes.ListMasterSwitchsQuery
>;
export const getUserByCognitoId = /* GraphQL */ `query GetUserByCognitoId(
  $cognitoId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByCognitoId(
    cognitoId: $cognitoId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByCognitoIdQueryVariables,
  APITypes.GetUserByCognitoIdQuery
>;
export const getUserByDonorPerfectId = /* GraphQL */ `query GetUserByDonorPerfectId(
  $donorPerfectId: String
  $sortDirection: ModelSortDirection
  $filter: ModelUserFilterInput
  $limit: Int
  $nextToken: String
) {
  getUserByDonorPerfectId(
    donorPerfectId: $donorPerfectId
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetUserByDonorPerfectIdQueryVariables,
  APITypes.GetUserByDonorPerfectIdQuery
>;
export const getGroupByName = /* GraphQL */ `query GetGroupByName(
  $groupName: String
  $sortDirection: ModelSortDirection
  $filter: ModelGroupFilterInput
  $limit: Int
  $nextToken: String
) {
  getGroupByName(
    groupName: $groupName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetGroupByNameQueryVariables,
  APITypes.GetGroupByNameQuery
>;
export const getRoleByName = /* GraphQL */ `query GetRoleByName(
  $roleName: String
  $sortDirection: ModelSortDirection
  $filter: ModelRoleFilterInput
  $limit: Int
  $nextToken: String
) {
  getRoleByName(
    roleName: $roleName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetRoleByNameQueryVariables,
  APITypes.GetRoleByNameQuery
>;
export const getPermissionByName = /* GraphQL */ `query GetPermissionByName(
  $permissionName: String
  $sortDirection: ModelSortDirection
  $filter: ModelAppPermissionFilterInput
  $limit: Int
  $nextToken: String
) {
  getPermissionByName(
    permissionName: $permissionName
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetPermissionByNameQueryVariables,
  APITypes.GetPermissionByNameQuery
>;
export const getDiscountPackageByName = /* GraphQL */ `query GetDiscountPackageByName(
  $name: String
  $sortDirection: ModelSortDirection
  $filter: ModelDiscountPackageFilterInput
  $limit: Int
  $nextToken: String
) {
  getDiscountPackageByName(
    name: $name
    sortDirection: $sortDirection
    filter: $filter
    limit: $limit
    nextToken: $nextToken
  ) {
    items {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<
  APITypes.GetDiscountPackageByNameQueryVariables,
  APITypes.GetDiscountPackageByNameQuery
>;
