/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedSubscription<InputType, OutputType> = string & {
  __generatedSubscriptionInput: InputType;
  __generatedSubscriptionOutput: OutputType;
};

export const onCreateUser = /* GraphQL */ `subscription OnCreateUser {
  onCreateUser {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserSubscriptionVariables,
  APITypes.OnCreateUserSubscription
>;
export const onUpdateUser = /* GraphQL */ `subscription OnUpdateUser {
  onUpdateUser {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserSubscriptionVariables,
  APITypes.OnUpdateUserSubscription
>;
export const onDeleteUser = /* GraphQL */ `subscription OnDeleteUser {
  onDeleteUser {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserSubscriptionVariables,
  APITypes.OnDeleteUserSubscription
>;
export const onCreateUserGroup = /* GraphQL */ `subscription OnCreateUserGroup {
  onCreateUserGroup {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserGroupSubscriptionVariables,
  APITypes.OnCreateUserGroupSubscription
>;
export const onUpdateUserGroup = /* GraphQL */ `subscription OnUpdateUserGroup {
  onUpdateUserGroup {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserGroupSubscriptionVariables,
  APITypes.OnUpdateUserGroupSubscription
>;
export const onDeleteUserGroup = /* GraphQL */ `subscription OnDeleteUserGroup {
  onDeleteUserGroup {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserGroupSubscriptionVariables,
  APITypes.OnDeleteUserGroupSubscription
>;
export const onCreateUserGroupRegistrar = /* GraphQL */ `subscription OnCreateUserGroupRegistrar {
  onCreateUserGroupRegistrar {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserGroupRegistrarSubscriptionVariables,
  APITypes.OnCreateUserGroupRegistrarSubscription
>;
export const onUpdateUserGroupRegistrar = /* GraphQL */ `subscription OnUpdateUserGroupRegistrar {
  onUpdateUserGroupRegistrar {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserGroupRegistrarSubscriptionVariables,
  APITypes.OnUpdateUserGroupRegistrarSubscription
>;
export const onDeleteUserGroupRegistrar = /* GraphQL */ `subscription OnDeleteUserGroupRegistrar {
  onDeleteUserGroupRegistrar {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserGroupRegistrarSubscriptionVariables,
  APITypes.OnDeleteUserGroupRegistrarSubscription
>;
export const onCreateGroup = /* GraphQL */ `subscription OnCreateGroup {
  onCreateGroup {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateGroupSubscriptionVariables,
  APITypes.OnCreateGroupSubscription
>;
export const onUpdateGroup = /* GraphQL */ `subscription OnUpdateGroup {
  onUpdateGroup {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateGroupSubscriptionVariables,
  APITypes.OnUpdateGroupSubscription
>;
export const onDeleteGroup = /* GraphQL */ `subscription OnDeleteGroup {
  onDeleteGroup {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteGroupSubscriptionVariables,
  APITypes.OnDeleteGroupSubscription
>;
export const onCreateRole = /* GraphQL */ `subscription OnCreateRole {
  onCreateRole {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRoleSubscriptionVariables,
  APITypes.OnCreateRoleSubscription
>;
export const onUpdateRole = /* GraphQL */ `subscription OnUpdateRole {
  onUpdateRole {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRoleSubscriptionVariables,
  APITypes.OnUpdateRoleSubscription
>;
export const onDeleteRole = /* GraphQL */ `subscription OnDeleteRole {
  onDeleteRole {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRoleSubscriptionVariables,
  APITypes.OnDeleteRoleSubscription
>;
export const onCreateUserPermission = /* GraphQL */ `subscription OnCreateUserPermission {
  onCreateUserPermission {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateUserPermissionSubscriptionVariables,
  APITypes.OnCreateUserPermissionSubscription
>;
export const onUpdateUserPermission = /* GraphQL */ `subscription OnUpdateUserPermission {
  onUpdateUserPermission {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateUserPermissionSubscriptionVariables,
  APITypes.OnUpdateUserPermissionSubscription
>;
export const onDeleteUserPermission = /* GraphQL */ `subscription OnDeleteUserPermission {
  onDeleteUserPermission {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteUserPermissionSubscriptionVariables,
  APITypes.OnDeleteUserPermissionSubscription
>;
export const onCreateAppPermission = /* GraphQL */ `subscription OnCreateAppPermission {
  onCreateAppPermission {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateAppPermissionSubscriptionVariables,
  APITypes.OnCreateAppPermissionSubscription
>;
export const onUpdateAppPermission = /* GraphQL */ `subscription OnUpdateAppPermission {
  onUpdateAppPermission {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateAppPermissionSubscriptionVariables,
  APITypes.OnUpdateAppPermissionSubscription
>;
export const onDeleteAppPermission = /* GraphQL */ `subscription OnDeleteAppPermission {
  onDeleteAppPermission {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteAppPermissionSubscriptionVariables,
  APITypes.OnDeleteAppPermissionSubscription
>;
export const onCreateBadgeDownload = /* GraphQL */ `subscription OnCreateBadgeDownload {
  onCreateBadgeDownload {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateBadgeDownloadSubscriptionVariables,
  APITypes.OnCreateBadgeDownloadSubscription
>;
export const onUpdateBadgeDownload = /* GraphQL */ `subscription OnUpdateBadgeDownload {
  onUpdateBadgeDownload {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateBadgeDownloadSubscriptionVariables,
  APITypes.OnUpdateBadgeDownloadSubscription
>;
export const onDeleteBadgeDownload = /* GraphQL */ `subscription OnDeleteBadgeDownload {
  onDeleteBadgeDownload {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteBadgeDownloadSubscriptionVariables,
  APITypes.OnDeleteBadgeDownloadSubscription
>;
export const onCreateRegistrationHistory = /* GraphQL */ `subscription OnCreateRegistrationHistory {
  onCreateRegistrationHistory {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateRegistrationHistorySubscriptionVariables,
  APITypes.OnCreateRegistrationHistorySubscription
>;
export const onUpdateRegistrationHistory = /* GraphQL */ `subscription OnUpdateRegistrationHistory {
  onUpdateRegistrationHistory {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateRegistrationHistorySubscriptionVariables,
  APITypes.OnUpdateRegistrationHistorySubscription
>;
export const onDeleteRegistrationHistory = /* GraphQL */ `subscription OnDeleteRegistrationHistory {
  onDeleteRegistrationHistory {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteRegistrationHistorySubscriptionVariables,
  APITypes.OnDeleteRegistrationHistorySubscription
>;
export const onCreateErrorHistory = /* GraphQL */ `subscription OnCreateErrorHistory {
  onCreateErrorHistory {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateErrorHistorySubscriptionVariables,
  APITypes.OnCreateErrorHistorySubscription
>;
export const onUpdateErrorHistory = /* GraphQL */ `subscription OnUpdateErrorHistory {
  onUpdateErrorHistory {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateErrorHistorySubscriptionVariables,
  APITypes.OnUpdateErrorHistorySubscription
>;
export const onDeleteErrorHistory = /* GraphQL */ `subscription OnDeleteErrorHistory {
  onDeleteErrorHistory {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteErrorHistorySubscriptionVariables,
  APITypes.OnDeleteErrorHistorySubscription
>;
export const onCreateDiscount = /* GraphQL */ `subscription OnCreateDiscount {
  onCreateDiscount {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiscountSubscriptionVariables,
  APITypes.OnCreateDiscountSubscription
>;
export const onUpdateDiscount = /* GraphQL */ `subscription OnUpdateDiscount {
  onUpdateDiscount {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiscountSubscriptionVariables,
  APITypes.OnUpdateDiscountSubscription
>;
export const onDeleteDiscount = /* GraphQL */ `subscription OnDeleteDiscount {
  onDeleteDiscount {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiscountSubscriptionVariables,
  APITypes.OnDeleteDiscountSubscription
>;
export const onCreateDiscountPackage = /* GraphQL */ `subscription OnCreateDiscountPackage {
  onCreateDiscountPackage {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateDiscountPackageSubscriptionVariables,
  APITypes.OnCreateDiscountPackageSubscription
>;
export const onUpdateDiscountPackage = /* GraphQL */ `subscription OnUpdateDiscountPackage {
  onUpdateDiscountPackage {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateDiscountPackageSubscriptionVariables,
  APITypes.OnUpdateDiscountPackageSubscription
>;
export const onDeleteDiscountPackage = /* GraphQL */ `subscription OnDeleteDiscountPackage {
  onDeleteDiscountPackage {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteDiscountPackageSubscriptionVariables,
  APITypes.OnDeleteDiscountPackageSubscription
>;
export const onCreateMasterSwitch = /* GraphQL */ `subscription OnCreateMasterSwitch {
  onCreateMasterSwitch {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnCreateMasterSwitchSubscriptionVariables,
  APITypes.OnCreateMasterSwitchSubscription
>;
export const onUpdateMasterSwitch = /* GraphQL */ `subscription OnUpdateMasterSwitch {
  onUpdateMasterSwitch {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnUpdateMasterSwitchSubscriptionVariables,
  APITypes.OnUpdateMasterSwitchSubscription
>;
export const onDeleteMasterSwitch = /* GraphQL */ `subscription OnDeleteMasterSwitch {
  onDeleteMasterSwitch {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedSubscription<
  APITypes.OnDeleteMasterSwitchSubscriptionVariables,
  APITypes.OnDeleteMasterSwitchSubscription
>;
