import { Box, IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, useEffect, useState } from 'react';
import { SearchService, User } from '../core';
import { withModal } from './withModal';

interface Props {
  registrations: User[];
}

interface Data {
  name: string;
  score: string;
  match: string;
}

export const Duplicates: FC<Props> = withModal(({ registrations, closeModal }) => {
  const [data, setData] = useState<Data[] | null>(null);

  useEffect(() => {
    const duplicates = registrations
      .map((user) => `${user.firstName} ${user.lastName}`)
      .filter((name) => name.search(/guest/gi) < 0)
      .map((name, idx, arr) => {
        return SearchService.fuzzyFind(name, arr.slice(idx + 1), {
          options: { includeScore: true, threshold: 0.25 },
          limit: 1,
        }).map((result) => ({ name, score: result.score, match: result.item }));
      })
      .filter((x) => x.length > 0)
      .flat(1)
      .sort((a, b) => a.score! - b.score!)
      .map((x) => ({ ...x, score: `${(100 * (1 - x.score!)).toFixed(0)}%` }));
    setData(duplicates);
  }, [registrations]);

  const columns: Column<Data>[] = [
    {
      title: 'A',
      field: 'name',
    },
    {
      title: 'B',
      field: 'match',
    },
    {
      title: 'Score',
      field: 'score',
    },
  ];

  return (
    <>
      <MaterialTable
        title={
          <>
            <Box display="flex" alignItems="center">
              <Typography variant="h6" color="primary">
                Potential Duplicates
              </Typography>
              <Typography variant="caption" color="secondary" style={{ marginLeft: '12px' }}>
                * Guests ignored
              </Typography>
            </Box>
            <IconButton
              aria-label="close"
              onClick={() => closeModal()}
              style={{ position: 'absolute', top: 8, right: 16 }}
            >
              <CloseRounded />
            </IconButton>
          </>
        }
        columns={columns.map((c) => ({ disableClick: true, ...c }))}
        data={data || []}
        onRowClick={() => {}}
        options={{
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10],
          padding: 'dense',
          search: false,
        }}
      />
    </>
  );
});
