import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { FC } from 'react';
import { ConferenceDay, ConferenceElement, ConferenceItem, EMOJI2 } from '../core';

interface Props {
  day: ConferenceDay;
  item: ConferenceItem;
  element: ConferenceElement;
  checked: boolean | undefined;
  onChange: (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => void;
  disabled?: boolean;
  register: () => any;
  isSoldOut?: boolean;
}

export const RegistrationSelect: FC<Props> = ({
  day,
  item,
  element,
  checked = false,
  onChange,
  disabled = false,
  register,
  isSoldOut = false,
}) => {
  return (
    <FormControlLabel
      id={`${day}${item}`}
      name={`${day}${item}`}
      key={`${day}${item}`}
      label={
        <Box display="flex" alignItems="center">
          <Typography>{`${element} ${EMOJI2[element]}`}</Typography>
          {isSoldOut && (
            <Typography variant="caption" color="secondary">
              &nbsp;Sold out!
            </Typography>
          )}
        </Box>
      }
      labelPlacement="end"
      control={<Switch color="primary" size="small" onChange={onChange} checked={checked} inputRef={register()} />}
      disabled={disabled}
    />
  );
};
