import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { ApiService, Group } from '../core';
import { ModalConfirm } from './ModalConfirm';
import { withModal } from './withModal';

interface Props {
  data: Group;
}
export const GroupDelete: FC<Props> = withModal(({ data, closeModal }) => {
  const { withLoading, updateGroups, updateRegistrations } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const deleteGroup = withLoading(async () => {
    try {
      await Promise.all(
        data.users?.items
          ?.map((item) => item?.userId || '')
          .map((userId) => ApiService.deleteUserGroup({ input: { userId, groupId: data.id } }).catch(() => {})) || []
      );
      await ApiService.deleteGroup({ input: { id: data.id, expectedVersion: data.version } });
      updateGroups();
      updateRegistrations();
      closeModal();
      enqueueSnackbar(`Successfully deleted group ${data.groupName}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to delete group ${data.groupName}`, { variant: 'error' });
    }
  });

  return (
    <ModalConfirm
      title="Delete group"
      word="Delete"
      onConfirm={deleteGroup}
      message={
        <>
          Are you sure you want to delete <b>{data.groupName}</b>?
        </>
      }
    ></ModalConfirm>
  );
});
