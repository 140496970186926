import { Box, Button, IconButton, Typography } from '@material-ui/core';
import { AddRounded, Delete } from '@material-ui/icons';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StateContext } from '../context';
import {
  ApiService,
  CreateDiscountInput,
  Discount,
  DiscountItem,
  DiscountPackage,
  DiscountType,
  getRandomInt,
  ID,
} from '../core';
import { Form } from './Form';
import { InputSelect } from './InputSelect';
import { InputSelectMultiple } from './InputSelectMultiple';
import { InputText } from './InputText';
import { withModal } from './withModal';

const OPTIONS = {
  priority: [...Array(10)].map((_, idx) => idx + 1).map((x) => x.toString()), // 1-10
  item: [DiscountItem.Speakers, DiscountItem.Breakfast, DiscountItem.Lunch, DiscountItem.Dinner, DiscountItem.ALL],
  count: [999].concat([...Array(25)].map((_, idx) => idx + 1)).map((x) => x.toString()), // 999, 1-25
  rate: [100]
    .concat([...Array(99)].map((_, idx) => idx + 1).filter((x) => !!x && x % 5 === 0))
    .map((x) => x.toString()), // 100, 1-99 (increments of 5)
};

export interface DataDiscountForm {
  Name: string;
  Groups: string[];
  [key: string]: any;
}

interface Props {
  existingData?: DiscountPackage;
}

export const DiscountForm: FC<Props> = withModal(({ existingData, closeModal }) => {
  const { withLoading, updateGroups, updateDiscountPackages, groups } = useContext(StateContext);
  const form = useForm<DataDiscountForm>();
  const { enqueueSnackbar } = useSnackbar();
  const [lineItems, setLineItems] = useState<(Discount & { key: string })[]>(
    existingData?.discounts?.items
      .map((item) => ({ ...item, key: getRandomInt(5) }))
      .sort((a, b) => a.priority - b.priority) || []
  );

  const onSubmit = form.handleSubmit(
    withLoading<[DataDiscountForm]>(async (data) => {
      try {
        if (existingData) {
          // update discount package object
          const discountPackage = await ApiService.updateDiscountPackage({
            input: {
              id: existingData.id,
              expectedVersion: existingData.version,
              name: data.Name,
            },
          });
          // delete existing discount objects
          await Promise.all([
            ...(existingData.discounts?.items.map((item) =>
              ApiService.deleteDiscount({ input: { id: item.id, expectedVersion: item.version } })
            ) || []),
          ]);
          // create discount objects, link to discount package
          await Promise.all([
            ...lineItems.map((lineItem) => {
              const { key } = lineItem;
              let input: CreateDiscountInput = {
                type: lineItem.type,
                priority: data[`Priority${key}`],
                packageId: discountPackage!.id,
              };
              if (lineItem.type === DiscountType.DollarCredit) {
                input = { ...input, creditAmount: parseInt((data[`Credit${key}`] as string).replace(/[^0-9]/g, '')) };
              }
              if (lineItem.type === DiscountType.PercentDiscount)
                input = {
                  ...input,
                  discountItem: data[`Item${key}`],
                  discountRate: parseInt(data[`Rate${key}`]),
                  discountCount: parseInt(data[`Count${key}`]),
                };
              return ApiService.createDiscount({ input });
            }),
          ]);
          // link groups to discount package
          const existingGroups = existingData.groups?.items || [];
          const newGroups = groups?.filter((g) => data.Groups.includes(g.groupName)) || [];
          const linkGroups = newGroups.filter((newGroup) => !existingGroups.map((x) => x.id).includes(newGroup.id));
          const unlinkGroups = existingGroups.filter(
            (existingGroup) => !newGroups.map((y) => y.id).includes(existingGroup.id)
          );
          await Promise.all([
            ...linkGroups.map((group) =>
              ApiService.updateGroup({
                input: { id: group.id, expectedVersion: group.version, discountPackageId: discountPackage?.id },
              })
            ),
            ...unlinkGroups.map((group) =>
              ApiService.updateGroup({
                input: { id: group.id, expectedVersion: group.version, discountPackageId: null },
              })
            ),
          ]);
        } else {
          // create discount package object
          const discountPackage = await ApiService.createDiscountPackage({ input: { name: data.Name } });
          // create discount objects, link to discount package
          await Promise.all([
            ...lineItems.map((lineItem) => {
              const { key } = lineItem;
              let input: CreateDiscountInput = {
                type: lineItem.type,
                priority: data[`Priority${key}`],
                packageId: discountPackage!.id,
              };
              if (lineItem.type === DiscountType.DollarCredit) {
                input = { ...input, creditAmount: parseInt((data[`Credit${key}`] as string).replace(/[^0-9]/g, '')) };
              }
              if (lineItem.type === DiscountType.PercentDiscount)
                input = {
                  ...input,
                  discountItem: data[`Item${key}`],
                  discountRate: parseInt(data[`Rate${key}`]),
                  discountCount: parseInt(data[`Count${key}`]),
                };
              return ApiService.createDiscount({ input });
            }),
          ]);
          // link groups to discount package
          await Promise.all([
            ...groups!
              .filter((g) => data.Groups.includes(g.groupName))
              .map((group) =>
                ApiService.updateGroup({
                  input: { id: group.id, expectedVersion: group.version, discountPackageId: discountPackage?.id },
                })
              ),
          ]);
        }
        updateGroups();
        updateDiscountPackages();
        closeModal();
        enqueueSnackbar(`Successfully ${existingData ? 'updated' : 'created'} ${data.Name} discount package`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(
          `Failed to ${existingData ? 'update' : 'create'} ${existingData ? `${data.Name} ` : ''}discount package`,
          { variant: 'error' }
        );
      }
    })
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          {existingData ? 'Edit discount package' : 'Add new discount package'}
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          <Box display="flex" margin="2px">
            <InputText
              id={ID.Form_Discount_Name}
              name="Name"
              defaultValue={existingData?.name}
              rules={{ required: true }}
              size="small"
            />
          </Box>
          <Box display="flex" margin="2px">
            <InputSelectMultiple
              name="Groups"
              defaultValue={existingData?.groups?.items?.map((item) => item?.groupName || '')}
              options={(groups || [])
                .filter((g) => !g.discountPackageId || (existingData && g.discountPackageId === existingData.id))
                .map((group) => group.groupName)}
              filterSelectedOptions
              size="small"
            />
          </Box>
          {lineItems.length > 0 &&
            lineItems.map((lineItem) => (
              <Box key={lineItem.key} display="flex">
                <Box display="flex" flexGrow={0} margin="2px">
                  <InputSelect
                    name={`Priority${lineItem.key}`}
                    defaultValue={lineItem.priority && `${lineItem.priority}`}
                    options={OPTIONS.priority}
                    shouldSortOptions={false}
                    rules={{ required: true }}
                    size="small"
                  />
                </Box>
                {lineItem.type === DiscountType.PercentDiscount && (
                  <Box display="flex" flexGrow={1} margin="2px">
                    <InputSelect
                      name={`Item${lineItem.key}`}
                      defaultValue={lineItem.discountItem && `${lineItem.discountItem}`}
                      options={OPTIONS.item}
                      shouldSortOptions={false}
                      rules={{ required: true }}
                      size="small"
                    />
                  </Box>
                )}
                {lineItem.type === DiscountType.PercentDiscount && (
                  <Box display="flex" flexGrow={0} margin="2px">
                    <InputSelect
                      name={`Count${lineItem.key}`}
                      defaultValue={lineItem.discountCount && `${lineItem.discountCount}`}
                      options={OPTIONS.count}
                      shouldSortOptions={false}
                      rules={{ required: true }}
                      size="small"
                    />
                  </Box>
                )}
                {lineItem.type === DiscountType.PercentDiscount && (
                  <Box display="flex" flexGrow={0} margin="2px">
                    <InputSelect
                      name={`Rate${lineItem.key}`}
                      defaultValue={lineItem.discountRate && `${lineItem.discountRate}`}
                      options={OPTIONS.rate}
                      shouldSortOptions={false}
                      rules={{ required: true }}
                      size="small"
                    />
                  </Box>
                )}
                {lineItem.type === DiscountType.DollarCredit && (
                  <Box display="flex" flexGrow={0} margin="2px">
                    <InputText
                      name={`Credit${lineItem.key}`}
                      defaultValue={lineItem.creditAmount && `${lineItem.creditAmount}`}
                      currency={true}
                      rules={{ required: true }}
                      size="small"
                    />
                  </Box>
                )}
                <Box display="flex" flexGrow={1} margin="2px">
                  <IconButton
                    color="secondary"
                    size="small"
                    onClick={() => setLineItems((lineItems) => lineItems.filter((x) => x.key !== lineItem.key))}
                    style={{ margin: '0.25rem' }}
                  >
                    <Delete />
                  </IconButton>
                </Box>
              </Box>
            ))}
          <Box display="flex" margin="2px">
            <Button
              color="primary"
              size="small"
              startIcon={<AddRounded />}
              onClick={() =>
                setLineItems((lineItems) => [
                  ...lineItems,
                  { type: DiscountType.PercentDiscount, key: getRandomInt(5) } as any,
                ])
              }
              style={{ margin: '0.25rem' }}
            >
              Discount (%)
            </Button>
            <Button
              color="primary"
              size="small"
              startIcon={<AddRounded />}
              onClick={() =>
                setLineItems((lineItems) => [
                  ...lineItems,
                  { type: DiscountType.DollarCredit, key: getRandomInt(5) } as any,
                ])
              }
              style={{ margin: '0.25rem' }}
            >
              Credit ($)
            </Button>
          </Box>
        </Box>
        <Box height="8px" />
        <Box display="flex" justifyContent="center">
          <Button
            id={ID.Form_Discount_Submit}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem' }}
          >
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
