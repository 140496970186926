import { Box, Button } from '@material-ui/core';
import { AddRounded, BarChartRounded, FileCopy } from '@material-ui/icons';
import React, { FC, memo, useContext } from 'react';
import { StateContext } from '../context';
import { ID, useWindowSize } from '../core';
import { RegistrationsTable } from './RegistrationsTable';
import { RegistrationForm } from './RegistrationForm';
import { Statistics } from './Statistics';
import { withModal } from './withModal';
import { Duplicates } from './Duplicates';

export const Registrations: FC = withModal(
  memo(({ openModal }) => {
    const { registrations } = useContext(StateContext);
    const { width } = useWindowSize();

    return (
      <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
        <Box width="100%">
          <Box display="flex" flexWrap="wrap" style={{ margin: '0.5rem 0.25rem' }}>
            <Button
              id={ID.Page_Registrations_Add}
              color="primary"
              variant="outlined"
              startIcon={<AddRounded />}
              onClick={() => openModal(<RegistrationForm />, 550)}
              style={{ margin: '0.25rem' }}
            >
              {width! > 650 ? 'Add New Registration' : 'New'}
            </Button>
            <Button
              color="default"
              variant="outlined"
              startIcon={<BarChartRounded />}
              onClick={() => openModal(<Statistics registrations={registrations || []} />, 500)}
              style={{ margin: '0.25rem' }}
            >
              {width! > 650 ? 'View Statistics' : 'Stats'}
            </Button>
            <Button
              color="default"
              variant="outlined"
              startIcon={<FileCopy />}
              onClick={() => openModal(<Duplicates registrations={registrations || []} />, 700)}
              style={{ margin: '0.25rem' }}
            >
              {width! > 650 ? 'Find Duplicates' : 'Dedup'}
            </Button>
          </Box>
          <RegistrationsTable />
        </Box>
      </Box>
    );
  })
);
