import { Box, Button, Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { StateContext } from '../context';
import { AccessRole, ApiService, AuthService, PermissionName, PERMISSION_NAME, User } from '../core';
import { Form } from './Form';
import { withModal } from './withModal';

interface DataPermissionsEdit {
  [PermissionName.EditPrivateReceptionsAndWorkshop]: boolean;
  [PermissionName.ManageGroupLeaders]: boolean;
  [PermissionName.ViewActivityLog]: boolean;
}

interface Props {
  user: User;
}

export const PermissionsEdit: FC<Props> = withModal(({ user, closeModal }) => {
  const { withLoading, permissions, updateRegistrations } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();
  const form = useForm<DataPermissionsEdit>();

  const existingPermissions = useMemo(
    (): Partial<DataPermissionsEdit> =>
      (
        user.permissions?.items
          ?.map((item) => item?.permission)
          .concat(permissions?.filter((permission) => permission.accessEnabled?.includes(user.accessRole!)) || [])
          .map((permission) => permission?.permissionName as PermissionName) || []
      ).reduce((p, c) => ({ ...p, [c]: true }), {}) || {},
    [user, permissions]
  );
  const [state, setState] = useState<Partial<DataPermissionsEdit>>(existingPermissions);

  const onSubmit = form.handleSubmit(
    withLoading<[DataPermissionsEdit]>(async (data) => {
      try {
        const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
        const previousPermissions = Object.keys(existingPermissions) as PermissionName[];
        const currentPermissions = (Object.entries(data) as [PermissionName, boolean][])
          .filter(([_, value]) => value)
          .map(([key, _]) => key);
        const createUserPermissions = currentPermissions.filter((c) => !previousPermissions.includes(c));
        const deleteUserPermissions = previousPermissions.filter((p) => !currentPermissions.includes(p));
        await Promise.all(
          createUserPermissions
            .map(async (permission) => {
              try {
                await ApiService.createUserPermission({
                  input: {
                    userId: user!.id!,
                    permissionId: permissions!.find((g) => g.permissionName === permission)!.id,
                  },
                });
                if (permission === PermissionName.ManageGroupLeaders)
                  await AuthService.addUserToGroup(user.email || '', AccessRole.Admin);
              } catch (error) {
                snackbar.push({
                  message: `Failed to add ${user?.firstName} to permission ${permission}.`,
                  variant: 'error',
                });
              }
            })
            .concat(
              deleteUserPermissions.map(async (permission) => {
                try {
                  await ApiService.deleteUserPermission({
                    input: {
                      userId: user!.id!,
                      permissionId: permissions!.find((g) => g.permissionName === permission)!.id,
                    },
                  });
                  if (permission === PermissionName.ManageGroupLeaders)
                    await AuthService.removeUserFromGroup(user.email || '', AccessRole.Admin);
                } catch (error) {
                  snackbar.push({
                    message: `Failed to remove ${user?.firstName} from permission ${permission}.`,
                    variant: 'error',
                  });
                }
              })
            )
        );
        updateRegistrations();
        closeModal();
        snackbar.unshift({
          message: `Successfully modified permissions for ${user?.firstName} ${user?.lastName}`,
          variant: 'success',
        });
        snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
      } catch (error) {
        enqueueSnackbar(`Failed to modify permissions for ${user.firstName} ${user.lastName}`, { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          Modify user permissions
        </Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexDirection="column">
          {permissions?.map(({ permissionName, accessEligible }) => (
            <FormControlLabel
              name={permissionName}
              key={permissionName}
              label={PERMISSION_NAME[permissionName as PermissionName]}
              labelPlacement="end"
              control={
                <Checkbox
                  color="primary"
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setState({ ...state, [event.target.name]: event.target.checked });
                  }}
                  checked={state[permissionName as PermissionName] || false}
                  inputRef={form.register()}
                  disabled={!accessEligible?.includes(user.accessRole!)}
                />
              }
            />
          ))}
        </Box>
        <Box display="flex" marginTop="20px" justifyContent="center">
          <Button type="submit" color="primary" variant="contained" style={{ margin: '0.5rem' }}>
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
