import { PRICING } from './constants';
import { DiscountItem } from './enums';
import { Discount, Scalars } from './graphql.types';
import { CustomUser } from './interfaces';

interface ItemCounts {
  speakers: number;
  breakfast: number;
  lunch: number;
  dinner: number;
}

interface SingleItemized {
  userId: Scalars['ID'];
  fridaySpeakers: number;
  fridayBreakfast: number;
  fridayLunch: number;
  fridayDinner: number;
  saturdaySpeakers: number;
  saturdayBreakfast: number;
  saturdayLunch: number;
  saturdayDinner: number;
}

type GroupItemized = SingleItemized[];

type ItemKey = keyof Omit<SingleItemized, 'userId'>;

const ALL_ITEM_KEYS: ItemKey[] = [
  'fridaySpeakers',
  'fridayBreakfast',
  'fridayLunch',
  'fridayDinner',
  'saturdaySpeakers',
  'saturdayBreakfast',
  'saturdayLunch',
  'saturdayDinner',
];

export interface Totals {
  retail: number;
  discount: number;
  actual: number;
  groupItemized: GroupItemized;
}

export abstract class DiscountService {
  static calculateTotals = (registrations: CustomUser[], discounts: Discount[]): Totals => {
    const itemCounts = DiscountService.getItemCounts(registrations);
    const retail = DiscountService.calculateTotalRetail(itemCounts);
    const groupItemized = DiscountService.calculateGroupAmountsDue(registrations, discounts);
    const actual = DiscountService.getGroupAmountDue(groupItemized);
    return { retail, actual, discount: retail - actual, groupItemized };
  };

  static getItemCounts = (registrations: CustomUser[]): ItemCounts => {
    return registrations.reduce(
      (p, c) => {
        return {
          speakers: p.speakers + (c.fridaySpeakers ? 1 : 0) + (c.saturdaySpeakers ? 1 : 0),
          breakfast: p.breakfast + (c.fridayBreakfast ? 1 : 0) + (c.saturdayBreakfast ? 1 : 0),
          lunch: p.lunch + (c.fridayLunch ? 1 : 0) + (c.saturdayLunch ? 1 : 0),
          dinner: p.dinner + (c.fridayDinner ? 1 : 0) + (c.saturdayDinner ? 1 : 0),
        };
      },
      {
        speakers: 0,
        breakfast: 0,
        lunch: 0,
        dinner: 0,
      }
    );
  };

  static calculateTotalRetail = (itemCounts: ItemCounts): number => {
    const { speakers: s, breakfast: b, lunch: l, dinner: d } = itemCounts;
    const { Speakers: ps, Breakfast: pb, Lunch: pl, Dinner: pd } = PRICING;
    return s * ps + b * pb + l * pl + d * pd;
  };

  static calculateGroupAmountsDue = (registrations: CustomUser[], discounts: Discount[]): GroupItemized => {
    let groupItemized = registrations.map((r) => ({
      userId: r.id,
      fridaySpeakers: 100 * PRICING.Speakers * (r.fridaySpeakers ? 1 : 0),
      fridayBreakfast: 100 * PRICING.Breakfast * (r.fridayBreakfast ? 1 : 0),
      fridayLunch: 100 * PRICING.Lunch * (r.fridayLunch ? 1 : 0),
      fridayDinner: 100 * PRICING.Dinner * (r.fridayDinner ? 1 : 0),
      saturdaySpeakers: 100 * PRICING.Speakers * (r.saturdaySpeakers ? 1 : 0),
      saturdayBreakfast: 100 * PRICING.Breakfast * (r.saturdayBreakfast ? 1 : 0),
      saturdayLunch: 100 * PRICING.Lunch * (r.saturdayLunch ? 1 : 0),
      saturdayDinner: 100 * PRICING.Dinner * (r.saturdayDinner ? 1 : 0),
    })) as GroupItemized;

    discounts
      .sort((a, b) => a.priority - b.priority)
      .forEach((discount) => {
        if (discount.creditAmount) {
          DiscountService.applyGroupCredit(groupItemized, discount.creditAmount * 100);
        } else {
          const { discountItem: item, discountRate: rate, discountCount: count } = discount;
          DiscountService.applyGroupDiscountRate(groupItemized, item! as DiscountItem, rate!, count!);
        }
      });

    groupItemized.forEach((singleItemized) => ALL_ITEM_KEYS.forEach((itemKey) => (singleItemized[itemKey] /= 100)));

    return groupItemized;
  };

  static applyGroupCredit = (groupItemized: GroupItemized, credit: number): number => {
    let creditApplied = 0;

    if (credit === 0) return creditApplied;

    const countHasAmountDue = groupItemized.filter(DiscountService.hasAmountDue).length;
    if (countHasAmountDue === 0) return creditApplied;

    const maxCreditPer = Math.floor(credit / countHasAmountDue);
    let remainder = credit % countHasAmountDue;

    groupItemized.forEach((singleItemized) => {
      if (DiscountService.hasAmountDue(singleItemized)) {
        let creditToApply = maxCreditPer;
        if (remainder > 0) {
          creditToApply += 1;
          remainder -= 1;
        }
        const thisCreditApplied = DiscountService.applyIndividualCredit(singleItemized, creditToApply);
        creditApplied += thisCreditApplied;
      }
    });

    return creditApplied + DiscountService.applyGroupCredit(groupItemized, credit - creditApplied);
  };

  static applyIndividualCredit = (singleItemized: SingleItemized, credit: number): number => {
    let creditApplied = 0;

    if (credit === 0) return creditApplied;

    const countHasAmountDue = ALL_ITEM_KEYS.reduce((p, c) => p + (singleItemized[c] > 0 ? 1 : 0), 0);
    if (countHasAmountDue === 0) return creditApplied;

    const maxCreditPer = Math.floor(credit / countHasAmountDue);
    let remainder = credit % countHasAmountDue;

    ALL_ITEM_KEYS.forEach((key) => {
      if (singleItemized[key] > 0) {
        let creditToApply = maxCreditPer;
        if (remainder > 0) {
          creditToApply += 1;
          remainder -= 1;
        }
        creditToApply = Math.min(singleItemized[key], creditToApply);
        singleItemized[key] -= creditToApply;
        creditApplied += creditToApply;
      }
    });

    return creditApplied + DiscountService.applyIndividualCredit(singleItemized, credit - creditApplied);
  };

  static applyGroupDiscountRate = (
    groupItemized: GroupItemized,
    item: DiscountItem,
    rate: number,
    count: number
  ): void => {
    const apply = (singleItemized: SingleItemized, key: ItemKey) =>
      (singleItemized[key] = rate === 100 ? 0 : Math.floor((singleItemized[key] * (100 - rate)) / 100));

    let keys: ItemKey[];

    groupItemized.forEach((singleItemized) => {
      switch (item) {
        case DiscountItem.ALL:
          keys = ALL_ITEM_KEYS;
          break;
        case DiscountItem.Speakers:
          keys = ['fridaySpeakers', 'saturdaySpeakers'];
          break;
        case DiscountItem.Breakfast:
          keys = ['fridayBreakfast', 'saturdayBreakfast'];
          break;
        case DiscountItem.Lunch:
          keys = ['fridayLunch', 'saturdayLunch'];
          break;
        case DiscountItem.Dinner:
          keys = ['fridayDinner', 'saturdayDinner'];
          break;
        default:
          keys = [];
          break;
      }
      keys.forEach((itemKey) => {
        if (singleItemized[itemKey] > 0 && count > 0) {
          apply(singleItemized, itemKey);
          count--;
        }
      });
    });
  };

  static getGroupAmountDue = (groupItemized: GroupItemized): number => {
    return groupItemized.reduce((p, c) => p + DiscountService.getIndividualAmountDue(c), 0);
  };

  static getIndividualAmountDue = (singleItemized: SingleItemized): number => {
    return ALL_ITEM_KEYS.reduce((p, c) => p + singleItemized[c], 0);
  };

  static hasAmountDue = (singleItemized: SingleItemized): boolean => {
    return DiscountService.getIndividualAmountDue(singleItemized) > 0;
  };

  static getSingleItemized = (groupItemized: GroupItemized, userId: Scalars['ID']): SingleItemized | undefined => {
    return groupItemized.find((singleItemized) => singleItemized.userId === userId);
  };
}
