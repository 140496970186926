import { Box, Checkbox, CheckboxProps, IconButton, Tooltip, Typography, withStyles } from '@material-ui/core';
import { green } from '@material-ui/core/colors';
import { AddCircle, Delete, GetAppRounded, RemoveCircle } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { ApiService, BadgeDownload, ConferenceDay, NameBadgeService, UserSnapshot } from '../core';
import { DownloadDelete } from './DownloadDelete';
import { withModal } from './withModal';

const GreenCheckbox = withStyles({
  root: {
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props: CheckboxProps) => <Checkbox color="default" {...props} />);

interface Props {}

export const DownloadHistoryTable: FC<Props> = withModal(({ openModal }) => {
  const { downloads, updateDownloads, withLoading } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const columns: Column<BadgeDownload>[] = [
    { title: 'Day', field: 'day' },
    {
      title: 'Type',
      field: 'type',
      render: (data) => {
        const update: UserSnapshot[] = JSON.parse(data.usersUpdate);
        const remove: UserSnapshot[] = JSON.parse(data.usersRemove);
        return (
          <Box display="flex" alignItems="center">
            <Typography style={{ padding: '5px' }}>{data.type}</Typography>
            {remove.length > 0 && (
              <Tooltip
                title={[<div key={'remove-label'}>REMOVE:</div>].concat(
                  remove.map((user, idx) => (
                    <div key={idx}>
                      {idx + 1}. {user.name}
                    </div>
                  ))
                )}
                placement="top"
                arrow
              >
                <IconButton size="small" aria-label="users removed">
                  <RemoveCircle />
                </IconButton>
              </Tooltip>
            )}
            {update.length > 0 && (
              <Tooltip
                title={[<div key={'add-label'}>ADD or UPDATE:</div>].concat(
                  update.map((user, idx) => (
                    <div key={idx}>
                      {idx + 1}. {user.name}
                    </div>
                  ))
                )}
                placement="top"
                arrow
              >
                <IconButton size="small" aria-label="users added">
                  <AddCircle />
                </IconButton>
              </Tooltip>
            )}
          </Box>
        );
      },
    },
    { title: 'Date', field: 'createdAt', type: 'datetime', defaultSort: 'desc' },
    {
      title: 'Printed?',
      field: 'printed',
      type: 'boolean',
      editable: 'always',
      render: (data) => (
        <GreenCheckbox
          checked={data.printed}
          onClick={() => {
            withLoading(() =>
              ApiService.updateBadgeDownload({ input: { id: data.id, printed: !data.printed } })
                .then(() => updateDownloads())
                .catch(() => {
                  enqueueSnackbar(
                    `😔 Something went wrong while updating the print status of the ${data.day} download on ${new Date(
                      data.createdAt
                    ).toLocaleString()}.`,
                    {
                      variant: 'error',
                    }
                  );
                })
            )();
          }}
        />
      ),
    },
  ];

  return (
    <MaterialTable
      title="Download History"
      columns={columns.map((column) => ({ editable: 'never', disableClick: true, ...column }))}
      data={downloads || []}
      options={{ paging: false, padding: 'dense' }}
      onRowClick={() => {}}
      actions={[
        {
          icon: GetAppRounded,
          iconProps: { color: 'primary' },
          tooltip: 'download',
          onClick: (_event, rowData) => {
            const { day, usersUpdate, usersRemove } = rowData as BadgeDownload;
            const users = JSON.parse(usersUpdate) as UserSnapshot[];
            const remove = JSON.parse(usersRemove) as UserSnapshot[];
            NameBadgeService.redownload(day as ConferenceDay, users, remove);
          },
        },
        {
          icon: Delete,
          iconProps: { color: 'secondary' },
          tooltip: 'delete',
          onClick: (_event, rowData) => openModal(<DownloadDelete data={rowData as BadgeDownload} />),
        },
      ]}
      components={{
        Action: (props) => {
          const action = props.action.action ? props.action.action(props.data) : props.action;
          const { iconProps = action.tooltip === 'Save' ? { style: { color: 'green' } } : {} } = action;
          return (
            <Tooltip title={action.tooltip} arrow>
              <span>
                <IconButton onClick={(event) => action.onClick(event, props.data)} disabled={action.disabled}>
                  <action.icon {...iconProps} />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      }}
    />
  );
});
