import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ApiService, RegistrationHistory, REGISTRATION_OBJECT_KEYS, useMaxTableBodyHeight } from '../core';
import { GroupChip } from './GroupChip';
import { RegistrationDayEmojis } from './RegistrationDayEmojis';

interface Props {}

export const ActivityHistory: FC<Props> = () => {
  const [registrationHistorys, setRegistrationHistorys] = useState<RegistrationHistory[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    ApiService.listRegistrationHistorys()
      .then((x) => setRegistrationHistorys(x))
      .then(
        () => setLoading(false),
        () => setLoading(false)
      );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: Column<RegistrationHistory>[] = [
    { title: 'Registrar', field: 'user' },
    { title: 'Action', field: 'event' },
    { title: 'Date', field: 'createdAt', type: 'datetime', defaultSort: 'desc' },
    { title: 'ID', field: 'registrationId' },
    { title: 'First Name', field: 'firstName' },
    { title: 'Last Name', field: 'lastName' },
    {
      title: 'Groups',
      field: 'groups',
      render: (user) => user?.groups?.map((groupName, idx) => <GroupChip key={idx} groupName={groupName} />),
    },
    { title: 'Role', field: 'role' },
    ...REGISTRATION_OBJECT_KEYS.map((grouping) => ({
      title: grouping,
      render: (user: any) => <RegistrationDayEmojis grouping={grouping} user={user} />,
    })),
    { title: 'Notes', field: 'notes' },
  ];

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%">
        <MaterialTable
          title="Registration Activity"
          columns={columns.map((c) => ({ disableClick: true, ...c }))}
          data={registrationHistorys || []}
          options={{
            toolbarButtonAlignment: 'left',
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 100],
            minBodyHeight: useMaxTableBodyHeight(),
            maxBodyHeight: useMaxTableBodyHeight(),
            padding: 'dense',
          }}
          onRowClick={() => {}}
          isLoading={loading}
          actions={[
            {
              icon: Refresh,
              iconProps: { color: 'primary' },
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => {
                fetchData();
              },
            },
          ]}
          components={{
            Action: (props) => {
              const action = props.action.action ? props.action.action(props.data) : props.action;
              return (
                <Tooltip title={action.tooltip} arrow>
                  <span>
                    <IconButton
                      color={action.iconProps.color}
                      onClick={(event) => action.onClick(event, props.data)}
                      disabled={action.disabled}
                    >
                      <action.icon />
                    </IconButton>
                  </span>
                </Tooltip>
              );
            },
          }}
        />
      </Box>
    </Box>
  );
};
