import { Box, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import React, { FC, Fragment } from 'react';
import { ConferenceItem, EMOJI2, REGISTRATION_ARRAY, User } from '../core';
import { withModal } from './withModal';

interface Props {
  registrations: User[];
}
export interface Registration {
  thursdayReception: number;
  fridayReception: number;
  fridayWorkshop: number;
  fridaySpeakers: number;
  fridayBreakfast: number;
  fridayLunch: number;
  fridayDinner: number;
  saturdaySpeakers: number;
  saturdayBreakfast: number;
  saturdayLunch: number;
  saturdayDinner: number;
  uniqueAttendees: number;
  bothSpeakers: number;
  bothBreakfast: number;
  bothLunch: number;
  bothDinner: number;
}

export const Statistics: FC<Props> = withModal(({ registrations, closeModal }) => {
  const data = registrations.reduce<Registration>(
    (p, c) => ({
      thursdayReception: p.thursdayReception + +!!c.thursdayReception,
      fridayReception: p.fridayReception + +!!c.fridayReception,
      fridayWorkshop: p.fridayWorkshop + +!!c.fridayWorkshop,
      fridaySpeakers: p.fridaySpeakers + +!!c.fridaySpeakers,
      fridayBreakfast: p.fridayBreakfast + +!!c.fridayBreakfast,
      fridayLunch: p.fridayLunch + +!!c.fridayLunch,
      fridayDinner: p.fridayDinner + +!!c.fridayDinner,
      saturdaySpeakers: p.saturdaySpeakers + +!!c.saturdaySpeakers,
      saturdayBreakfast: p.saturdayBreakfast + +!!c.saturdayBreakfast,
      saturdayLunch: p.saturdayLunch + +!!c.saturdayLunch,
      saturdayDinner: p.saturdayDinner + +!!c.saturdayDinner,
      uniqueAttendees: p.uniqueAttendees + +!!(c.fridaySpeakers || c.saturdaySpeakers),
      bothSpeakers: p.bothSpeakers + +!!(c.fridaySpeakers && c.saturdaySpeakers),
      bothBreakfast: p.bothBreakfast + +!!(c.fridayBreakfast && c.saturdayBreakfast),
      bothLunch: p.bothLunch + +!!(c.fridayLunch && c.saturdayLunch),
      bothDinner: p.bothDinner + +!!(c.fridayDinner && c.saturdayDinner),
    }),
    {
      thursdayReception: 0,
      fridayReception: 0,
      fridayWorkshop: 0,
      fridaySpeakers: 0,
      fridayBreakfast: 0,
      fridayLunch: 0,
      fridayDinner: 0,
      saturdaySpeakers: 0,
      saturdayBreakfast: 0,
      saturdayLunch: 0,
      saturdayDinner: 0,
      uniqueAttendees: 0,
      bothSpeakers: 0,
      bothBreakfast: 0,
      bothLunch: 0,
      bothDinner: 0,
    }
  );

  return (
    <>
      <Typography variant="overline" color="primary">
        Registration Statistics
      </Typography>
      <IconButton aria-label="close" onClick={() => closeModal()} style={{ position: 'absolute', top: 8, right: 16 }}>
        <CloseRounded />
      </IconButton>
      <Box height="15px" />
      <Box display="flex" flexDirection="column">
        <Box display="flex">
          <Typography>Total unique attending:</Typography>
          <Typography color="primary" style={{ marginLeft: '10px' }}>
            {data.uniqueAttendees}
          </Typography>
        </Box>
        <Box height="10px" />
        <Box display="flex">
          {REGISTRATION_ARRAY.concat([['Both', ['Speakers', 'Breakfast', 'Lunch', 'Dinner']] as [any, any]]).map(
            ([grouping, elements], groupingIdx) => (
              <Box display="flex" flexDirection="column" flexGrow={1} key={groupingIdx}>
                <Typography>{grouping}</Typography>
                <Box height={10} />
                {elements.map((element, elementIdx) => (
                  <Fragment key={elementIdx}>
                    <Box display="flex" alignItems="center">
                      <Tooltip title={element} arrow>
                        <span
                          role="img"
                          aria-label={element}
                          style={{ fontSize: 25, margin: '0px 5px', cursor: 'default' }}
                        >
                          {EMOJI2[element]}
                        </span>
                      </Tooltip>
                      <Typography color="primary" style={{ marginLeft: '10px' }}>
                        {grouping === ConferenceItem.Reception
                          ? data[`${element.toLowerCase()}${grouping}` as keyof Registration]
                          : data[`${grouping.toLowerCase()}${element}` as keyof Registration]}
                      </Typography>
                    </Box>
                    <Box height={10} />
                  </Fragment>
                ))}
              </Box>
            )
          )}
        </Box>
      </Box>
    </>
  );
});
