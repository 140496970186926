import { Box, IconButton, Typography } from '@material-ui/core';
import { CloseRounded } from '@material-ui/icons';
import React, { FC } from 'react';
import { formatPhoneNumber, User } from '../core';
import { withModal } from './withModal';

interface Props {
  data: User;
}

export const Contact: FC<Props> = withModal(({ data, closeModal }) => {
  return (
    <>
      <Typography variant="overline" color="primary">
        Contact Information
      </Typography>
      <IconButton aria-label="close" onClick={() => closeModal()} style={{ position: 'absolute', top: 8, right: 16 }}>
        <CloseRounded />
      </IconButton>
      <Box display="flex" flexDirection="column" alignItems="center" marginY="20px">
        <Typography color="primary" style={{ margin: '10px 0' }}>
          <b>
            {data.firstName} {data.lastName}
          </b>
        </Typography>
        {!!data.email && (
          <Typography color="primary" style={{ margin: '10px 0' }}>
            {data.email}
          </Typography>
        )}
        {!!data.phone && (
          <Typography color="primary" style={{ margin: '10px 0' }}>
            {formatPhoneNumber(data.phone)}
          </Typography>
        )}
      </Box>
    </>
  );
});
