import { Box, Button, TextField, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { StateContext } from '../context';
import { AuthService, User } from '../core';
import { withModal } from './withModal';

interface Props {
  data: User;
}

export const UserResend: FC<Props> = withModal(({ data, closeModal }) => {
  const [canResend, setCanResend] = useState(false);
  const { withLoading } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const resendInvitation = withLoading(async () => {
    try {
      await AuthService.adminCreateUser({ Username: data.email!, MessageAction: 'RESEND' });
      closeModal();
      enqueueSnackbar(`Resent invitation to ${data.email}`, { variant: 'success' });
    } catch (error) {
      enqueueSnackbar(`Failed to resend invitation to ${data.firstName} ${data.lastName}`, { variant: 'error' });
    }
  });

  return (
    <>
      <Typography variant="overline" color="primary">
        Resend user invite
      </Typography>
      <Box height="10px" />
      <Typography color="primary">
        Are you sure you want to resend a new user invite email to{' '}
        <b>
          {data.firstName} {data.lastName}
        </b>
        ?
      </Typography>
      <Box height="20px" />
      <Typography color="primary">
        To confirm, type <i>Resend</i> in the box:
      </Typography>
      <Box height="10px" />
      <TextField
        variant="outlined"
        placeholder="Resend"
        onChange={(event) => setCanResend(event.target.value === 'Resend')}
        fullWidth
      />
      <Box height="30px" />
      <Box display="flex" justifyContent="flex-end">
        <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          disabled={!canResend}
          color="primary"
          variant="contained"
          style={{ margin: '0.5rem' }}
          onClick={() => resendInvitation()}
        >
          Resend
        </Button>
      </Box>
    </>
  );
});
