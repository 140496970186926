import { Box, IconButton, Tooltip } from '@material-ui/core';
import { Refresh } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { ApiService, ErrorHistory, useMaxTableBodyHeight } from '../core';

interface Props {}

export const ErrorLog: FC<Props> = () => {
  const [errorHistorys, setErrorHistorys] = useState<ErrorHistory[]>();
  const [loading, setLoading] = useState<boolean>(false);

  const fetchData = useCallback(() => {
    setLoading(true);
    ApiService.listErrorHistorys()
      .then((x) => setErrorHistorys(x))
      .then(
        () => setLoading(false),
        () => setLoading(false)
      );
  }, []);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  const columns: Column<ErrorHistory>[] = [
    { title: 'User', field: 'user' },
    { title: 'Date', field: 'createdAt', type: 'datetime', defaultSort: 'desc' },
    { title: 'Error', field: 'message' },
  ];

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%">
        <MaterialTable
          title="Error Log"
          columns={columns.map((c) => ({ disableClick: true, ...c }))}
          data={errorHistorys || []}
          options={{
            toolbarButtonAlignment: 'left',
            paging: true,
            pageSize: 5,
            pageSizeOptions: [5, 10, 25, 100],
            minBodyHeight: useMaxTableBodyHeight(),
            maxBodyHeight: useMaxTableBodyHeight(),
            padding: 'dense',
          }}
          onRowClick={() => {}}
          isLoading={loading}
          actions={[
            {
              icon: Refresh,
              iconProps: { color: 'primary' },
              tooltip: 'Refresh Data',
              isFreeAction: true,
              onClick: () => {
                fetchData();
              },
            },
          ]}
          components={{
            Action: (props) => {
              const action = props.action.action ? props.action.action(props.data) : props.action;
              return (
                <Tooltip title={action.tooltip} arrow>
                  <span>
                    <IconButton
                      color={action.iconProps.color}
                      onClick={(event) => action.onClick(event, props.data)}
                      disabled={action.disabled}
                    >
                      <action.icon />
                    </IconButton>
                  </span>
                </Tooltip>
              );
            },
          }}
        />
      </Box>
    </Box>
  );
};
