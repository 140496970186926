import { API, Auth } from 'aws-amplify';
import { CognitoIdentityServiceProvider as CISP } from 'aws-sdk';

import { ApiService } from './api.service';
import { AWSCognitoUser } from './interfaces';
import { LoggingService } from './logging.service';

export abstract class AuthService {
  static signIn = (email: string, password: string): Promise<AWSCognitoUser> =>
    Auth.signIn(email.toLowerCase(), password);
  static signOut = (): Promise<any> => Auth.signOut();

  static currentAuthenticatedUser = (): Promise<AWSCognitoUser | null> =>
    Auth.currentAuthenticatedUser().catch(() => null);

  static completeNewPassword = (user: AWSCognitoUser, password: string): Promise<AWSCognitoUser> =>
    Auth.completeNewPassword(user, password);

  static forgotPassword = (email: string) => Auth.forgotPassword(email.toLowerCase());
  static forgotPasswordSubmit = (email: string, code: string, password: string) =>
    Auth.forgotPasswordSubmit(email.toLowerCase(), code, password);

  static listUsers = async (): Promise<CISP.ListUsersResponse> => {
    let apiName = 'AdminQueries';
    let path = '/listUsers';
    let myInit = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return await API.get(apiName, path, myInit).catch(
      LoggingService.error('AuthService#listUsers', (error) => {
        throw error;
      })
    );
  };

  static adminCreateUser = (
    args: Omit<CISP.AdminCreateUserRequest, 'UserPoolId' | 'TemporaryPassword'>
  ): Promise<CISP.AdminCreateUserResponse> =>
    ApiService.getDeveloperCreds({ name: 'babtl-cognito-admin' })
      .then((result) => {
        if (!result) return null;
        const { region, accessKeyId, secretAccessKey, userPoolId } = result;
        return new CISP({ region, credentials: { accessKeyId, secretAccessKey } })
          .adminCreateUser({
            ...args,
            UserPoolId: userPoolId || '',
            TemporaryPassword: process.env.REACT_APP_AWS_TEMPORARY_PASSWORD,
          })
          .promise();
      })
      .catch(
        LoggingService.error('AuthService#adminCreateUser', (error) => {
          throw error;
        })
      );

  static adminDeleteUser = (args: Omit<CISP.AdminDeleteUserRequest, 'UserPoolId'>): Promise<{}> =>
    ApiService.getDeveloperCreds({ name: 'babtl-cognito-admin' })
      .then((result) => {
        if (!result) return null;
        const { region, accessKeyId, secretAccessKey, userPoolId } = result;
        return new CISP({ region, credentials: { accessKeyId, secretAccessKey } })
          .adminDeleteUser({ ...args, UserPoolId: userPoolId || '' })
          .promise();
      })
      .catch(
        LoggingService.error('AuthService#adminDeleteUser', (error) => {
          throw error;
        })
      );

  static addUserToGroup = async (username: string, groupname: string) => {
    let apiName = 'AdminQueries';
    let path = '/addUserToGroup';
    let myInit = {
      body: { username, groupname },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit).catch(
      LoggingService.error('AuthService#addUserToGroup', (error) => {
        throw error;
      })
    );
  };

  static removeUserFromGroup = async (username: string, groupname: string) => {
    let apiName = 'AdminQueries';
    let path = '/removeUserFromGroup';
    let myInit = {
      body: { username, groupname },
      headers: {
        'Content-Type': 'application/json',
        Authorization: `${(await Auth.currentSession()).getAccessToken().getJwtToken()}`,
      },
    };
    return await API.post(apiName, path, myInit).catch(
      LoggingService.error('AuthService#removeUserFromGroup', (error) => {
        throw error;
      })
    );
  };
}
