import React, { createContext, FC, useCallback, useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { ZenObservable } from 'zen-observable-ts';

import { LoadingBackdrop } from '../components';
import {
  AccessRole,
  ApiService,
  AuthService,
  CustomUser,
  GenericFn,
  Group,
  InviteStatus,
  Maybe,
  AppPermission,
  Role,
  sortGroupsAlpha,
  sortPermissionsAlpha,
  sortRolesAlpha,
  sortUsersAlpha,
  User,
  BadgeDownload,
  PermissionName,
  DiscountPackage,
  MasterSwitch,
} from '../core';
import { LoggingService } from '../core/logging.service';

interface StateContextProps {
  user?: Maybe<User>;
  registrations?: Maybe<CustomUser[]>;
  groups?: Maybe<Group[]>;
  discountPackages?: Maybe<DiscountPackage[]>;
  roles?: Maybe<Role[]>;
  permissions?: Maybe<AppPermission[]>;
  downloads?: Maybe<BadgeDownload[]>;
  masterSwitchs?: Maybe<MasterSwitch[]>;
  updateUser: () => Promise<void>;
  updateRegistrations: () => Promise<void>;
  updateGroups: () => Promise<void>;
  updateDiscountPackages: () => Promise<void>;
  updateRoles: () => Promise<void>;
  updateDownloads: () => Promise<void>;
  updateMasterSwitchs: () => Promise<void>;
  logOut: () => Promise<void>;
  withLoading: <T extends any[]>(fn: GenericFn<T, Promise<any>>) => GenericFn<T, Promise<any>>;
}

export const StateContext = createContext<StateContextProps>({} as StateContextProps);

export const StateProvider: FC = ({ children }) => {
  const [user, setUser] = useState<Maybe<User>>();
  const [registrations, setRegistrations] = useState<Maybe<CustomUser[]>>();
  const [groups, setGroups] = useState<Maybe<Group[]>>();
  const [discountPackages, setDiscountPackages] = useState<Maybe<DiscountPackage[]>>();
  const [roles, setRoles] = useState<Maybe<Role[]>>();
  const [permissions, setPermissions] = useState<Maybe<AppPermission[]>>();
  const [downloads, setDownloads] = useState<Maybe<BadgeDownload[]>>();
  const [masterSwitchs, setMasterSwitchs] = useState<Maybe<MasterSwitch[]>>();
  const [, setSubscriptions] = useState<ZenObservable.Subscription[]>([]);
  const [loading, setLoading] = useState<number>(0);
  const { enqueueSnackbar } = useSnackbar();

  const withLoading: <T extends any[]>(fn: GenericFn<T, Promise<any>>) => GenericFn<T, Promise<any>> =
    (fn) =>
    async (...args) => {
      setLoading((loading) => loading + 1);
      await fn(...args);
      setLoading((loading) => loading - 1);
    };

  const updateUser = useCallback(
    withLoading(async () => {
      const userAuthenticated = await AuthService.currentAuthenticatedUser();
      if (userAuthenticated) {
        const userApi = await ApiService.getUserByCognitoId({ cognitoId: userAuthenticated.getUsername() });
        if (userApi) {
          setUser(userApi);
        } else {
          enqueueSnackbar('Server error. User not found. Please contact an administrator.', { variant: 'error' });
          logOut();
        }
      } else {
        setUser(null);
      }
    }),
    []
  );

  const updateRegistrations = useCallback(
    withLoading(async () => {
      try {
        const canManageGroupLeaders = user?.permissions?.items
          .map((item) => item.permission.permissionName)
          .includes(PermissionName.ManageGroupLeaders);
        const isAdmin = user?.accessRole === AccessRole.Admin;

        let listUsers = await ApiService.listUsers().then((users) => users && users.sort(sortUsersAlpha));
        setRegistrations(listUsers);

        if (isAdmin || canManageGroupLeaders) {
          const authUsers = (await AuthService.listUsers().catch(() => null))?.Users;
          setRegistrations((registrations) =>
            registrations?.map((registration) => {
              const match = authUsers?.find((authUser) => authUser.Username === registration.cognitoId);
              if (!match) return registration;
              return { ...registration, inviteAccepted: match.UserStatus === InviteStatus.Accepted };
            })
          );
        }
      } catch (error) {
        enqueueSnackbar('Server error. Unable to load registrations. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updateGroups = useCallback(
    withLoading(async () => {
      try {
        const canManageGroupLeaders = user?.permissions?.items
          .map((item) => item.permission.permissionName)
          .includes(PermissionName.ManageGroupLeaders);
        const isRegistrar = user?.accessRole === AccessRole.Registrar;

        let listGroups = await ApiService.listGroups().then((groups) => groups && groups.sort(sortGroupsAlpha));
        if (listGroups && isRegistrar && canManageGroupLeaders)
          listGroups = listGroups.filter((g) => g.managedBy?.id === user?.id);
        setGroups(listGroups);
      } catch (error) {
        enqueueSnackbar('Server error. Unable to load groups. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updateDiscountPackages = useCallback(
    withLoading(async () => {
      try {
        const listDiscountPackages = await ApiService.listDiscountPackages();
        setDiscountPackages(listDiscountPackages);
      } catch (error) {
        enqueueSnackbar('Server error. Unable to load discount packages. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updateRoles = useCallback(
    withLoading(async () => {
      try {
        setRoles(await ApiService.listRoles().then((roles) => roles && roles.sort(sortRolesAlpha)));
      } catch (error) {
        enqueueSnackbar('Server error. Unable to load roles. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updatePermissions = useCallback(
    withLoading(async () => {
      try {
        setPermissions(
          await ApiService.listPermissions().then(
            (permissions) => permissions && permissions.sort(sortPermissionsAlpha)
          )
        );
      } catch (error) {
        enqueueSnackbar('Server error. Unable to load user permissions. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updateDownloads = useCallback(
    withLoading(async () => {
      try {
        setDownloads(await ApiService.listBadgeDownloads());
      } catch (error) {
        enqueueSnackbar('Server error. Unable to retrieve download history. Please contact an administrator.', {
          variant: 'error',
        });
      }
    }),
    [user]
  );

  const updateMasterSwitchs = useCallback(async () => {
    setMasterSwitchs(
      await ApiService.listMasterSwitchs().catch(
        LoggingService.error('Failure retrieving master switches!', (e) => null)
      )
    );
  }, []);

  const createSubscriptions = useCallback(() => {
    const subs = [
      ApiService.onCreateMasterSwitch(() => updateMasterSwitchs()),
      ApiService.onDeleteMasterSwitch(() => updateMasterSwitchs()),
    ];
    setSubscriptions((prev) => {
      if (prev) prev.forEach((p) => p.unsubscribe());
      return subs;
    });
  }, [updateMasterSwitchs]);

  const logOut = withLoading(async () => {
    await AuthService.signOut();
    await updateUser();
  });

  useEffect(() => {
    updateUser();
  }, [updateUser]);

  useEffect(() => {
    if (user) {
      createSubscriptions();
      updateRegistrations();
      updateGroups();
      updateDiscountPackages();
      updateRoles();
      updateMasterSwitchs();
    }
    if (user?.accessRole === AccessRole.Admin) {
      updatePermissions();
      updateDownloads();
    }
  }, [
    user,
    createSubscriptions,
    updateRegistrations,
    updateGroups,
    updateDiscountPackages,
    updateRoles,
    updatePermissions,
    updateDownloads,
    updateMasterSwitchs,
  ]);

  return (
    <StateContext.Provider
      value={{
        user,
        registrations,
        groups,
        discountPackages,
        roles,
        permissions,
        downloads,
        masterSwitchs,
        updateUser,
        updateRegistrations,
        updateGroups,
        updateDiscountPackages,
        updateRoles,
        updateDownloads,
        updateMasterSwitchs,
        logOut,
        withLoading,
      }}
    >
      {children}
      <LoadingBackdrop open={!!loading} />
    </StateContext.Provider>
  );
};
