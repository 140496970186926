export enum ID {
  Action_Edit = 'editAction',
  Form_Discount_Name = 'discountName',
  Form_Discount_Submit = 'submitDiscount',
  Form_Group_Name = 'groupName',
  Form_Group_Submit = 'submitGroup',
  Form_Individuals_File_Upload = 'individualsFileUpload',
  Form_Registration_FirstName = 'firstName',
  Form_Registration_LastName = 'lastName',
  Form_Registration_Notes = 'notes',
  Form_Registration_Submit = 'submitRegistration',
  Form_UserCreate_FirstName = 'user-firstName',
  Form_UserCreate_LastName = 'user-lastName',
  Form_UserCreate_Group = 'user-group',
  Form_UserCreate_Email = 'user-email',
  Form_UserCreate_Registrar = `radio-Registrar`,
  Form_UserCreate_GroupLeader = 'radio-GroupLeader',
  Form_UserCreate_Submit = 'submitUser',
  Form_UserEdit_Groups = 'userEdit-groups',
  Form_UserEdit_Groups_Input = 'userEdit-groups-input',
  Form_UserEdit_Groups_Chip = 'userEdit-groups-chip',
  Form_UserEdit_Submit = 'userEdit-submit',
  Greeting = 'hello',
  Login_Email = 'email',
  Login_Password = 'password',
  Login_Submit = 'submitAuth',
  Menu = 'menu',
  Menu_Groups = 'menu-groups',
  Menu_LogOut = 'logout',
  Menu_Users = 'menu-users',
  Modal_Confirm = 'confirmSubmit',
  Page_Discounts_Add = 'addDiscount',
  Page_Groups_Add = 'addGroup',
  Page_Registrations_Add = 'addRegistration',
  Page_Users_Add = 'addUser',
  Snackbar = 'client-snackbar',
  Table_Users_Groups_Chip = 'table-users-groups-chip',
}

export enum AuthChallenge {
  NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED',
}

export enum AccessRole {
  Admin = 'admin',
  Registrar = 'volunteer',
  GroupLeader = 'group',
}

export enum InviteStatus {
  Pending = 'FORCE_CHANGE_PASSWORD',
  Accepted = 'CONFIRMED',
}

export enum ConferenceDay {
  Thursday = 'Thursday',
  Friday = 'Friday',
  Saturday = 'Saturday',
}

export enum ConferenceItem {
  Speakers = 'Speakers',
  Breakfast = 'Breakfast',
  Lunch = 'Lunch',
  Dinner = 'Dinner',
  Reception = 'Reception',
  Workshop = 'Workshop',
}

export type ConferenceGrouping = ConferenceItem.Reception | ConferenceDay.Friday | ConferenceDay.Saturday;
export type ConferenceElement =
  | ConferenceItem.Workshop
  | ConferenceItem.Speakers
  | ConferenceItem.Breakfast
  | ConferenceItem.Lunch
  | ConferenceItem.Dinner
  | ConferenceDay.Thursday
  | ConferenceDay.Friday;

export enum PermissionName {
  EditPrivateReceptionsAndWorkshop = 'editPrivateReceptions',
  ManageGroupLeaders = 'manageGroupLeaders',
  ViewActivityLog = 'viewActivityLog',
}

export enum DownloadType {
  All = 'All',
  Reprint = 'Reprint',
  Individual = 'Individual',
}

export enum HubChannel {
  Registration = 'registration',
  Error = 'error',
}

export enum HubEvent {
  Create = 'create',
  Update = 'update',
  Delete = 'delete',
  Error = 'error',
}

export enum DiscountType {
  DollarCredit = 'DollarCredit',
  PercentDiscount = 'PercentDiscount',
}

export enum DiscountItem {
  Speakers = 'Speakers',
  Breakfast = 'Breakfast',
  Lunch = 'Lunch',
  Dinner = 'Dinner',
  ALL = 'ALL',
}

export enum MasterSwitchItem {
  FridayDinnerSoldOut = 'FridayDinnerSoldOut',
  FridayLunchSoldOut = 'FridayLunchSoldOut',
  SaturdayDinnerSoldOut = 'SaturdayDinnerSoldOut',
  SaturdayLunchSoldOut = 'SaturdayLunchSoldOut',
}
