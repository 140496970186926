import { Box, Button } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import React, { FC } from 'react';
import { ID } from '../core';
import { UserCreate } from './UserCreate';
import { UsersTable } from './UsersTable';
import { withModal } from './withModal';

export const Users: FC = withModal(({ openModal }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%" maxWidth="1400px">
        <Button
          id={ID.Page_Users_Add}
          color="primary"
          variant="outlined"
          startIcon={<AddRounded />}
          onClick={() => openModal(<UserCreate />)}
          style={{ margin: '0.5rem' }}
        >
          Add New User
        </Button>
        <UsersTable />
      </Box>
    </Box>
  );
});
