import { Box, Button } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import React, { FC, useContext, useState } from 'react';
import { StateContext } from '../context';
import { AccessRole, ID } from '../core';
import { DiscountForm } from './DiscountForm';
import { DiscountsTable } from './DiscountsTable';
import { withModal } from './withModal';

interface Props {}

export const Discounts: FC<Props> = withModal(({ openModal }) => {
  const { user } = useContext(StateContext);
  const [isAdmin] = useState<boolean>(user?.accessRole === AccessRole.Admin);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%" maxWidth="1200px">
        {isAdmin && (
          <Button
            id={ID.Page_Discounts_Add}
            color="primary"
            variant="outlined"
            startIcon={<AddRounded />}
            onClick={() => openModal(<DiscountForm />, 650)}
            style={{ margin: '0.5rem' }}
          >
            Add New Discount Package
          </Button>
        )}
        <DiscountsTable />
      </Box>
    </Box>
  );
});
