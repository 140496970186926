import { Box, Typography } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { StateContext } from '../context';
import {
  AccessRole,
  ConferenceDay,
  ConferenceGrouping,
  ConferenceItem,
  isPermitted,
  MasterSwitchItem,
  PermissionName,
  REGISTRATION_GROUPING,
} from '../core';
import { RegistrationSelect } from './RegistrationSelect';

interface RegistrationSelectGroupOptions {
  speakers: boolean;
  breakfast: boolean;
  lunch: boolean;
  dinner: boolean;
  thursday: boolean;
  friday: boolean;
  workshop: boolean;
}

interface Props {
  grouping: ConferenceGrouping;
  existingSelections?: Partial<RegistrationSelectGroupOptions>;
  register: () => any;
}

export const RegistrationSelectGroup: FC<Props> = ({ grouping, existingSelections = {}, register }) => {
  const { user, permissions, masterSwitchs } = useContext(StateContext);
  const [registration, setRegistration] = useState<Partial<RegistrationSelectGroupOptions>>(existingSelections);
  const [isDinnerSoldOut, setIsDinnerSoldOut] = useState<boolean>(false);
  const [isLunchSoldOut, setIsLunchSoldOut] = useState<boolean>(false);

  useEffect(() => {
    if (grouping === ConferenceDay.Friday) {
      setIsDinnerSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.FridayDinnerSoldOut) || false);
      setIsLunchSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.FridayLunchSoldOut) || false);
    }
    if (grouping === ConferenceDay.Saturday) {
      setIsDinnerSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.SaturdayDinnerSoldOut) || false);
      setIsLunchSoldOut(masterSwitchs?.map((x) => x.key).includes(MasterSwitchItem.SaturdayLunchSoldOut) || false);
    }
  }, [masterSwitchs, grouping]);

  return (
    <Box display="flex" flexDirection="column" flexGrow={1} key={grouping} padding="5px">
      <Typography>{grouping}</Typography>
      {REGISTRATION_GROUPING[grouping].includes(ConferenceItem.Speakers) && (
        <RegistrationSelect
          day={grouping as ConferenceDay}
          item={ConferenceItem.Speakers}
          element={ConferenceItem.Speakers}
          checked={registration.speakers}
          onChange={(_, checked) => setRegistration((x) => ({ speakers: checked }))}
          register={register}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceItem.Breakfast) && (
        <RegistrationSelect
          day={grouping as ConferenceDay}
          item={ConferenceItem.Breakfast}
          element={ConferenceItem.Breakfast}
          checked={registration.breakfast}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, breakfast: checked }))}
          disabled={!registration.speakers}
          register={register}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceItem.Lunch) && (
        <RegistrationSelect
          day={grouping as ConferenceDay}
          item={ConferenceItem.Lunch}
          element={ConferenceItem.Lunch}
          checked={registration.lunch}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, lunch: checked }))}
          disabled={
            !registration.speakers || (!registration.lunch && isLunchSoldOut && user?.accessRole !== AccessRole.Admin)
          }
          register={register}
          isSoldOut={isLunchSoldOut}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceItem.Dinner) && (
        <RegistrationSelect
          day={grouping as ConferenceDay}
          item={ConferenceItem.Dinner}
          element={ConferenceItem.Dinner}
          checked={registration.dinner}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, dinner: checked }))}
          disabled={
            !registration.speakers || (!registration.dinner && isDinnerSoldOut && user?.accessRole !== AccessRole.Admin)
          }
          register={register}
          isSoldOut={isDinnerSoldOut}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceItem.Workshop) && (
        <RegistrationSelect
          day={grouping as ConferenceDay}
          item={ConferenceItem.Workshop}
          element={ConferenceItem.Workshop}
          checked={registration.workshop}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, workshop: checked }))}
          register={register}
          disabled={!isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop)}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceDay.Thursday) && (
        <RegistrationSelect
          day={ConferenceDay.Thursday}
          item={ConferenceItem.Reception}
          element={ConferenceDay.Thursday}
          checked={registration.thursday}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, thursday: checked }))}
          register={register}
          disabled={!isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop)}
        />
      )}
      {REGISTRATION_GROUPING[grouping].includes(ConferenceDay.Friday) && (
        <RegistrationSelect
          day={ConferenceDay.Friday}
          item={ConferenceItem.Reception}
          element={ConferenceDay.Friday}
          checked={registration.friday}
          onChange={(_, checked) => setRegistration((x) => ({ ...x, friday: checked }))}
          register={register}
          disabled={!isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop)}
        />
      )}
    </Box>
  );
};
