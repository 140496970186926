import { Box, Button } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import React, { FC } from 'react';
import { RoleForm } from './RoleForm';
import { RolesTable } from './RolesTable';
import { withModal } from './withModal';

export const Roles: FC = withModal(({ openModal }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%" maxWidth="800px">
        <Button
          color="primary"
          variant="outlined"
          startIcon={<AddRounded />}
          onClick={() => openModal(<RoleForm />)}
          style={{ margin: '0.5rem' }}
        >
          Add New Convention Role
        </Button>
        <RolesTable />
      </Box>
    </Box>
  );
});
