import React, { createContext, useState, ReactElement, useCallback, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { Modal } from '../components';

interface ModalContextProps {
  openModal: (content?: ReactElement, width?: number) => void;
  closeModal: () => void;
}

export const ModalContext = createContext<ModalContextProps>({} as ModalContextProps);

export const ModalProvider = ({ children }: any) => {
  const [open, setOpen] = useState(false);
  const [content, setContent] = useState<ReactElement>(<></>);
  const [width, setWidth] = useState<number>(400);
  const location = useLocation();
  const [pathname, setPathname] = useState<string>(location.pathname);

  const openModal = useCallback((content?: ReactElement, width?: number) => {
    content && setContent(content);
    width && setWidth(width);
    setOpen(true);
  }, []);

  const closeModal = useCallback(() => {
    setOpen(false);
    setWidth(400);
  }, []);

  useEffect(() => setPathname(location.pathname), [location]);
  useEffect(() => closeModal(), [pathname, closeModal]);

  return (
    <ModalContext.Provider value={{ openModal, closeModal }}>
      {children}
      <Modal open={open} onClose={() => closeModal()} width={width}>
        {content}
      </Modal>
    </ModalContext.Provider>
  );
};
