import { Box, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useState } from 'react';
import { useForm } from 'react-hook-form';

import { StateContext } from '../context';
import { AccessRole, ACCESS_ROLE, AuthService, ID, RegistrationService } from '../core';
import { Form } from './Form';
import { InputRadioGroup } from './InputRadioGroup';
import { InputSelect } from './InputSelect';
import { InputText } from './InputText';
import { withModal } from './withModal';

interface DataUserCreate {
  Group?: string;
  Access: AccessRole;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  ConventionRole: string;
}

interface Props {}

export const UserCreate: FC<Props> = withModal(({ closeModal }) => {
  const { withLoading, updateRegistrations, updateGroups, user, groups, roles } = useContext(StateContext);
  const form = useForm<DataUserCreate>();
  const { enqueueSnackbar } = useSnackbar();
  const [isAdmin] = useState<boolean>(user?.accessRole === AccessRole.Admin);

  const onSubmit = form.handleSubmit(
    withLoading<[DataUserCreate]>(async (data) => {
      try {
        const snackbar: { message: string; variant: 'success' | 'error' }[] = [];
        const email = data.Email.toLowerCase();
        const authUser = await AuthService.adminCreateUser({
          Username: email,
          UserAttributes: [
            { Name: 'name', Value: `${data.FirstName} ${data.LastName}` },
            { Name: 'email', Value: email },
            { Name: 'email_verified', Value: 'true' },
          ],
        });
        await AuthService.addUserToGroup(authUser?.User?.Username || '', data.Access || AccessRole.GroupLeader);
        await RegistrationService.create(
          {
            cognitoId: authUser?.User?.Username || '',
            firstName: data.FirstName,
            lastName: data.LastName,
            email: email,
            phone: data.Phone,
            accessRole: data.Access || AccessRole.GroupLeader,
            userAccessGrantedById: user?.id,
            userRoleId: data.ConventionRole
              ? roles?.find((role) => role.roleName === data.ConventionRole)?.id || undefined
              : undefined,
          },
          data.Group ? groups!.filter((g) => data.Group === g.groupName).map((group) => group.id) : [],
          data.Group ? groups!.filter((g) => data.Group === g.groupName).map((group) => group.id) : []
        );
        updateRegistrations();
        updateGroups();
        closeModal();
        snackbar.unshift({ message: `New user invitation sent to ${data.Email}`, variant: 'success' });
        snackbar.forEach(({ message, variant }) => enqueueSnackbar(message, { variant }));
      } catch (error) {
        enqueueSnackbar('Failed to add new user.', { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          Add new user
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        {[
          isAdmin && (
            <InputRadioGroup
              name="Access"
              options={[
                { label: ACCESS_ROLE[AccessRole.Registrar], value: AccessRole.Registrar },
                { label: ACCESS_ROLE[AccessRole.GroupLeader], value: AccessRole.GroupLeader },
              ]}
              rules={{ required: true }}
              row
            />
          ),
          (form.watch('Access') === AccessRole.GroupLeader || !isAdmin) && (
            <InputSelect
              id={ID.Form_UserCreate_Group}
              name="Group"
              options={(groups || []).map((group) => group.groupName)}
              rules={{ required: true }}
            />
          ),
          <InputText id={ID.Form_UserCreate_FirstName} name="FirstName" rules={{ required: true }} />,
          <InputText id={ID.Form_UserCreate_LastName} name="LastName" rules={{ required: true }} />,
          <InputText id={ID.Form_UserCreate_Email} name="Email" rules={{ required: true }} />,
          <InputText phone />,
          isAdmin && <InputSelect name="ConventionRole" options={(roles || []).map((role) => role.roleName)} />,
        ]
          .filter((x) => !!x)
          .map((field, idx) => (
            <Box key={idx} display="flex" margin="2px">
              {field}
            </Box>
          ))}
        <Box display="flex" marginTop="20px" justifyContent="center">
          <Button
            id={ID.Form_UserCreate_Submit}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem' }}
          >
            Send Invitation
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
