/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createUser = /* GraphQL */ `mutation CreateUser(
  $input: CreateUserInput!
  $condition: ModelUserConditionInput
) {
  createUser(input: $input, condition: $condition) {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserMutationVariables,
  APITypes.CreateUserMutation
>;
export const updateUser = /* GraphQL */ `mutation UpdateUser(
  $input: UpdateUserInput!
  $condition: ModelUserConditionInput
) {
  updateUser(input: $input, condition: $condition) {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserMutationVariables,
  APITypes.UpdateUserMutation
>;
export const deleteUser = /* GraphQL */ `mutation DeleteUser(
  $input: DeleteUserInput!
  $condition: ModelUserConditionInput
) {
  deleteUser(input: $input, condition: $condition) {
    id
    cognitoId
    donorPerfectId
    accessRole
    accessGrantedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    firstName
    lastName
    email
    phone
    groups {
      nextToken
      __typename
    }
    groupsRegistrar {
      nextToken
      __typename
    }
    userRoleId
    role {
      id
      roleName
      createdAt
      updatedAt
      version
      __typename
    }
    permissions {
      nextToken
      __typename
    }
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserMutationVariables,
  APITypes.DeleteUserMutation
>;
export const createUserGroup = /* GraphQL */ `mutation CreateUserGroup(
  $input: CreateUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  createUserGroup(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserGroupMutationVariables,
  APITypes.CreateUserGroupMutation
>;
export const updateUserGroup = /* GraphQL */ `mutation UpdateUserGroup(
  $input: UpdateUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  updateUserGroup(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserGroupMutationVariables,
  APITypes.UpdateUserGroupMutation
>;
export const deleteUserGroup = /* GraphQL */ `mutation DeleteUserGroup(
  $input: DeleteUserGroupInput!
  $condition: ModelUserGroupConditionInput
) {
  deleteUserGroup(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserGroupMutationVariables,
  APITypes.DeleteUserGroupMutation
>;
export const createUserGroupRegistrar = /* GraphQL */ `mutation CreateUserGroupRegistrar(
  $input: CreateUserGroupRegistrarInput!
  $condition: ModelUserGroupRegistrarConditionInput
) {
  createUserGroupRegistrar(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserGroupRegistrarMutationVariables,
  APITypes.CreateUserGroupRegistrarMutation
>;
export const updateUserGroupRegistrar = /* GraphQL */ `mutation UpdateUserGroupRegistrar(
  $input: UpdateUserGroupRegistrarInput!
  $condition: ModelUserGroupRegistrarConditionInput
) {
  updateUserGroupRegistrar(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserGroupRegistrarMutationVariables,
  APITypes.UpdateUserGroupRegistrarMutation
>;
export const deleteUserGroupRegistrar = /* GraphQL */ `mutation DeleteUserGroupRegistrar(
  $input: DeleteUserGroupRegistrarInput!
  $condition: ModelUserGroupRegistrarConditionInput
) {
  deleteUserGroupRegistrar(input: $input, condition: $condition) {
    userId
    groupId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    group {
      id
      groupName
      notes
      discountPackageId
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserGroupRegistrarMutationVariables,
  APITypes.DeleteUserGroupRegistrarMutation
>;
export const createGroup = /* GraphQL */ `mutation CreateGroup(
  $input: CreateGroupInput!
  $condition: ModelGroupConditionInput
) {
  createGroup(input: $input, condition: $condition) {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateGroupMutationVariables,
  APITypes.CreateGroupMutation
>;
export const updateGroup = /* GraphQL */ `mutation UpdateGroup(
  $input: UpdateGroupInput!
  $condition: ModelGroupConditionInput
) {
  updateGroup(input: $input, condition: $condition) {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateGroupMutationVariables,
  APITypes.UpdateGroupMutation
>;
export const deleteGroup = /* GraphQL */ `mutation DeleteGroup(
  $input: DeleteGroupInput!
  $condition: ModelGroupConditionInput
) {
  deleteGroup(input: $input, condition: $condition) {
    id
    groupName
    managedBy {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    notes
    users {
      nextToken
      __typename
    }
    registrars {
      nextToken
      __typename
    }
    discountPackageId
    discountPackage {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteGroupMutationVariables,
  APITypes.DeleteGroupMutation
>;
export const createRole = /* GraphQL */ `mutation CreateRole(
  $input: CreateRoleInput!
  $condition: ModelRoleConditionInput
) {
  createRole(input: $input, condition: $condition) {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRoleMutationVariables,
  APITypes.CreateRoleMutation
>;
export const updateRole = /* GraphQL */ `mutation UpdateRole(
  $input: UpdateRoleInput!
  $condition: ModelRoleConditionInput
) {
  updateRole(input: $input, condition: $condition) {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRoleMutationVariables,
  APITypes.UpdateRoleMutation
>;
export const deleteRole = /* GraphQL */ `mutation DeleteRole(
  $input: DeleteRoleInput!
  $condition: ModelRoleConditionInput
) {
  deleteRole(input: $input, condition: $condition) {
    id
    roleName
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRoleMutationVariables,
  APITypes.DeleteRoleMutation
>;
export const createUserPermission = /* GraphQL */ `mutation CreateUserPermission(
  $input: CreateUserPermissionInput!
  $condition: ModelUserPermissionConditionInput
) {
  createUserPermission(input: $input, condition: $condition) {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateUserPermissionMutationVariables,
  APITypes.CreateUserPermissionMutation
>;
export const updateUserPermission = /* GraphQL */ `mutation UpdateUserPermission(
  $input: UpdateUserPermissionInput!
  $condition: ModelUserPermissionConditionInput
) {
  updateUserPermission(input: $input, condition: $condition) {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateUserPermissionMutationVariables,
  APITypes.UpdateUserPermissionMutation
>;
export const deleteUserPermission = /* GraphQL */ `mutation DeleteUserPermission(
  $input: DeleteUserPermissionInput!
  $condition: ModelUserPermissionConditionInput
) {
  deleteUserPermission(input: $input, condition: $condition) {
    userId
    permissionId
    user {
      id
      cognitoId
      donorPerfectId
      accessRole
      firstName
      lastName
      email
      phone
      userRoleId
      thursdayReception
      fridayReception
      fridayWorkshop
      fridaySpeakers
      fridayBreakfast
      fridayLunch
      fridayDinner
      saturdaySpeakers
      saturdayBreakfast
      saturdayLunch
      saturdayDinner
      notes
      createdAt
      updatedAt
      version
      __typename
    }
    permission {
      id
      permissionName
      accessEnabled
      accessEligible
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteUserPermissionMutationVariables,
  APITypes.DeleteUserPermissionMutation
>;
export const createAppPermission = /* GraphQL */ `mutation CreateAppPermission(
  $input: CreateAppPermissionInput!
  $condition: ModelAppPermissionConditionInput
) {
  createAppPermission(input: $input, condition: $condition) {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateAppPermissionMutationVariables,
  APITypes.CreateAppPermissionMutation
>;
export const updateAppPermission = /* GraphQL */ `mutation UpdateAppPermission(
  $input: UpdateAppPermissionInput!
  $condition: ModelAppPermissionConditionInput
) {
  updateAppPermission(input: $input, condition: $condition) {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateAppPermissionMutationVariables,
  APITypes.UpdateAppPermissionMutation
>;
export const deleteAppPermission = /* GraphQL */ `mutation DeleteAppPermission(
  $input: DeleteAppPermissionInput!
  $condition: ModelAppPermissionConditionInput
) {
  deleteAppPermission(input: $input, condition: $condition) {
    id
    permissionName
    accessEnabled
    accessEligible
    users {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteAppPermissionMutationVariables,
  APITypes.DeleteAppPermissionMutation
>;
export const createBadgeDownload = /* GraphQL */ `mutation CreateBadgeDownload(
  $input: CreateBadgeDownloadInput!
  $condition: ModelBadgeDownloadConditionInput
) {
  createBadgeDownload(input: $input, condition: $condition) {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateBadgeDownloadMutationVariables,
  APITypes.CreateBadgeDownloadMutation
>;
export const updateBadgeDownload = /* GraphQL */ `mutation UpdateBadgeDownload(
  $input: UpdateBadgeDownloadInput!
  $condition: ModelBadgeDownloadConditionInput
) {
  updateBadgeDownload(input: $input, condition: $condition) {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateBadgeDownloadMutationVariables,
  APITypes.UpdateBadgeDownloadMutation
>;
export const deleteBadgeDownload = /* GraphQL */ `mutation DeleteBadgeDownload(
  $input: DeleteBadgeDownloadInput!
  $condition: ModelBadgeDownloadConditionInput
) {
  deleteBadgeDownload(input: $input, condition: $condition) {
    id
    day
    type
    printed
    usersUpdate
    usersRemove
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteBadgeDownloadMutationVariables,
  APITypes.DeleteBadgeDownloadMutation
>;
export const createRegistrationHistory = /* GraphQL */ `mutation CreateRegistrationHistory(
  $input: CreateRegistrationHistoryInput!
  $condition: ModelRegistrationHistoryConditionInput
) {
  createRegistrationHistory(input: $input, condition: $condition) {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateRegistrationHistoryMutationVariables,
  APITypes.CreateRegistrationHistoryMutation
>;
export const updateRegistrationHistory = /* GraphQL */ `mutation UpdateRegistrationHistory(
  $input: UpdateRegistrationHistoryInput!
  $condition: ModelRegistrationHistoryConditionInput
) {
  updateRegistrationHistory(input: $input, condition: $condition) {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateRegistrationHistoryMutationVariables,
  APITypes.UpdateRegistrationHistoryMutation
>;
export const deleteRegistrationHistory = /* GraphQL */ `mutation DeleteRegistrationHistory(
  $input: DeleteRegistrationHistoryInput!
  $condition: ModelRegistrationHistoryConditionInput
) {
  deleteRegistrationHistory(input: $input, condition: $condition) {
    id
    user
    event
    createdAt
    registrationId
    firstName
    lastName
    email
    phone
    groups
    role
    thursdayReception
    fridayReception
    fridayWorkshop
    fridaySpeakers
    fridayBreakfast
    fridayLunch
    fridayDinner
    saturdaySpeakers
    saturdayBreakfast
    saturdayLunch
    saturdayDinner
    notes
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteRegistrationHistoryMutationVariables,
  APITypes.DeleteRegistrationHistoryMutation
>;
export const createErrorHistory = /* GraphQL */ `mutation CreateErrorHistory(
  $input: CreateErrorHistoryInput!
  $condition: ModelErrorHistoryConditionInput
) {
  createErrorHistory(input: $input, condition: $condition) {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateErrorHistoryMutationVariables,
  APITypes.CreateErrorHistoryMutation
>;
export const updateErrorHistory = /* GraphQL */ `mutation UpdateErrorHistory(
  $input: UpdateErrorHistoryInput!
  $condition: ModelErrorHistoryConditionInput
) {
  updateErrorHistory(input: $input, condition: $condition) {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateErrorHistoryMutationVariables,
  APITypes.UpdateErrorHistoryMutation
>;
export const deleteErrorHistory = /* GraphQL */ `mutation DeleteErrorHistory(
  $input: DeleteErrorHistoryInput!
  $condition: ModelErrorHistoryConditionInput
) {
  deleteErrorHistory(input: $input, condition: $condition) {
    id
    user
    createdAt
    message
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteErrorHistoryMutationVariables,
  APITypes.DeleteErrorHistoryMutation
>;
export const createDiscount = /* GraphQL */ `mutation CreateDiscount(
  $input: CreateDiscountInput!
  $condition: ModelDiscountConditionInput
) {
  createDiscount(input: $input, condition: $condition) {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscountMutationVariables,
  APITypes.CreateDiscountMutation
>;
export const updateDiscount = /* GraphQL */ `mutation UpdateDiscount(
  $input: UpdateDiscountInput!
  $condition: ModelDiscountConditionInput
) {
  updateDiscount(input: $input, condition: $condition) {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscountMutationVariables,
  APITypes.UpdateDiscountMutation
>;
export const deleteDiscount = /* GraphQL */ `mutation DeleteDiscount(
  $input: DeleteDiscountInput!
  $condition: ModelDiscountConditionInput
) {
  deleteDiscount(input: $input, condition: $condition) {
    id
    type
    priority
    creditAmount
    discountItem
    discountRate
    discountCount
    packageId
    package {
      id
      name
      createdAt
      updatedAt
      version
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscountMutationVariables,
  APITypes.DeleteDiscountMutation
>;
export const createDiscountPackage = /* GraphQL */ `mutation CreateDiscountPackage(
  $input: CreateDiscountPackageInput!
  $condition: ModelDiscountPackageConditionInput
) {
  createDiscountPackage(input: $input, condition: $condition) {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateDiscountPackageMutationVariables,
  APITypes.CreateDiscountPackageMutation
>;
export const updateDiscountPackage = /* GraphQL */ `mutation UpdateDiscountPackage(
  $input: UpdateDiscountPackageInput!
  $condition: ModelDiscountPackageConditionInput
) {
  updateDiscountPackage(input: $input, condition: $condition) {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateDiscountPackageMutationVariables,
  APITypes.UpdateDiscountPackageMutation
>;
export const deleteDiscountPackage = /* GraphQL */ `mutation DeleteDiscountPackage(
  $input: DeleteDiscountPackageInput!
  $condition: ModelDiscountPackageConditionInput
) {
  deleteDiscountPackage(input: $input, condition: $condition) {
    id
    name
    discounts {
      nextToken
      __typename
    }
    groups {
      nextToken
      __typename
    }
    createdAt
    updatedAt
    version
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteDiscountPackageMutationVariables,
  APITypes.DeleteDiscountPackageMutation
>;
export const createMasterSwitch = /* GraphQL */ `mutation CreateMasterSwitch(
  $input: CreateMasterSwitchInput!
  $condition: ModelMasterSwitchConditionInput
) {
  createMasterSwitch(input: $input, condition: $condition) {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateMasterSwitchMutationVariables,
  APITypes.CreateMasterSwitchMutation
>;
export const updateMasterSwitch = /* GraphQL */ `mutation UpdateMasterSwitch(
  $input: UpdateMasterSwitchInput!
  $condition: ModelMasterSwitchConditionInput
) {
  updateMasterSwitch(input: $input, condition: $condition) {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateMasterSwitchMutationVariables,
  APITypes.UpdateMasterSwitchMutation
>;
export const deleteMasterSwitch = /* GraphQL */ `mutation DeleteMasterSwitch(
  $input: DeleteMasterSwitchInput!
  $condition: ModelMasterSwitchConditionInput
) {
  deleteMasterSwitch(input: $input, condition: $condition) {
    key
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteMasterSwitchMutationVariables,
  APITypes.DeleteMasterSwitchMutation
>;
