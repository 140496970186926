import { IconButton, Tooltip } from '@material-ui/core';
import { Edit, Delete } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { DiscountPackage, useMaxTableBodyHeight } from '../core';
import { DiscountDelete } from './DiscountDelete';
import { DiscountForm } from './DiscountForm';
import { GroupChip } from './GroupChip';
import { withModal } from './withModal';

interface Props {}

export const DiscountsTable: FC<Props> = withModal(({ openModal }) => {
  const { discountPackages } = useContext(StateContext);

  const columns: Column<DiscountPackage & { groupNames: string[] }>[] = [
    { title: 'Name', field: 'name' },
    {
      title: 'Groups',
      field: 'groupNames',
      render: (discountPackage) =>
        discountPackage.groupNames.map((groupName, idx) => <GroupChip key={idx} groupName={groupName} />),
    },
  ];

  return (
    <MaterialTable
      title="Discount Packages"
      columns={columns.map((c) => ({ searchable: true, disableClick: true, ...c }))}
      data={
        (discountPackages || []).map((discountPackage) => ({
          ...discountPackage,
          groupNames: discountPackage.groups?.items.map((item) => item.groupName) || [],
        })) || []
      }
      onRowClick={() => {}}
      options={{
        paging: false,
        minBodyHeight: useMaxTableBodyHeight(),
        maxBodyHeight: useMaxTableBodyHeight(),
        padding: 'default',
      }}
      actions={[
        {
          icon: Edit,
          iconProps: { color: 'primary' },
          tooltip: 'edit',
          onClick: (_event, rowData) => openModal(<DiscountForm existingData={rowData as DiscountPackage} />, 650),
        },
        {
          icon: Delete,
          iconProps: { color: 'secondary' },
          tooltip: 'delete',
          onClick: (_event, rowData) => openModal(<DiscountDelete data={rowData as DiscountPackage} />),
        },
      ]}
      components={{
        Action: (props) => {
          const action = props.action.action ? props.action.action(props.data) : props.action;
          return (
            <Tooltip title={action.tooltip} arrow>
              <span>
                <IconButton
                  color={action.iconProps.color}
                  onClick={(event) => action.onClick(event, props.data)}
                  disabled={action.disabled}
                >
                  <action.icon />
                </IconButton>
              </span>
            </Tooltip>
          );
        },
      }}
    />
  );
});
