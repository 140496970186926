import { Box, Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import React, { FC, useContext, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';

import { StateContext } from '../context';
import { AccessRole, ApiService, Group, ID, SearchService } from '../core';
import { Form } from './Form';
import { InputText } from './InputText';
import { withModal } from './withModal';
import { InputSelect } from './InputSelect';

interface DataGroupForm {
  GroupName: string;
  ManagedBy: string | null | undefined;
  Notes: string;
}

interface Props {
  existingData?: Group;
}

export const GroupForm: FC<Props> = withModal(({ closeModal, existingData }) => {
  const { withLoading, updateGroups, updateRegistrations, groups, user, registrations } = useContext(StateContext);
  const form = useForm<DataGroupForm>();
  const { enqueueSnackbar } = useSnackbar();
  const [duplicates, setDuplicates] = useState<string[]>([]);
  const [groupName, setGroupName] = useState<string>(existingData?.groupName || '');
  const [otherGroups] = useState(existingData ? groups?.filter((x) => x.id !== existingData.id) : groups);

  useEffect(() => {
    setDuplicates(
      groupName.length > 0
        ? SearchService.fuzzyFind(
            groupName,
            (otherGroups || []).map((group) => group.groupName)
          ).map((result) => result.item)
        : []
    );
  }, [groupName, otherGroups]);

  const onSubmit = form.handleSubmit(
    withLoading<[DataGroupForm]>(async (data) => {
      try {
        const mappedData = {
          groupName: data.GroupName.trim(),
          groupManagedById: data.ManagedBy
            ? (registrations || [])
                .filter((x) => !!x.accessRole)
                .find((x) => `${x.firstName} ${x.lastName}` === data.ManagedBy)?.id
            : existingData?.managedBy?.id ?? user?.id,
          notes: data.Notes,
        };
        if (existingData) {
          await ApiService.updateGroup({
            input: { ...mappedData, id: existingData.id, expectedVersion: existingData.version },
          });
        } else {
          await ApiService.createGroup({ input: { ...mappedData } });
        }
        updateGroups();
        updateRegistrations();
        closeModal();
        enqueueSnackbar(`Successfully ${existingData ? 'updated' : 'created new'} group ${data.GroupName}`, {
          variant: 'success',
        });
      } catch (error) {
        enqueueSnackbar(`Failed to ${existingData ? 'update' : 'create new'} group.`, { variant: 'error' });
      }
    }),
    (errors) => {
      Object.entries(errors).forEach((item) => enqueueSnackbar(item[1]?.message, { variant: 'error' }));
    }
  );

  return (
    <>
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Typography variant="overline" color="primary">
          {existingData ? 'Edit group' : 'Add new group'}
        </Typography>
        <Typography variant="caption">* = required</Typography>
      </Box>
      <Box height="10px" />
      <Form form={form} onSubmit={onSubmit}>
        <Box display="flex" flexGrow={1} margin="2px">
          <InputText
            id={ID.Form_Group_Name}
            name="GroupName"
            defaultValue={existingData?.groupName}
            rules={{
              required: true,
              validate: (name) => {
                name = name.trim();
                return !otherGroups?.map((group) => group.groupName).includes(name) || `Group "${name}" already exists`;
              },
            }}
            autoFocus={!existingData}
            onChange={(event) => setGroupName(event.target.value)}
          />
        </Box>
        <Box display="flex" margin="5px">
          <Typography variant="body2">Similar:&nbsp;</Typography>
          <Typography variant="body2" color="secondary">
            {duplicates.join(', ')}
          </Typography>
        </Box>
        {user?.accessRole === AccessRole.Admin && (
          <Box display="flex" margin="2px">
            <InputSelect
              name="ManagedBy"
              defaultValue={
                existingData?.managedBy
                  ? `${existingData.managedBy.firstName} ${existingData.managedBy.lastName}`
                  : `${user.firstName} ${user.lastName}`
              }
              options={(registrations || []).filter((x) => !!x.accessRole).map((x) => x.firstName + ' ' + x.lastName)}
              rules={{ required: true }}
            />
          </Box>
        )}
        <Box display="flex" margin="2px">
          <InputText name="Notes" defaultValue={existingData?.notes} />
        </Box>
        <Box display="flex" marginTop="20px" justifyContent="center">
          <Button
            id={ID.Form_Group_Submit}
            type="submit"
            color="primary"
            variant="contained"
            style={{ margin: '0.5rem' }}
          >
            Submit
          </Button>
          <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
            Cancel
          </Button>
        </Box>
      </Form>
    </>
  );
});
