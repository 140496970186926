import { Box, Tooltip } from '@material-ui/core';
import React, { FC } from 'react';
import { ConferenceGrouping, ConferenceItem, EMOJI2, REGISTRATION_GROUPING, User } from '../core';

interface Props {
  grouping: ConferenceGrouping;
  user: User;
}

export const RegistrationDayEmojis: FC<Props> = ({ grouping, user }) => {
  return (
    <Box display="flex" margin="0px -5px">
      {REGISTRATION_GROUPING[grouping]
        .filter((element) =>
          grouping === ConferenceItem.Reception
            ? user[`${element.toLowerCase()}${grouping}` as keyof User]
            : user[`${grouping.toLowerCase()}${element}` as keyof User]
        )
        .map((element, idx) => (
          <Tooltip title={element} arrow key={idx}>
            <span role="img" aria-label={element} style={{ fontSize: 25, margin: '0px 5px', cursor: 'default' }}>
              {EMOJI2[element]}
            </span>
          </Tooltip>
        ))}
    </Box>
  );
};
