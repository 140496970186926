import { Autocomplete, AutocompleteProps, AutocompleteRenderInputParams } from '@material-ui/lab';
import React, { FC, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { InputText, InputTextProps } from './InputText';

export interface SelectOptionComplex {
  value: string;
  inputValue: string;
}

type Props = Omit<InputTextProps, 'phone'> &
  Omit<AutocompleteProps<SelectOptionComplex, true, false, false>, 'multiple' | 'renderInput'>;

export const InputSelectMultipleComplex: FC<Props> = (props) => {
  const { register, setValue } = useFormContext();
  const { autoComplete, defaultValue, fullWidth = true, label, name = '', options, rules, variant, ...rest } = props;

  useEffect(() => {
    register(name);
    setValue(name, defaultValue || []);
  }, [register, setValue, name, defaultValue]);

  return (
    <Autocomplete
      multiple
      autoComplete
      autoSelect
      fullWidth={fullWidth}
      openOnFocus
      defaultValue={defaultValue}
      options={options}
      renderInput={(params: AutocompleteRenderInputParams) => (
        <InputText {...params} name={name} label={label} variant={variant} rules={rules} inputRef={() => {}} />
      )}
      onChange={(_event, options) => setValue(name, options)}
      getOptionLabel={(option) => option.value}
      getOptionSelected={(option, value) => option.inputValue === value.inputValue}
      {...rest}
    />
  );
};
