import {
  DpoXmlData,
  DpoJsonGift,
  DpoXmlRecord,
  DpoXmlField,
  DpoXmlAttributes,
  DpoRegistration,
  DpoIndividual,
  DpoCsvData,
} from './donor-perfect.types';

/**
 * Data Mapping
 */

const MAP_REGISTRATION: { [key: string]: keyof DpoRegistration } = {
  FRI_SPEAK_ONLY: 'fridaySpeakers',
  FRI_BREAKFAST: 'fridayBreakfast',
  FRI_LUNCH: 'fridayLunch',
  FRI_DINNER: 'fridayDinner',
  SAT_SPEAK_ONLY: 'saturdaySpeakers',
  SAT_BREAKFAST: 'saturdayBreakfast',
  SAT_LUNCH: 'saturdayLunch',
  SAT_DINNER: 'saturdayDinner',
};

/**
 * API transform
 */

export function transformDpoApiData(data: DpoXmlData): DpoJsonGift[] {
  return data.result.record.map(mapRecord);
}

function mapRecord(record: DpoXmlRecord): DpoJsonGift {
  return record.field.reduce((p, c) => ({ ...p, ...mapField(c) }), {} as DpoJsonGift);
}

function mapField(field: DpoXmlField): { [key: string]: string } {
  return mapAttributes(field.$);
}

function mapAttributes(attributes: DpoXmlAttributes): { [key: string]: string } {
  return { [attributes.id]: attributes.value };
}

/**
 * API merge
 */

export function mergeDpoApiData(gifts: DpoJsonGift[]): DpoIndividual[] {
  const individuals: DpoIndividual[] = [];
  for (const gift of gifts) {
    if (gift.gl_code !== 'SYM-TICK') continue;
    const idx = individuals.findIndex((i) => i.attendee_id === gift.donor_id);
    if (idx >= 0) {
      const item = MAP_REGISTRATION[gift.sub_solicit_code];
      individuals[idx].registration[item] = true;
    } else {
      const newIndividual = createIndividual(gift);
      individuals.push(newIndividual);
    }
  }
  return individuals;
}

function createIndividual(gift: DpoJsonGift): DpoIndividual {
  return {
    attendee_id: gift.donor_id,
    first_name: `${gift.first_name}`.trim(),
    last_name: `${gift.last_name} ${gift.suffix}`.trim(),
    phone: gift.mobile_phone || gift.home_phone || gift.business_phone || '',
    email: gift.email || '',
    registration: {
      ...createEmptyRegistration(),
      [MAP_REGISTRATION[gift.gl_code]]: true,
    },
  };
}

function createEmptyRegistration(): DpoRegistration {
  return {
    fridaySpeakers: false,
    fridayBreakfast: false,
    fridayLunch: false,
    fridayDinner: false,
    saturdaySpeakers: false,
    saturdayBreakfast: false,
    saturdayLunch: false,
    saturdayDinner: false,
  };
}

/**
 * CSV merge
 */

export function mergeDpoCsvData(rows: DpoCsvData[]): DpoCsvData[] {
  return rows.reduce((p, c) => {
    const idx = p.findIndex((r) => r.EntryId === c.EntryId);
    if (idx < 0) return [...p, c];
    p[idx].sub_solicit_code += `,${c.sub_solicit_code}`;
    return p;
  }, [] as DpoCsvData[]);
}
