import { Box, Button } from '@material-ui/core';
import { AddRounded } from '@material-ui/icons';
import React, { FC, useContext } from 'react';
import { Route, Switch } from 'react-router-dom';
import { StateContext } from '../context';
import { AccessRole, ID } from '../core';
import { GroupForm } from './GroupForm';
import { GroupRegistration } from './GroupRegistration';
import { GroupsTable } from './GroupsTable';
import { withModal } from './withModal';

interface Props {
  isGroupRegistrar?: boolean;
}

export const Groups: FC<Props> = withModal(({ isGroupRegistrar = false, openModal }) => {
  const { user } = useContext(StateContext);
  return (
    <Switch>
      <Route path="/groups/:id">
        <GroupRegistration isGroupRegistrar={isGroupRegistrar} />
      </Route>
      <Route path="/groups">
        <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
          <Box width="100%" maxWidth="1600px">
            {user?.accessRole !== AccessRole.GroupLeader && (
              <Button
                id={ID.Page_Groups_Add}
                color="primary"
                variant="outlined"
                startIcon={<AddRounded />}
                onClick={() => openModal(<GroupForm />)}
                style={{ margin: '0.5rem' }}
              >
                Add New Group
              </Button>
            )}
            <GroupsTable isGroupRegistrar={isGroupRegistrar} />
          </Box>
        </Box>
      </Route>
    </Switch>
  );
});
