import { IconButton, Tooltip } from '@material-ui/core';
import { Delete, Edit, Visibility } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, memo, useContext } from 'react';
import { useHistory } from 'react-router-dom';
import { StateContext } from '../context';
import { AccessRole, Group, useMaxTableBodyHeight } from '../core';
import { GroupDelete } from './GroupDelete';
import { GroupForm } from './GroupForm';
import { withModal } from './withModal';

interface Props {
  isGroupRegistrar?: boolean;
}

export const GroupsTable: FC<Props> = withModal(
  memo(({ isGroupRegistrar, openModal }) => {
    const { user, groups } = useContext(StateContext);
    const history = useHistory();

    const columns: Column<Group & { count: number; discountPackageName: string; managedByName: string }>[] = [
      { title: 'Group Name', field: 'groupName' },
      { title: 'Count', field: 'count' },
      { title: 'Managed By', field: 'managedByName', hidden: isGroupRegistrar },
      { title: 'Discount Package', field: 'discountPackageName' },
      { title: 'Notes', field: 'notes', hidden: isGroupRegistrar },
    ];

    return (
      <MaterialTable
        title="Groups"
        columns={columns.map((c) => ({ searchable: true, disableClick: true, ...c }))}
        data={(isGroupRegistrar ? user?.groupsRegistrar?.items.map((item) => item.group) || [] : groups || []).map(
          (group) => ({
            ...group,
            count: group.users?.items.length || 0,
            discountPackageName: group.discountPackage?.name || '',
            managedByName: group.managedBy ? `${group.managedBy.firstName} ${group.managedBy.lastName}` : '',
          })
        )}
        onRowClick={() => {}}
        options={{
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          minBodyHeight: useMaxTableBodyHeight(),
          maxBodyHeight: useMaxTableBodyHeight(),
          padding: 'dense',
        }}
        actions={[
          {
            icon: Edit,
            iconProps: { color: 'primary' },
            tooltip: 'edit',
            onClick: (_event, rowData) => openModal(<GroupForm existingData={rowData as Group} />, 550),
            disabled: user?.accessRole === AccessRole.GroupLeader,
          },
          (rowData) => ({
            icon: Delete,
            iconProps: { color: 'secondary' },
            tooltip: 'delete',
            onClick: (_event, rowData) => openModal(<GroupDelete data={rowData as Group} />),
            disabled:
              user?.accessRole === AccessRole.GroupLeader ||
              (!!rowData.registrars && rowData.registrars.items.length > 0),
          }),
          {
            icon: Visibility,
            iconProps: { color: 'primary' },
            tooltip: 'view',
            onClick: (_event, rowData) => history.push(`/groups/${(rowData as Group).id}`),
          },
        ]}
        components={{
          Action: (props) => {
            const action = props.action.action ? props.action.action(props.data) : props.action;
            return (
              <Tooltip title={action.tooltip} arrow>
                <span>
                  <IconButton
                    color={action.iconProps.color}
                    onClick={(event) => action.onClick(event, props.data)}
                    disabled={action.disabled}
                  >
                    <action.icon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        }}
      />
    );
  })
);
