import React, { FC, memo } from 'react';
import { Backdrop } from '@material-ui/core';

import './LoadingBackdrop.css';

interface Props {
  open: boolean;
}

export const LoadingBackdrop: FC<Props> = memo((props) => {
  return (
    <Backdrop style={{ zIndex: 1375, color: '#fff' }} open={props.open}>
      <div className="lds-roller">
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
        <div></div>
      </div>
    </Backdrop>
  );
});
