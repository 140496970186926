import { Box, FormControlLabel, Switch, Typography } from '@material-ui/core';
import React, { FC, useContext, useEffect, useState } from 'react';
import { StateContext } from '../context';
import { ApiService, MasterSwitchItem, MASTER_SWITCHES } from '../core';

interface Props {}

export const MasterSwitches: FC<Props> = (props) => {
  const { masterSwitchs, updateMasterSwitchs } = useContext(StateContext);
  const [state, setState] = useState<MasterSwitchItem[]>(masterSwitchs?.map((x) => x.key as MasterSwitchItem) || []);

  useEffect(() => {
    setState((prev) => masterSwitchs?.map((x) => x.key as MasterSwitchItem) || prev);
  }, [masterSwitchs]);

  return (
    <Box display="flex" flexDirection="column" alignItems="center" padding="80px 16px 16px">
      <Box width="100%" maxWidth="1000px" display="flex" flexDirection="column" alignItems="center">
        <Typography variant="h5">Master Switches</Typography>
        <Box height="16px" />
        {!!masterSwitchs &&
          MASTER_SWITCHES.map((masterSwitchKey) => (
            <FormControlLabel
              id={`masterSwitch${masterSwitchKey}`}
              key={`masterSwitch${masterSwitchKey}`}
              label={`${masterSwitchKey.replace(/([A-Z])/g, ' $1').trim()}`}
              labelPlacement="start"
              control={
                <Switch
                  color="primary"
                  onChange={async (e) => {
                    setState((prev) =>
                      prev.includes(masterSwitchKey)
                        ? prev.filter((p) => p !== masterSwitchKey)
                        : [...prev, masterSwitchKey]
                    );
                    const crud = masterSwitchs.map((x) => x.key).includes(masterSwitchKey) ? 'delete' : 'create';
                    await ApiService[`${crud}MasterSwitch`]({ input: { key: masterSwitchKey } });
                    updateMasterSwitchs();
                  }}
                  checked={state.includes(masterSwitchKey)}
                />
              }
            />
          ))}
      </Box>
    </Box>
  );
};
