import React, { FC } from 'react';
import { FormProvider, UseFormMethods } from 'react-hook-form';

interface Props {
  form: UseFormMethods<any>;
  onSubmit: (event: React.FormEvent<HTMLFormElement>) => void;
}

export const Form: FC<Props> = ({ form, onSubmit, children }) => {
  return (
    <FormProvider {...form}>
      <form
        onSubmit={onSubmit}
        onKeyDown={(e) => {
          if (e.key === 'Enter') e.preventDefault();
        }}
      >
        {children}
      </form>
    </FormProvider>
  );
};
