import React, { FC } from 'react';
import { Modal as MaterialModal, ModalProps as MaterialModalProps, makeStyles, createStyles } from '@material-ui/core';

const useStyles = makeStyles((theme) =>
  createStyles({
    paper: (props: any) => ({
      position: 'absolute',
      width: props.width,
      maxWidth: '60vw',
      maxHeight: '75%',
      overflowY: 'scroll',
      backgroundColor: theme.palette.background.paper,
      border: `2px solid ${theme.palette.primary.main}`,
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3),
    }),
  })
);

interface ModalProps {
  width: number;
}

export const Modal: FC<MaterialModalProps & ModalProps> = (props) => {
  const style = {
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
  } as React.CSSProperties;
  const classes = useStyles({ width: props.width });

  return (
    <MaterialModal disableBackdropClick {...props}>
      <div style={style} className={classes.paper}>
        {props.children}
      </div>
    </MaterialModal>
  );
};
