export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  AWSDateTime: any;
};

export type AppPermission = {
  __typename?: 'AppPermission';
  accessEligible?: Maybe<Array<Scalars['String']>>;
  accessEnabled?: Maybe<Array<Scalars['String']>>;
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  permissionName: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  users?: Maybe<ModelUserPermissionConnection>;
  version: Scalars['Int'];
};


export type AppPermissionUsersArgs = {
  filter?: InputMaybe<ModelUserPermissionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userId?: InputMaybe<ModelIdKeyConditionInput>;
};

export type BadgeDownload = {
  __typename?: 'BadgeDownload';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  day: Scalars['String'];
  id: Scalars['ID'];
  printed: Scalars['Boolean'];
  type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  usersRemove: Scalars['String'];
  usersUpdate: Scalars['String'];
};

export type CreateAppPermissionInput = {
  accessEligible?: InputMaybe<Array<Scalars['String']>>;
  accessEnabled?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  permissionName: Scalars['String'];
};

export type CreateBadgeDownloadInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  day: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  printed: Scalars['Boolean'];
  type: Scalars['String'];
  usersRemove: Scalars['String'];
  usersUpdate: Scalars['String'];
};

export type CreateDiscountInput = {
  creditAmount?: InputMaybe<Scalars['Int']>;
  discountCount?: InputMaybe<Scalars['Int']>;
  discountItem?: InputMaybe<Scalars['String']>;
  discountRate?: InputMaybe<Scalars['Int']>;
  id?: InputMaybe<Scalars['ID']>;
  packageId?: InputMaybe<Scalars['ID']>;
  priority: Scalars['Int'];
  type: Scalars['String'];
};

export type CreateDiscountPackageInput = {
  id?: InputMaybe<Scalars['ID']>;
  name: Scalars['String'];
};

export type CreateErrorHistoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  id?: InputMaybe<Scalars['ID']>;
  message?: InputMaybe<Scalars['String']>;
  user: Scalars['String'];
};

export type CreateGroupInput = {
  discountPackageId?: InputMaybe<Scalars['ID']>;
  groupManagedById?: InputMaybe<Scalars['ID']>;
  groupName: Scalars['String'];
  id?: InputMaybe<Scalars['ID']>;
  notes?: InputMaybe<Scalars['String']>;
};

export type CreateMasterSwitchInput = {
  key: Scalars['String'];
};

export type CreateRegistrationHistoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email?: InputMaybe<Scalars['String']>;
  event: Scalars['String'];
  firstName: Scalars['String'];
  fridayBreakfast?: InputMaybe<Scalars['Boolean']>;
  fridayDinner?: InputMaybe<Scalars['Boolean']>;
  fridayLunch?: InputMaybe<Scalars['Boolean']>;
  fridayReception?: InputMaybe<Scalars['Boolean']>;
  fridaySpeakers?: InputMaybe<Scalars['Boolean']>;
  fridayWorkshop?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registrationId: Scalars['String'];
  role?: InputMaybe<Scalars['String']>;
  saturdayBreakfast?: InputMaybe<Scalars['Boolean']>;
  saturdayDinner?: InputMaybe<Scalars['Boolean']>;
  saturdayLunch?: InputMaybe<Scalars['Boolean']>;
  saturdaySpeakers?: InputMaybe<Scalars['Boolean']>;
  thursdayReception?: InputMaybe<Scalars['Boolean']>;
  user: Scalars['String'];
};

export type CreateRoleInput = {
  id?: InputMaybe<Scalars['ID']>;
  roleName: Scalars['String'];
};

export type CreateUserGroupInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateUserGroupRegistrarInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type CreateUserInput = {
  accessRole?: InputMaybe<Scalars['String']>;
  cognitoId?: InputMaybe<Scalars['String']>;
  donorPerfectId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  firstName: Scalars['String'];
  fridayBreakfast?: InputMaybe<Scalars['Boolean']>;
  fridayDinner?: InputMaybe<Scalars['Boolean']>;
  fridayLunch?: InputMaybe<Scalars['Boolean']>;
  fridayReception?: InputMaybe<Scalars['Boolean']>;
  fridaySpeakers?: InputMaybe<Scalars['Boolean']>;
  fridayWorkshop?: InputMaybe<Scalars['Boolean']>;
  id?: InputMaybe<Scalars['ID']>;
  lastName: Scalars['String'];
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  saturdayBreakfast?: InputMaybe<Scalars['Boolean']>;
  saturdayDinner?: InputMaybe<Scalars['Boolean']>;
  saturdayLunch?: InputMaybe<Scalars['Boolean']>;
  saturdaySpeakers?: InputMaybe<Scalars['Boolean']>;
  thursdayReception?: InputMaybe<Scalars['Boolean']>;
  userAccessGrantedById?: InputMaybe<Scalars['ID']>;
  userRoleId?: InputMaybe<Scalars['ID']>;
};

export type CreateUserPermissionInput = {
  permissionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteAppPermissionInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteBadgeDownloadInput = {
  id: Scalars['ID'];
};

export type DeleteDiscountInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteDiscountPackageInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteErrorHistoryInput = {
  id: Scalars['ID'];
};

export type DeleteGroupInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteMasterSwitchInput = {
  key: Scalars['String'];
};

export type DeleteRegistrationHistoryInput = {
  id: Scalars['ID'];
};

export type DeleteRoleInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteUserGroupInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteUserGroupRegistrarInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeleteUserInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
};

export type DeleteUserPermissionInput = {
  permissionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type DeveloperCreds = {
  __typename?: 'DeveloperCreds';
  accessKeyId: Scalars['String'];
  createdAt: Scalars['AWSDateTime'];
  name: Scalars['String'];
  region: Scalars['String'];
  secretAccessKey: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  userPoolId?: Maybe<Scalars['String']>;
};

export type Discount = {
  __typename?: 'Discount';
  createdAt: Scalars['AWSDateTime'];
  creditAmount?: Maybe<Scalars['Int']>;
  discountCount?: Maybe<Scalars['Int']>;
  discountItem?: Maybe<Scalars['String']>;
  discountRate?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  package?: Maybe<DiscountPackage>;
  packageId?: Maybe<Scalars['ID']>;
  priority: Scalars['Int'];
  type: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  version: Scalars['Int'];
};

export type DiscountPackage = {
  __typename?: 'DiscountPackage';
  createdAt: Scalars['AWSDateTime'];
  discounts?: Maybe<ModelDiscountConnection>;
  groups?: Maybe<ModelGroupConnection>;
  id: Scalars['ID'];
  name: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  version: Scalars['Int'];
};


export type DiscountPackageDiscountsArgs = {
  filter?: InputMaybe<ModelDiscountFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type DiscountPackageGroupsArgs = {
  filter?: InputMaybe<ModelGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type ErrorHistory = {
  __typename?: 'ErrorHistory';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  message?: Maybe<Scalars['String']>;
  updatedAt: Scalars['AWSDateTime'];
  user: Scalars['String'];
};

export type Group = {
  __typename?: 'Group';
  createdAt: Scalars['AWSDateTime'];
  discountPackage?: Maybe<DiscountPackage>;
  discountPackageId?: Maybe<Scalars['ID']>;
  groupName: Scalars['String'];
  id: Scalars['ID'];
  managedBy?: Maybe<User>;
  notes?: Maybe<Scalars['String']>;
  registrars?: Maybe<ModelUserGroupRegistrarConnection>;
  updatedAt: Scalars['AWSDateTime'];
  users?: Maybe<ModelUserGroupConnection>;
  version: Scalars['Int'];
};


export type GroupRegistrarsArgs = {
  filter?: InputMaybe<ModelUserGroupRegistrarFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userId?: InputMaybe<ModelIdKeyConditionInput>;
};


export type GroupUsersArgs = {
  filter?: InputMaybe<ModelUserGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
  userId?: InputMaybe<ModelIdKeyConditionInput>;
};

export type MasterSwitch = {
  __typename?: 'MasterSwitch';
  createdAt: Scalars['AWSDateTime'];
  key: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
};

export type ModelAppPermissionConditionInput = {
  accessEligible?: InputMaybe<ModelStringInput>;
  accessEnabled?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAppPermissionConditionInput>>>;
  not?: InputMaybe<ModelAppPermissionConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAppPermissionConditionInput>>>;
  permissionName?: InputMaybe<ModelStringInput>;
};

export type ModelAppPermissionConnection = {
  __typename?: 'ModelAppPermissionConnection';
  items: Array<Maybe<AppPermission>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelAppPermissionFilterInput = {
  accessEligible?: InputMaybe<ModelStringInput>;
  accessEnabled?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelAppPermissionFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelAppPermissionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelAppPermissionFilterInput>>>;
  permissionName?: InputMaybe<ModelStringInput>;
};

export enum ModelAttributeTypes {
  Null = '_null',
  Binary = 'binary',
  BinarySet = 'binarySet',
  Bool = 'bool',
  List = 'list',
  Map = 'map',
  Number = 'number',
  NumberSet = 'numberSet',
  String = 'string',
  StringSet = 'stringSet'
}

export type ModelBadgeDownloadConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelBadgeDownloadConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  day?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelBadgeDownloadConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelBadgeDownloadConditionInput>>>;
  printed?: InputMaybe<ModelBooleanInput>;
  type?: InputMaybe<ModelStringInput>;
  usersRemove?: InputMaybe<ModelStringInput>;
  usersUpdate?: InputMaybe<ModelStringInput>;
};

export type ModelBadgeDownloadConnection = {
  __typename?: 'ModelBadgeDownloadConnection';
  items: Array<Maybe<BadgeDownload>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelBadgeDownloadFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelBadgeDownloadFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  day?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelBadgeDownloadFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelBadgeDownloadFilterInput>>>;
  printed?: InputMaybe<ModelBooleanInput>;
  type?: InputMaybe<ModelStringInput>;
  usersRemove?: InputMaybe<ModelStringInput>;
  usersUpdate?: InputMaybe<ModelStringInput>;
};

export type ModelBooleanInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  eq?: InputMaybe<Scalars['Boolean']>;
  ne?: InputMaybe<Scalars['Boolean']>;
};

export type ModelDeveloperCredsConnection = {
  __typename?: 'ModelDeveloperCredsConnection';
  items: Array<Maybe<DeveloperCreds>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDeveloperCredsFilterInput = {
  accessKeyId?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelDeveloperCredsFilterInput>>>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelDeveloperCredsFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDeveloperCredsFilterInput>>>;
  region?: InputMaybe<ModelStringInput>;
  secretAccessKey?: InputMaybe<ModelStringInput>;
  userPoolId?: InputMaybe<ModelStringInput>;
};

export type ModelDiscountConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDiscountConditionInput>>>;
  creditAmount?: InputMaybe<ModelIntInput>;
  discountCount?: InputMaybe<ModelIntInput>;
  discountItem?: InputMaybe<ModelStringInput>;
  discountRate?: InputMaybe<ModelIntInput>;
  not?: InputMaybe<ModelDiscountConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDiscountConditionInput>>>;
  packageId?: InputMaybe<ModelIdInput>;
  priority?: InputMaybe<ModelIntInput>;
  type?: InputMaybe<ModelStringInput>;
};

export type ModelDiscountConnection = {
  __typename?: 'ModelDiscountConnection';
  items: Array<Maybe<Discount>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDiscountFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDiscountFilterInput>>>;
  creditAmount?: InputMaybe<ModelIntInput>;
  discountCount?: InputMaybe<ModelIntInput>;
  discountItem?: InputMaybe<ModelStringInput>;
  discountRate?: InputMaybe<ModelIntInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelDiscountFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDiscountFilterInput>>>;
  packageId?: InputMaybe<ModelIdInput>;
  priority?: InputMaybe<ModelIntInput>;
  type?: InputMaybe<ModelStringInput>;
};

export type ModelDiscountPackageConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDiscountPackageConditionInput>>>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelDiscountPackageConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDiscountPackageConditionInput>>>;
};

export type ModelDiscountPackageConnection = {
  __typename?: 'ModelDiscountPackageConnection';
  items: Array<Maybe<DiscountPackage>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelDiscountPackageFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelDiscountPackageFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  name?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelDiscountPackageFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelDiscountPackageFilterInput>>>;
};

export type ModelErrorHistoryConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelErrorHistoryConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelErrorHistoryConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelErrorHistoryConditionInput>>>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelErrorHistoryConnection = {
  __typename?: 'ModelErrorHistoryConnection';
  items: Array<Maybe<ErrorHistory>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelErrorHistoryFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelErrorHistoryFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  message?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelErrorHistoryFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelErrorHistoryFilterInput>>>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelFloatInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Float']>>>;
  eq?: InputMaybe<Scalars['Float']>;
  ge?: InputMaybe<Scalars['Float']>;
  gt?: InputMaybe<Scalars['Float']>;
  le?: InputMaybe<Scalars['Float']>;
  lt?: InputMaybe<Scalars['Float']>;
  ne?: InputMaybe<Scalars['Float']>;
};

export type ModelGroupConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelGroupConditionInput>>>;
  discountPackageId?: InputMaybe<ModelIdInput>;
  groupName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelGroupConditionInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGroupConditionInput>>>;
};

export type ModelGroupConnection = {
  __typename?: 'ModelGroupConnection';
  items: Array<Maybe<Group>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelGroupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelGroupFilterInput>>>;
  discountPackageId?: InputMaybe<ModelIdInput>;
  groupName?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelGroupFilterInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelGroupFilterInput>>>;
};

export type ModelIdInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  contains?: InputMaybe<Scalars['ID']>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
  ne?: InputMaybe<Scalars['ID']>;
  notContains?: InputMaybe<Scalars['ID']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelIdKeyConditionInput = {
  beginsWith?: InputMaybe<Scalars['ID']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  eq?: InputMaybe<Scalars['ID']>;
  ge?: InputMaybe<Scalars['ID']>;
  gt?: InputMaybe<Scalars['ID']>;
  le?: InputMaybe<Scalars['ID']>;
  lt?: InputMaybe<Scalars['ID']>;
};

export type ModelIntInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export type ModelMasterSwitchConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMasterSwitchConditionInput>>>;
  not?: InputMaybe<ModelMasterSwitchConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMasterSwitchConditionInput>>>;
};

export type ModelMasterSwitchConnection = {
  __typename?: 'ModelMasterSwitchConnection';
  items: Array<Maybe<MasterSwitch>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelMasterSwitchFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelMasterSwitchFilterInput>>>;
  key?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelMasterSwitchFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelMasterSwitchFilterInput>>>;
};

export type ModelRegistrationHistoryConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelRegistrationHistoryConditionInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  event?: InputMaybe<ModelStringInput>;
  firstName?: InputMaybe<ModelStringInput>;
  fridayBreakfast?: InputMaybe<ModelBooleanInput>;
  fridayDinner?: InputMaybe<ModelBooleanInput>;
  fridayLunch?: InputMaybe<ModelBooleanInput>;
  fridayReception?: InputMaybe<ModelBooleanInput>;
  fridaySpeakers?: InputMaybe<ModelBooleanInput>;
  fridayWorkshop?: InputMaybe<ModelBooleanInput>;
  groups?: InputMaybe<ModelStringInput>;
  lastName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelRegistrationHistoryConditionInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelRegistrationHistoryConditionInput>>>;
  phone?: InputMaybe<ModelStringInput>;
  registrationId?: InputMaybe<ModelStringInput>;
  role?: InputMaybe<ModelStringInput>;
  saturdayBreakfast?: InputMaybe<ModelBooleanInput>;
  saturdayDinner?: InputMaybe<ModelBooleanInput>;
  saturdayLunch?: InputMaybe<ModelBooleanInput>;
  saturdaySpeakers?: InputMaybe<ModelBooleanInput>;
  thursdayReception?: InputMaybe<ModelBooleanInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelRegistrationHistoryConnection = {
  __typename?: 'ModelRegistrationHistoryConnection';
  items: Array<Maybe<RegistrationHistory>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRegistrationHistoryFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelRegistrationHistoryFilterInput>>>;
  createdAt?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  event?: InputMaybe<ModelStringInput>;
  firstName?: InputMaybe<ModelStringInput>;
  fridayBreakfast?: InputMaybe<ModelBooleanInput>;
  fridayDinner?: InputMaybe<ModelBooleanInput>;
  fridayLunch?: InputMaybe<ModelBooleanInput>;
  fridayReception?: InputMaybe<ModelBooleanInput>;
  fridaySpeakers?: InputMaybe<ModelBooleanInput>;
  fridayWorkshop?: InputMaybe<ModelBooleanInput>;
  groups?: InputMaybe<ModelStringInput>;
  id?: InputMaybe<ModelIdInput>;
  lastName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelRegistrationHistoryFilterInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelRegistrationHistoryFilterInput>>>;
  phone?: InputMaybe<ModelStringInput>;
  registrationId?: InputMaybe<ModelStringInput>;
  role?: InputMaybe<ModelStringInput>;
  saturdayBreakfast?: InputMaybe<ModelBooleanInput>;
  saturdayDinner?: InputMaybe<ModelBooleanInput>;
  saturdayLunch?: InputMaybe<ModelBooleanInput>;
  saturdaySpeakers?: InputMaybe<ModelBooleanInput>;
  thursdayReception?: InputMaybe<ModelBooleanInput>;
  user?: InputMaybe<ModelStringInput>;
};

export type ModelRoleConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelRoleConditionInput>>>;
  not?: InputMaybe<ModelRoleConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelRoleConditionInput>>>;
  roleName?: InputMaybe<ModelStringInput>;
};

export type ModelRoleConnection = {
  __typename?: 'ModelRoleConnection';
  items: Array<Maybe<Role>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelRoleFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelRoleFilterInput>>>;
  id?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelRoleFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelRoleFilterInput>>>;
  roleName?: InputMaybe<ModelStringInput>;
};

export type ModelSizeInput = {
  between?: InputMaybe<Array<InputMaybe<Scalars['Int']>>>;
  eq?: InputMaybe<Scalars['Int']>;
  ge?: InputMaybe<Scalars['Int']>;
  gt?: InputMaybe<Scalars['Int']>;
  le?: InputMaybe<Scalars['Int']>;
  lt?: InputMaybe<Scalars['Int']>;
  ne?: InputMaybe<Scalars['Int']>;
};

export enum ModelSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export type ModelStringInput = {
  attributeExists?: InputMaybe<Scalars['Boolean']>;
  attributeType?: InputMaybe<ModelAttributeTypes>;
  beginsWith?: InputMaybe<Scalars['String']>;
  between?: InputMaybe<Array<InputMaybe<Scalars['String']>>>;
  contains?: InputMaybe<Scalars['String']>;
  eq?: InputMaybe<Scalars['String']>;
  ge?: InputMaybe<Scalars['String']>;
  gt?: InputMaybe<Scalars['String']>;
  le?: InputMaybe<Scalars['String']>;
  lt?: InputMaybe<Scalars['String']>;
  ne?: InputMaybe<Scalars['String']>;
  notContains?: InputMaybe<Scalars['String']>;
  size?: InputMaybe<ModelSizeInput>;
};

export type ModelUserConditionInput = {
  accessRole?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelUserConditionInput>>>;
  cognitoId?: InputMaybe<ModelStringInput>;
  donorPerfectId?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  firstName?: InputMaybe<ModelStringInput>;
  fridayBreakfast?: InputMaybe<ModelBooleanInput>;
  fridayDinner?: InputMaybe<ModelBooleanInput>;
  fridayLunch?: InputMaybe<ModelBooleanInput>;
  fridayReception?: InputMaybe<ModelBooleanInput>;
  fridaySpeakers?: InputMaybe<ModelBooleanInput>;
  fridayWorkshop?: InputMaybe<ModelBooleanInput>;
  lastName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUserConditionInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserConditionInput>>>;
  phone?: InputMaybe<ModelStringInput>;
  saturdayBreakfast?: InputMaybe<ModelBooleanInput>;
  saturdayDinner?: InputMaybe<ModelBooleanInput>;
  saturdayLunch?: InputMaybe<ModelBooleanInput>;
  saturdaySpeakers?: InputMaybe<ModelBooleanInput>;
  thursdayReception?: InputMaybe<ModelBooleanInput>;
  userRoleId?: InputMaybe<ModelIdInput>;
};

export type ModelUserConnection = {
  __typename?: 'ModelUserConnection';
  items: Array<Maybe<User>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserFilterInput = {
  accessRole?: InputMaybe<ModelStringInput>;
  and?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  cognitoId?: InputMaybe<ModelStringInput>;
  donorPerfectId?: InputMaybe<ModelStringInput>;
  email?: InputMaybe<ModelStringInput>;
  firstName?: InputMaybe<ModelStringInput>;
  fridayBreakfast?: InputMaybe<ModelBooleanInput>;
  fridayDinner?: InputMaybe<ModelBooleanInput>;
  fridayLunch?: InputMaybe<ModelBooleanInput>;
  fridayReception?: InputMaybe<ModelBooleanInput>;
  fridaySpeakers?: InputMaybe<ModelBooleanInput>;
  fridayWorkshop?: InputMaybe<ModelBooleanInput>;
  id?: InputMaybe<ModelIdInput>;
  lastName?: InputMaybe<ModelStringInput>;
  not?: InputMaybe<ModelUserFilterInput>;
  notes?: InputMaybe<ModelStringInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserFilterInput>>>;
  phone?: InputMaybe<ModelStringInput>;
  saturdayBreakfast?: InputMaybe<ModelBooleanInput>;
  saturdayDinner?: InputMaybe<ModelBooleanInput>;
  saturdayLunch?: InputMaybe<ModelBooleanInput>;
  saturdaySpeakers?: InputMaybe<ModelBooleanInput>;
  thursdayReception?: InputMaybe<ModelBooleanInput>;
  userRoleId?: InputMaybe<ModelIdInput>;
};

export type ModelUserGroupConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserGroupConditionInput>>>;
  not?: InputMaybe<ModelUserGroupConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserGroupConditionInput>>>;
};

export type ModelUserGroupConnection = {
  __typename?: 'ModelUserGroupConnection';
  items: Array<Maybe<UserGroup>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserGroupFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserGroupFilterInput>>>;
  groupId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUserGroupFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserGroupFilterInput>>>;
  userId?: InputMaybe<ModelIdInput>;
};

export type ModelUserGroupRegistrarConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserGroupRegistrarConditionInput>>>;
  not?: InputMaybe<ModelUserGroupRegistrarConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserGroupRegistrarConditionInput>>>;
};

export type ModelUserGroupRegistrarConnection = {
  __typename?: 'ModelUserGroupRegistrarConnection';
  items: Array<Maybe<UserGroupRegistrar>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserGroupRegistrarFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserGroupRegistrarFilterInput>>>;
  groupId?: InputMaybe<ModelIdInput>;
  not?: InputMaybe<ModelUserGroupRegistrarFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserGroupRegistrarFilterInput>>>;
  userId?: InputMaybe<ModelIdInput>;
};

export type ModelUserPermissionConditionInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserPermissionConditionInput>>>;
  not?: InputMaybe<ModelUserPermissionConditionInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserPermissionConditionInput>>>;
};

export type ModelUserPermissionConnection = {
  __typename?: 'ModelUserPermissionConnection';
  items: Array<Maybe<UserPermission>>;
  nextToken?: Maybe<Scalars['String']>;
};

export type ModelUserPermissionFilterInput = {
  and?: InputMaybe<Array<InputMaybe<ModelUserPermissionFilterInput>>>;
  not?: InputMaybe<ModelUserPermissionFilterInput>;
  or?: InputMaybe<Array<InputMaybe<ModelUserPermissionFilterInput>>>;
  permissionId?: InputMaybe<ModelIdInput>;
  userId?: InputMaybe<ModelIdInput>;
};

export type Mutation = {
  __typename?: 'Mutation';
  createAppPermission?: Maybe<AppPermission>;
  createBadgeDownload?: Maybe<BadgeDownload>;
  createDiscount?: Maybe<Discount>;
  createDiscountPackage?: Maybe<DiscountPackage>;
  createErrorHistory?: Maybe<ErrorHistory>;
  createGroup?: Maybe<Group>;
  createMasterSwitch?: Maybe<MasterSwitch>;
  createRegistrationHistory?: Maybe<RegistrationHistory>;
  createRole?: Maybe<Role>;
  createUser?: Maybe<User>;
  createUserGroup?: Maybe<UserGroup>;
  createUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  createUserPermission?: Maybe<UserPermission>;
  deleteAppPermission?: Maybe<AppPermission>;
  deleteBadgeDownload?: Maybe<BadgeDownload>;
  deleteDiscount?: Maybe<Discount>;
  deleteDiscountPackage?: Maybe<DiscountPackage>;
  deleteErrorHistory?: Maybe<ErrorHistory>;
  deleteGroup?: Maybe<Group>;
  deleteMasterSwitch?: Maybe<MasterSwitch>;
  deleteRegistrationHistory?: Maybe<RegistrationHistory>;
  deleteRole?: Maybe<Role>;
  deleteUser?: Maybe<User>;
  deleteUserGroup?: Maybe<UserGroup>;
  deleteUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  deleteUserPermission?: Maybe<UserPermission>;
  updateAppPermission?: Maybe<AppPermission>;
  updateBadgeDownload?: Maybe<BadgeDownload>;
  updateDiscount?: Maybe<Discount>;
  updateDiscountPackage?: Maybe<DiscountPackage>;
  updateErrorHistory?: Maybe<ErrorHistory>;
  updateGroup?: Maybe<Group>;
  updateMasterSwitch?: Maybe<MasterSwitch>;
  updateRegistrationHistory?: Maybe<RegistrationHistory>;
  updateRole?: Maybe<Role>;
  updateUser?: Maybe<User>;
  updateUserGroup?: Maybe<UserGroup>;
  updateUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  updateUserPermission?: Maybe<UserPermission>;
};


export type MutationCreateAppPermissionArgs = {
  condition?: InputMaybe<ModelAppPermissionConditionInput>;
  input: CreateAppPermissionInput;
};


export type MutationCreateBadgeDownloadArgs = {
  condition?: InputMaybe<ModelBadgeDownloadConditionInput>;
  input: CreateBadgeDownloadInput;
};


export type MutationCreateDiscountArgs = {
  condition?: InputMaybe<ModelDiscountConditionInput>;
  input: CreateDiscountInput;
};


export type MutationCreateDiscountPackageArgs = {
  condition?: InputMaybe<ModelDiscountPackageConditionInput>;
  input: CreateDiscountPackageInput;
};


export type MutationCreateErrorHistoryArgs = {
  condition?: InputMaybe<ModelErrorHistoryConditionInput>;
  input: CreateErrorHistoryInput;
};


export type MutationCreateGroupArgs = {
  condition?: InputMaybe<ModelGroupConditionInput>;
  input: CreateGroupInput;
};


export type MutationCreateMasterSwitchArgs = {
  condition?: InputMaybe<ModelMasterSwitchConditionInput>;
  input: CreateMasterSwitchInput;
};


export type MutationCreateRegistrationHistoryArgs = {
  condition?: InputMaybe<ModelRegistrationHistoryConditionInput>;
  input: CreateRegistrationHistoryInput;
};


export type MutationCreateRoleArgs = {
  condition?: InputMaybe<ModelRoleConditionInput>;
  input: CreateRoleInput;
};


export type MutationCreateUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: CreateUserInput;
};


export type MutationCreateUserGroupArgs = {
  condition?: InputMaybe<ModelUserGroupConditionInput>;
  input: CreateUserGroupInput;
};


export type MutationCreateUserGroupRegistrarArgs = {
  condition?: InputMaybe<ModelUserGroupRegistrarConditionInput>;
  input: CreateUserGroupRegistrarInput;
};


export type MutationCreateUserPermissionArgs = {
  condition?: InputMaybe<ModelUserPermissionConditionInput>;
  input: CreateUserPermissionInput;
};


export type MutationDeleteAppPermissionArgs = {
  condition?: InputMaybe<ModelAppPermissionConditionInput>;
  input: DeleteAppPermissionInput;
};


export type MutationDeleteBadgeDownloadArgs = {
  condition?: InputMaybe<ModelBadgeDownloadConditionInput>;
  input: DeleteBadgeDownloadInput;
};


export type MutationDeleteDiscountArgs = {
  condition?: InputMaybe<ModelDiscountConditionInput>;
  input: DeleteDiscountInput;
};


export type MutationDeleteDiscountPackageArgs = {
  condition?: InputMaybe<ModelDiscountPackageConditionInput>;
  input: DeleteDiscountPackageInput;
};


export type MutationDeleteErrorHistoryArgs = {
  condition?: InputMaybe<ModelErrorHistoryConditionInput>;
  input: DeleteErrorHistoryInput;
};


export type MutationDeleteGroupArgs = {
  condition?: InputMaybe<ModelGroupConditionInput>;
  input: DeleteGroupInput;
};


export type MutationDeleteMasterSwitchArgs = {
  condition?: InputMaybe<ModelMasterSwitchConditionInput>;
  input: DeleteMasterSwitchInput;
};


export type MutationDeleteRegistrationHistoryArgs = {
  condition?: InputMaybe<ModelRegistrationHistoryConditionInput>;
  input: DeleteRegistrationHistoryInput;
};


export type MutationDeleteRoleArgs = {
  condition?: InputMaybe<ModelRoleConditionInput>;
  input: DeleteRoleInput;
};


export type MutationDeleteUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: DeleteUserInput;
};


export type MutationDeleteUserGroupArgs = {
  condition?: InputMaybe<ModelUserGroupConditionInput>;
  input: DeleteUserGroupInput;
};


export type MutationDeleteUserGroupRegistrarArgs = {
  condition?: InputMaybe<ModelUserGroupRegistrarConditionInput>;
  input: DeleteUserGroupRegistrarInput;
};


export type MutationDeleteUserPermissionArgs = {
  condition?: InputMaybe<ModelUserPermissionConditionInput>;
  input: DeleteUserPermissionInput;
};


export type MutationUpdateAppPermissionArgs = {
  condition?: InputMaybe<ModelAppPermissionConditionInput>;
  input: UpdateAppPermissionInput;
};


export type MutationUpdateBadgeDownloadArgs = {
  condition?: InputMaybe<ModelBadgeDownloadConditionInput>;
  input: UpdateBadgeDownloadInput;
};


export type MutationUpdateDiscountArgs = {
  condition?: InputMaybe<ModelDiscountConditionInput>;
  input: UpdateDiscountInput;
};


export type MutationUpdateDiscountPackageArgs = {
  condition?: InputMaybe<ModelDiscountPackageConditionInput>;
  input: UpdateDiscountPackageInput;
};


export type MutationUpdateErrorHistoryArgs = {
  condition?: InputMaybe<ModelErrorHistoryConditionInput>;
  input: UpdateErrorHistoryInput;
};


export type MutationUpdateGroupArgs = {
  condition?: InputMaybe<ModelGroupConditionInput>;
  input: UpdateGroupInput;
};


export type MutationUpdateMasterSwitchArgs = {
  condition?: InputMaybe<ModelMasterSwitchConditionInput>;
  input: UpdateMasterSwitchInput;
};


export type MutationUpdateRegistrationHistoryArgs = {
  condition?: InputMaybe<ModelRegistrationHistoryConditionInput>;
  input: UpdateRegistrationHistoryInput;
};


export type MutationUpdateRoleArgs = {
  condition?: InputMaybe<ModelRoleConditionInput>;
  input: UpdateRoleInput;
};


export type MutationUpdateUserArgs = {
  condition?: InputMaybe<ModelUserConditionInput>;
  input: UpdateUserInput;
};


export type MutationUpdateUserGroupArgs = {
  condition?: InputMaybe<ModelUserGroupConditionInput>;
  input: UpdateUserGroupInput;
};


export type MutationUpdateUserGroupRegistrarArgs = {
  condition?: InputMaybe<ModelUserGroupRegistrarConditionInput>;
  input: UpdateUserGroupRegistrarInput;
};


export type MutationUpdateUserPermissionArgs = {
  condition?: InputMaybe<ModelUserPermissionConditionInput>;
  input: UpdateUserPermissionInput;
};

export type Query = {
  __typename?: 'Query';
  getAppPermission?: Maybe<AppPermission>;
  getBadgeDownload?: Maybe<BadgeDownload>;
  getDeveloperCreds?: Maybe<DeveloperCreds>;
  getDiscount?: Maybe<Discount>;
  getDiscountPackage?: Maybe<DiscountPackage>;
  getDiscountPackageByName?: Maybe<ModelDiscountPackageConnection>;
  getErrorHistory?: Maybe<ErrorHistory>;
  getGroup?: Maybe<Group>;
  getGroupByName?: Maybe<ModelGroupConnection>;
  getMasterSwitch?: Maybe<MasterSwitch>;
  getPermissionByName?: Maybe<ModelAppPermissionConnection>;
  getRegistrationHistory?: Maybe<RegistrationHistory>;
  getRole?: Maybe<Role>;
  getRoleByName?: Maybe<ModelRoleConnection>;
  getUser?: Maybe<User>;
  getUserByCognitoId?: Maybe<ModelUserConnection>;
  getUserByDonorPerfectId?: Maybe<ModelUserConnection>;
  listAppPermissions?: Maybe<ModelAppPermissionConnection>;
  listBadgeDownloads?: Maybe<ModelBadgeDownloadConnection>;
  listDeveloperCredss?: Maybe<ModelDeveloperCredsConnection>;
  listDiscountPackages?: Maybe<ModelDiscountPackageConnection>;
  listDiscounts?: Maybe<ModelDiscountConnection>;
  listErrorHistorys?: Maybe<ModelErrorHistoryConnection>;
  listGroups?: Maybe<ModelGroupConnection>;
  listMasterSwitchs?: Maybe<ModelMasterSwitchConnection>;
  listRegistrationHistorys?: Maybe<ModelRegistrationHistoryConnection>;
  listRoles?: Maybe<ModelRoleConnection>;
  listUsers?: Maybe<ModelUserConnection>;
};


export type QueryGetAppPermissionArgs = {
  id: Scalars['ID'];
};


export type QueryGetBadgeDownloadArgs = {
  id: Scalars['ID'];
};


export type QueryGetDeveloperCredsArgs = {
  name: Scalars['String'];
};


export type QueryGetDiscountArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountPackageArgs = {
  id: Scalars['ID'];
};


export type QueryGetDiscountPackageByNameArgs = {
  filter?: InputMaybe<ModelDiscountPackageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetErrorHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetGroupArgs = {
  id: Scalars['ID'];
};


export type QueryGetGroupByNameArgs = {
  filter?: InputMaybe<ModelGroupFilterInput>;
  groupName?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetMasterSwitchArgs = {
  key: Scalars['String'];
};


export type QueryGetPermissionByNameArgs = {
  filter?: InputMaybe<ModelAppPermissionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  permissionName?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetRegistrationHistoryArgs = {
  id: Scalars['ID'];
};


export type QueryGetRoleArgs = {
  id: Scalars['ID'];
};


export type QueryGetRoleByNameArgs = {
  filter?: InputMaybe<ModelRoleFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  roleName?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetUserArgs = {
  id: Scalars['ID'];
};


export type QueryGetUserByCognitoIdArgs = {
  cognitoId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryGetUserByDonorPerfectIdArgs = {
  donorPerfectId?: InputMaybe<Scalars['String']>;
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryListAppPermissionsArgs = {
  filter?: InputMaybe<ModelAppPermissionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListBadgeDownloadsArgs = {
  filter?: InputMaybe<ModelBadgeDownloadFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListDeveloperCredssArgs = {
  filter?: InputMaybe<ModelDeveloperCredsFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  name?: InputMaybe<Scalars['String']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryListDiscountPackagesArgs = {
  filter?: InputMaybe<ModelDiscountPackageFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListDiscountsArgs = {
  filter?: InputMaybe<ModelDiscountFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListErrorHistorysArgs = {
  filter?: InputMaybe<ModelErrorHistoryFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListGroupsArgs = {
  filter?: InputMaybe<ModelGroupFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListMasterSwitchsArgs = {
  filter?: InputMaybe<ModelMasterSwitchFilterInput>;
  key?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type QueryListRegistrationHistorysArgs = {
  filter?: InputMaybe<ModelRegistrationHistoryFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListRolesArgs = {
  filter?: InputMaybe<ModelRoleFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};


export type QueryListUsersArgs = {
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
};

export type RegistrationHistory = {
  __typename?: 'RegistrationHistory';
  createdAt?: Maybe<Scalars['AWSDateTime']>;
  email?: Maybe<Scalars['String']>;
  event: Scalars['String'];
  firstName: Scalars['String'];
  fridayBreakfast?: Maybe<Scalars['Boolean']>;
  fridayDinner?: Maybe<Scalars['Boolean']>;
  fridayLunch?: Maybe<Scalars['Boolean']>;
  fridayReception?: Maybe<Scalars['Boolean']>;
  fridaySpeakers?: Maybe<Scalars['Boolean']>;
  fridayWorkshop?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  registrationId: Scalars['String'];
  role?: Maybe<Scalars['String']>;
  saturdayBreakfast?: Maybe<Scalars['Boolean']>;
  saturdayDinner?: Maybe<Scalars['Boolean']>;
  saturdayLunch?: Maybe<Scalars['Boolean']>;
  saturdaySpeakers?: Maybe<Scalars['Boolean']>;
  thursdayReception?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['AWSDateTime'];
  user: Scalars['String'];
};

export type Role = {
  __typename?: 'Role';
  createdAt: Scalars['AWSDateTime'];
  id: Scalars['ID'];
  roleName: Scalars['String'];
  updatedAt: Scalars['AWSDateTime'];
  users?: Maybe<ModelUserConnection>;
  version: Scalars['Int'];
};


export type RoleUsersArgs = {
  filter?: InputMaybe<ModelUserFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type Subscription = {
  __typename?: 'Subscription';
  onCreateAppPermission?: Maybe<AppPermission>;
  onCreateBadgeDownload?: Maybe<BadgeDownload>;
  onCreateDiscount?: Maybe<Discount>;
  onCreateDiscountPackage?: Maybe<DiscountPackage>;
  onCreateErrorHistory?: Maybe<ErrorHistory>;
  onCreateGroup?: Maybe<Group>;
  onCreateMasterSwitch?: Maybe<MasterSwitch>;
  onCreateRegistrationHistory?: Maybe<RegistrationHistory>;
  onCreateRole?: Maybe<Role>;
  onCreateUser?: Maybe<User>;
  onCreateUserGroup?: Maybe<UserGroup>;
  onCreateUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  onCreateUserPermission?: Maybe<UserPermission>;
  onDeleteAppPermission?: Maybe<AppPermission>;
  onDeleteBadgeDownload?: Maybe<BadgeDownload>;
  onDeleteDiscount?: Maybe<Discount>;
  onDeleteDiscountPackage?: Maybe<DiscountPackage>;
  onDeleteErrorHistory?: Maybe<ErrorHistory>;
  onDeleteGroup?: Maybe<Group>;
  onDeleteMasterSwitch?: Maybe<MasterSwitch>;
  onDeleteRegistrationHistory?: Maybe<RegistrationHistory>;
  onDeleteRole?: Maybe<Role>;
  onDeleteUser?: Maybe<User>;
  onDeleteUserGroup?: Maybe<UserGroup>;
  onDeleteUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  onDeleteUserPermission?: Maybe<UserPermission>;
  onUpdateAppPermission?: Maybe<AppPermission>;
  onUpdateBadgeDownload?: Maybe<BadgeDownload>;
  onUpdateDiscount?: Maybe<Discount>;
  onUpdateDiscountPackage?: Maybe<DiscountPackage>;
  onUpdateErrorHistory?: Maybe<ErrorHistory>;
  onUpdateGroup?: Maybe<Group>;
  onUpdateMasterSwitch?: Maybe<MasterSwitch>;
  onUpdateRegistrationHistory?: Maybe<RegistrationHistory>;
  onUpdateRole?: Maybe<Role>;
  onUpdateUser?: Maybe<User>;
  onUpdateUserGroup?: Maybe<UserGroup>;
  onUpdateUserGroupRegistrar?: Maybe<UserGroupRegistrar>;
  onUpdateUserPermission?: Maybe<UserPermission>;
};

export type UpdateAppPermissionInput = {
  accessEligible?: InputMaybe<Array<Scalars['String']>>;
  accessEnabled?: InputMaybe<Array<Scalars['String']>>;
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
  permissionName?: InputMaybe<Scalars['String']>;
};

export type UpdateBadgeDownloadInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  day?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  printed?: InputMaybe<Scalars['Boolean']>;
  type?: InputMaybe<Scalars['String']>;
  usersRemove?: InputMaybe<Scalars['String']>;
  usersUpdate?: InputMaybe<Scalars['String']>;
};

export type UpdateDiscountInput = {
  creditAmount?: InputMaybe<Scalars['Int']>;
  discountCount?: InputMaybe<Scalars['Int']>;
  discountItem?: InputMaybe<Scalars['String']>;
  discountRate?: InputMaybe<Scalars['Int']>;
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
  packageId?: InputMaybe<Scalars['ID']>;
  priority?: InputMaybe<Scalars['Int']>;
  type?: InputMaybe<Scalars['String']>;
};

export type UpdateDiscountPackageInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
  name?: InputMaybe<Scalars['String']>;
};

export type UpdateErrorHistoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  id: Scalars['ID'];
  message?: InputMaybe<Scalars['String']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateGroupInput = {
  discountPackageId?: InputMaybe<Scalars['ID']>;
  expectedVersion: Scalars['Int'];
  groupManagedById?: InputMaybe<Scalars['ID']>;
  groupName?: InputMaybe<Scalars['String']>;
  id: Scalars['ID'];
  notes?: InputMaybe<Scalars['String']>;
};

export type UpdateMasterSwitchInput = {
  key: Scalars['String'];
};

export type UpdateRegistrationHistoryInput = {
  createdAt?: InputMaybe<Scalars['AWSDateTime']>;
  email?: InputMaybe<Scalars['String']>;
  event?: InputMaybe<Scalars['String']>;
  firstName?: InputMaybe<Scalars['String']>;
  fridayBreakfast?: InputMaybe<Scalars['Boolean']>;
  fridayDinner?: InputMaybe<Scalars['Boolean']>;
  fridayLunch?: InputMaybe<Scalars['Boolean']>;
  fridayReception?: InputMaybe<Scalars['Boolean']>;
  fridaySpeakers?: InputMaybe<Scalars['Boolean']>;
  fridayWorkshop?: InputMaybe<Scalars['Boolean']>;
  groups?: InputMaybe<Array<Scalars['String']>>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  registrationId?: InputMaybe<Scalars['String']>;
  role?: InputMaybe<Scalars['String']>;
  saturdayBreakfast?: InputMaybe<Scalars['Boolean']>;
  saturdayDinner?: InputMaybe<Scalars['Boolean']>;
  saturdayLunch?: InputMaybe<Scalars['Boolean']>;
  saturdaySpeakers?: InputMaybe<Scalars['Boolean']>;
  thursdayReception?: InputMaybe<Scalars['Boolean']>;
  user?: InputMaybe<Scalars['String']>;
};

export type UpdateRoleInput = {
  expectedVersion: Scalars['Int'];
  id: Scalars['ID'];
  roleName?: InputMaybe<Scalars['String']>;
};

export type UpdateUserGroupInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateUserGroupRegistrarInput = {
  groupId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type UpdateUserInput = {
  accessRole?: InputMaybe<Scalars['String']>;
  cognitoId?: InputMaybe<Scalars['String']>;
  donorPerfectId?: InputMaybe<Scalars['String']>;
  email?: InputMaybe<Scalars['String']>;
  expectedVersion: Scalars['Int'];
  firstName?: InputMaybe<Scalars['String']>;
  fridayBreakfast?: InputMaybe<Scalars['Boolean']>;
  fridayDinner?: InputMaybe<Scalars['Boolean']>;
  fridayLunch?: InputMaybe<Scalars['Boolean']>;
  fridayReception?: InputMaybe<Scalars['Boolean']>;
  fridaySpeakers?: InputMaybe<Scalars['Boolean']>;
  fridayWorkshop?: InputMaybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastName?: InputMaybe<Scalars['String']>;
  notes?: InputMaybe<Scalars['String']>;
  phone?: InputMaybe<Scalars['String']>;
  saturdayBreakfast?: InputMaybe<Scalars['Boolean']>;
  saturdayDinner?: InputMaybe<Scalars['Boolean']>;
  saturdayLunch?: InputMaybe<Scalars['Boolean']>;
  saturdaySpeakers?: InputMaybe<Scalars['Boolean']>;
  thursdayReception?: InputMaybe<Scalars['Boolean']>;
  userAccessGrantedById?: InputMaybe<Scalars['ID']>;
  userRoleId?: InputMaybe<Scalars['ID']>;
};

export type UpdateUserPermissionInput = {
  permissionId: Scalars['ID'];
  userId: Scalars['ID'];
};

export type User = {
  __typename?: 'User';
  accessGrantedBy?: Maybe<User>;
  accessRole?: Maybe<Scalars['String']>;
  cognitoId?: Maybe<Scalars['String']>;
  createdAt: Scalars['AWSDateTime'];
  donorPerfectId?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  firstName: Scalars['String'];
  fridayBreakfast?: Maybe<Scalars['Boolean']>;
  fridayDinner?: Maybe<Scalars['Boolean']>;
  fridayLunch?: Maybe<Scalars['Boolean']>;
  fridayReception?: Maybe<Scalars['Boolean']>;
  fridaySpeakers?: Maybe<Scalars['Boolean']>;
  fridayWorkshop?: Maybe<Scalars['Boolean']>;
  groups?: Maybe<ModelUserGroupConnection>;
  groupsRegistrar?: Maybe<ModelUserGroupRegistrarConnection>;
  id: Scalars['ID'];
  lastName: Scalars['String'];
  notes?: Maybe<Scalars['String']>;
  permissions?: Maybe<ModelUserPermissionConnection>;
  phone?: Maybe<Scalars['String']>;
  role?: Maybe<Role>;
  saturdayBreakfast?: Maybe<Scalars['Boolean']>;
  saturdayDinner?: Maybe<Scalars['Boolean']>;
  saturdayLunch?: Maybe<Scalars['Boolean']>;
  saturdaySpeakers?: Maybe<Scalars['Boolean']>;
  thursdayReception?: Maybe<Scalars['Boolean']>;
  updatedAt: Scalars['AWSDateTime'];
  userRoleId?: Maybe<Scalars['ID']>;
  version: Scalars['Int'];
};


export type UserGroupsArgs = {
  filter?: InputMaybe<ModelUserGroupFilterInput>;
  groupId?: InputMaybe<ModelIdKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type UserGroupsRegistrarArgs = {
  filter?: InputMaybe<ModelUserGroupRegistrarFilterInput>;
  groupId?: InputMaybe<ModelIdKeyConditionInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};


export type UserPermissionsArgs = {
  filter?: InputMaybe<ModelUserPermissionFilterInput>;
  limit?: InputMaybe<Scalars['Int']>;
  nextToken?: InputMaybe<Scalars['String']>;
  permissionId?: InputMaybe<ModelIdKeyConditionInput>;
  sortDirection?: InputMaybe<ModelSortDirection>;
};

export type UserGroup = {
  __typename?: 'UserGroup';
  createdAt: Scalars['AWSDateTime'];
  group: Group;
  groupId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type UserGroupRegistrar = {
  __typename?: 'UserGroupRegistrar';
  createdAt: Scalars['AWSDateTime'];
  group: Group;
  groupId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  user: User;
  userId: Scalars['ID'];
};

export type UserPermission = {
  __typename?: 'UserPermission';
  createdAt: Scalars['AWSDateTime'];
  permission: AppPermission;
  permissionId: Scalars['ID'];
  updatedAt: Scalars['AWSDateTime'];
  user: User;
  userId: Scalars['ID'];
};
