import { IconButton, Tooltip } from '@material-ui/core';
import { Edit } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, memo, useContext } from 'react';
import { StateContext } from '../context';
import { Role, useMaxTableBodyHeight } from '../core';
import { RoleForm } from './RoleForm';
import { withModal } from './withModal';

interface Props {}

export const RolesTable: FC<Props> = withModal(
  memo(({ openModal }) => {
    const { roles } = useContext(StateContext);

    const columns: Column<Role>[] = [{ title: 'Convention Role', field: 'roleName' }];

    return (
      <MaterialTable
        title="Convention Roles"
        columns={columns.map((c) => ({ hiddenByColumnsButton: true, searchable: true, disableClick: true, ...c }))}
        data={roles || []}
        onRowClick={() => {}}
        options={{
          paging: false,
          columnsButton: true,
          maxBodyHeight: useMaxTableBodyHeight(),
          padding: 'dense',
        }}
        actions={[
          {
            icon: Edit,
            iconProps: { color: 'primary' },
            tooltip: 'edit',
            onClick: (_event, rowData) => openModal(<RoleForm existingData={rowData as Role} />, 550),
          },
        ]}
        components={{
          Action: (props) => {
            const action = props.action.action ? props.action.action(props.data) : props.action;
            return (
              <Tooltip title={action.tooltip} arrow>
                <span>
                  <IconButton
                    color={action.iconProps.color}
                    onClick={(event) => action.onClick(event, props.data)}
                    disabled={action.disabled}
                  >
                    <action.icon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        }}
      />
    );
  })
);
