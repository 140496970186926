import { Box, Button, IconButton, Tooltip, Typography } from '@material-ui/core';
import { CheckCircleOutline, Delete, Edit, PermContactCalendar, RadioButtonUnchecked } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, useContext, memo, useState } from 'react';
import { StateContext } from '../context';
import {
  AccessRole,
  isPermitted,
  PermissionName,
  REGISTRATION_OBJECT_KEYS,
  sortUsersAlpha,
  useMaxTableBodyHeight,
  User,
} from '../core';
import { Contact } from './Contact';
import { RegistrationDelete } from './RegistrationDelete';
import { RegistrationForm } from './RegistrationForm';
import { AccessAvatar } from './AccessAvatar';
import { withModal } from './withModal';
import { RegistrationDayEmojis } from './RegistrationDayEmojis';
import { GroupChip } from './GroupChip';

interface Props {}

export const RegistrationsTable: FC<Props> = withModal(
  memo(({ openModal }) => {
    const { user, permissions, registrations } = useContext(StateContext);
    const [showReceptionsOnly, setShowReceptionsOnly] = useState<boolean>(false);
    const [showWorkshopOnly, setShowWorkshopOnly] = useState<boolean>(false);

    const columns: Column<
      User & { name: string; contact: string; groupNames: string[]; groupRegistrarNames: string[]; cRole: string }
    >[] = [
      {
        title: 'Name',
        field: 'name',
        customSort: sortUsersAlpha,
        render: (user) => (
          <Box display="flex" alignItems="baseline" flexWrap="wrap">
            <Typography style={!!user.accessRole ? { marginRight: '0.5rem' } : undefined}>{user.name}</Typography>
            {!!user.accessRole && <AccessAvatar accessRole={user.accessRole as AccessRole} />}
          </Box>
        ),
      },
      {
        title: 'Groups',
        field: 'groupNames',
        render: (user) =>
          user.groupRegistrarNames
            .map((groupName, idx) => <GroupChip key={idx} groupName={groupName} isRegistrar />)
            .concat(
              user.groupNames
                .filter((groupName) => !user.groupRegistrarNames.find((x) => x === groupName))
                .map((groupName, idx) => <GroupChip key={idx} groupName={groupName} />)
            ),
      },
      { title: 'Role', field: 'cRole' },
      ...REGISTRATION_OBJECT_KEYS.map((grouping) => ({
        title: grouping,
        render: (user: User) => <RegistrationDayEmojis grouping={grouping} user={user} />,
      })),
      { title: 'Notes', field: 'notes' },
      {
        title: 'Contact',
        field: 'contact',
        hidden: true,
        hiddenByColumnsButton: false,
      },
    ];

    return (
      <MaterialTable
        title={
          <Box display="flex" flexWrap="wrap" alignItems="center" marginRight="12px">
            <Typography variant="h6" style={{ paddingRight: '16px' }}>
              Registrations
            </Typography>
            {isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop) && (
              <Button
                color={showReceptionsOnly ? 'secondary' : 'default'}
                variant={showReceptionsOnly ? 'contained' : 'outlined'}
                endIcon={showReceptionsOnly ? <CheckCircleOutline /> : <RadioButtonUnchecked />}
                onClick={() => setShowReceptionsOnly((x) => !x)}
                size="small"
                disabled={showWorkshopOnly}
                style={{ marginRight: '8px' }}
              >
                {`Receptions Only`}
              </Button>
            )}
            {isPermitted(user, permissions, PermissionName.EditPrivateReceptionsAndWorkshop) && (
              <Button
                color={showWorkshopOnly ? 'secondary' : 'default'}
                variant={showWorkshopOnly ? 'contained' : 'outlined'}
                endIcon={showWorkshopOnly ? <CheckCircleOutline /> : <RadioButtonUnchecked />}
                onClick={() => setShowWorkshopOnly((x) => !x)}
                size="small"
                disabled={showReceptionsOnly}
              >
                {`Workshop Only`}
              </Button>
            )}
          </Box>
        }
        columns={columns.map((c) => ({ hiddenByColumnsButton: true, searchable: true, disableClick: true, ...c }))}
        data={(registrations || [])
          .map((x) => ({
            ...x,
            name: `${x.firstName} ${x.lastName}`,
            contact: `${x.email} ${x.phone}`.trim(),
            groupNames: x.groups?.items?.map((item) => item?.group.groupName || '') || [],
            groupRegistrarNames: x.groupsRegistrar?.items?.map((item) => item?.group.groupName || '') || [],
            cRole: x.role?.roleName || '',
          }))
          .filter((x) => (showReceptionsOnly ? x.thursdayReception || x.fridayReception : true))
          .filter((x) => (showWorkshopOnly ? x.fridayWorkshop : true))}
        onRowClick={() => {}}
        options={{
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          columnsButton: true,
          minBodyHeight: useMaxTableBodyHeight(),
          maxBodyHeight: useMaxTableBodyHeight(),
          padding: 'dense',
        }}
        actions={[
          {
            icon: Edit,
            iconProps: { color: 'primary' },
            tooltip: 'edit',
            onClick: (_event, rowData) => openModal(<RegistrationForm existingData={rowData as User} />, 600),
          },
          (rowData) => ({
            icon: Delete,
            iconProps: { color: 'secondary' },
            tooltip: 'delete',
            onClick: (_event, rowData) => openModal(<RegistrationDelete data={rowData as User} />),
            disabled: !!rowData.accessRole,
          }),
          (rowData) => ({
            icon: PermContactCalendar,
            iconProps: { color: 'default' },
            tooltip: 'contact',
            onClick: (_event, rowData) => openModal(<Contact data={rowData as User} />),
            disabled: !rowData.phone && !rowData.email,
          }),
        ]}
        components={{
          Action: (props) => {
            const action = props.action.action ? props.action.action(props.data) : props.action;
            return (
              <Tooltip title={action.tooltip} arrow>
                <span>
                  <IconButton
                    id={`${action.tooltip}Action`}
                    color={action.iconProps.color}
                    onClick={(event) => action.onClick(event, props.data)}
                    disabled={action.disabled}
                  >
                    <action.icon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        }}
      />
    );
  })
);
