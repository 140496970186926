import { Tooltip, IconButton } from '@material-ui/core';
import { Edit, LockOpen, PermContactCalendar, RemoveCircle, Send } from '@material-ui/icons';
import MaterialTable, { Column } from 'material-table';
import React, { FC, memo, useContext, useState } from 'react';
import { StateContext } from '../context';
import { User, AccessRole, useMaxTableBodyHeight, CustomUser, sortUsersAlpha, ID, PermissionName } from '../core';
import { AccessChip } from './AccessChip';
import { Contact } from './Contact';
import { GroupChip } from './GroupChip';
import { PermissionsEdit } from './PermissionsEdit';
import { UserRemove } from './UserRemove';
import { UserResend } from './UserResend';
import { UserUpdate } from './UserUpdate';
import { withModal } from './withModal';

interface Props {}

export const UsersTable: FC<Props> = withModal(
  memo(({ openModal }) => {
    const { user, registrations } = useContext(StateContext);
    const [canManageGroupLeaders] = useState<boolean>(
      !!user?.permissions?.items
        .map((item) => item.permission.permissionName)
        .includes(PermissionName.ManageGroupLeaders)
    );
    const [isRegistrar] = useState<boolean>(user?.accessRole === AccessRole.Registrar);
    const [isAdmin] = useState<boolean>(user?.accessRole === AccessRole.Admin);

    const columns: Column<
      CustomUser & { name: string; contact: string; groupNames: string[]; groupRegistrarNames: string[]; cRole: string }
    >[] = [
      { title: 'Name', field: 'name', customSort: sortUsersAlpha },
      {
        title: 'Access',
        field: 'accessRole',
        render: (user) => <AccessChip role={user.accessRole as AccessRole} />,
      },
      {
        title: 'Groups',
        field: 'groupNames',
        render: (user) =>
          user.groupRegistrarNames
            .map((groupName, idx) => (
              <GroupChip id={`${ID.Table_Users_Groups_Chip}-${idx}`} key={idx} groupName={groupName} isRegistrar />
            ))
            .concat(
              user.groupNames
                .filter((groupName) => !user.groupRegistrarNames.find((x) => x === groupName))
                .map((groupName, idx) => <GroupChip key={idx} groupName={groupName} />)
            ),
      },
      { title: 'Role', field: 'cRole', hidden: !isAdmin },
      {
        title: 'Invitation',
        field: 'inviteAccepted',
        render: (user) => (user.inviteAccepted ? 'Accepted' : <i>Pending</i>),
      },
      {
        title: 'Contact',
        field: 'contact',
        hidden: true,
        hiddenByColumnsButton: false,
      },
    ];

    return (
      <MaterialTable
        title="Users"
        columns={columns.map((c) => ({ hiddenByColumnsButton: true, searchable: true, disableClick: true, ...c }))}
        data={(registrations || [])
          .filter((x) => !!x.accessRole)
          .filter((x) => (isRegistrar && canManageGroupLeaders ? x.accessGrantedBy?.id === user?.id : true))
          .map((x) => ({
            ...x,
            name: `${x.firstName} ${x.lastName}`,
            contact: `${x.email} ${x.phone}`.trim(),
            groupNames: x.groups?.items?.map((item) => item?.group.groupName || '') || [],
            groupRegistrarNames: x.groupsRegistrar?.items?.map((item) => item?.group.groupName || '') || [],
            cRole: x.role?.roleName || '',
          }))}
        options={{
          paging: true,
          pageSize: 10,
          pageSizeOptions: [10, 25, 50, 100],
          columnsButton: true,
          minBodyHeight: useMaxTableBodyHeight(),
          maxBodyHeight: useMaxTableBodyHeight(),
          padding: 'dense',
        }}
        onRowClick={() => {}}
        actions={[
          (rowUser) => ({
            icon: PermContactCalendar,
            iconProps: { color: 'default' },
            tooltip: 'contact',
            onClick: (_event, rowData) => openModal(<Contact data={rowData as User} />),
            disabled: !rowUser.phone && !rowUser.email,
          }),
          (rowUser) => ({
            icon: RemoveCircle,
            iconProps: { color: 'secondary' },
            tooltip: 'remove',
            onClick: (_event, rowData) => openModal(<UserRemove data={rowData as User} />),
            disabled:
              rowUser.accessRole === AccessRole.Admin ||
              (user?.accessRole === AccessRole.GroupLeader && rowUser.accessRole !== AccessRole.GroupLeader),
          }),
          (rowUser) => ({
            icon: Send,
            iconProps: { color: 'primary' },
            tooltip: 'resend',
            onClick: (_event, rowData) => openModal(<UserResend data={rowData as User} />),
            disabled:
              rowUser.inviteAccepted ||
              (user?.accessRole === AccessRole.GroupLeader && rowUser.accessRole !== AccessRole.GroupLeader),
          }),
          (_rowUser) => ({
            icon: LockOpen,
            iconProps: { color: 'primary' },
            tooltip: 'permissions',
            onClick: (_event, rowData) => openModal(<PermissionsEdit user={rowData as User} />),
            disabled: !isAdmin,
          }),
          (rowUser) => ({
            icon: Edit,
            iconProps: { color: 'primary' },
            tooltip: 'edit',
            onClick: (_event, rowData) => openModal(<UserUpdate existingData={rowData as User} />),
            disabled: rowUser.accessRole !== AccessRole.GroupLeader,
          }),
        ]}
        components={{
          Action: (props) => {
            const action = props.action.action ? props.action.action(props.data) : props.action;
            return (
              <Tooltip title={action.tooltip} arrow>
                <span>
                  <IconButton
                    id={`${action.tooltip}Action`}
                    color={action.iconProps.color}
                    onClick={(event) => action.onClick(event, props.data)}
                    disabled={action.disabled}
                  >
                    <action.icon />
                  </IconButton>
                </span>
              </Tooltip>
            );
          },
        }}
      />
    );
  })
);
