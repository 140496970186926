import jsPDF from 'jspdf';
import logoBABTL from '../assets/babtl-logo.jpg';
import logoCRTL from '../assets/crtl-logo.jpg';
import logoTOBCLE from '../assets/tobcle-logo.jpg';
import beerIcon from '../assets/beer-icon.png';
import { ApiService } from './api.service';
import { ConferenceDay, DownloadType } from './enums';
import { User } from './graphql.types';
import { UserSnapshot } from './interfaces';

const format = 'letter';
// const pageWidth = 215.9; // 215.9 mm = 8.5 in
// const pageHeight = 279.4; // 279.4 mm = 11 in
const topMargin = 28.7; // 28.7 mm = 1.13 in
const sideMargin = 6.35; // 6.35 mm = 0.25 in
const badgeWidth = 101.6; // 101.6 mm = 4 in
const badgeHeight = 76.2; // 76.2 mm = 3 in
const badgeXY = [
  [sideMargin, topMargin], // top left
  [sideMargin + badgeWidth, topMargin], // top right
  [sideMargin, topMargin + badgeHeight], // middle left
  [sideMargin + badgeWidth, topMargin + badgeHeight], // middle right
  [sideMargin, topMargin + 2 * badgeHeight], // bottom left
  [sideMargin + badgeWidth, topMargin + 2 * badgeHeight], // bottom right
];

export abstract class NameBadgeService {
  static redownload = (day: ConferenceDay, usersUpdate: UserSnapshot[], usersRemove: UserSnapshot[] = []): void => {
    NameBadgeService.generatePDF(day, usersUpdate, usersRemove);
  };

  static download = (
    day: ConferenceDay,
    type: DownloadType,
    usersUpdate: UserSnapshot[],
    usersRemove: UserSnapshot[] = []
  ): Promise<void> => {
    return ApiService.createBadgeDownload({
      input: {
        day,
        type,
        usersUpdate: JSON.stringify(usersUpdate),
        usersRemove: JSON.stringify(usersRemove),
        printed: false,
      },
    }).then(() => {
      NameBadgeService.generatePDF(day, usersUpdate, usersRemove);
    });
  };

  static downloadBlanks = (day: ConferenceDay) => {
    var doc = new jsPDF({ format });

    const eventType = day === ConferenceDay.Thursday ? 'Reception' : 'Speakers';

    for (let i = 0; i < 6; i++) {
      if (i > 0 && i % 6 === 0) doc.addPage();
      const [x, y] = badgeXY[i % 6];
      NameBadgeService.drawBadgeAttendee(doc, x, y, badgeWidth, badgeHeight, null, day);
    }

    doc.save(`${day}${eventType}BlankPasses2025.pdf`);
  };

  static downloadPress = () => {
    var doc = new jsPDF({ format });

    for (let i = 0; i < 6; i++) {
      if (i > 0 && i % 6 === 0) doc.addPage();
      const [x, y] = badgeXY[i % 6];

      // doc.setLineWidth(0.2);
      // doc.rect(x, y, badgeWidth, badgeHeight);

      const babtl = { x: x + 0.23 * badgeWidth, y: y + 1, width: 0.55 * badgeWidth, height: 0.2 * badgeWidth };
      doc.addImage(logoBABTL, 'JPEG', babtl.x, babtl.y, babtl.width, babtl.height);

      const crtl = {
        x: x + 0.1 * badgeWidth,
        y: y + badgeHeight - 0.1 * badgeWidth - 10,
        width: 0.13 * badgeWidth,
        height: 0.1 * badgeWidth,
      };
      doc.addImage(logoCRTL, 'JPEG', crtl.x, crtl.y, crtl.width, crtl.height);

      const event = { x: x + 0.5 * badgeWidth, y: babtl.y + babtl.height + 5 };
      NameBadgeService.drawEvent(doc, '2025', event.x, event.y);

      const name = { x: x + 0.5 * badgeWidth, y: event.y + 12 };
      NameBadgeService.drawName(doc, 'PRESS', name.x, name.y);
    }

    doc.save(`PressPasses2025.pdf`);
  };

  static mapUsersSnapshot = (day: ConferenceDay, users: User[]): UserSnapshot[] => {
    return users
      .filter((user) => NameBadgeService.isBadgeEligible(day, user))
      .map((user) => NameBadgeService.mapUserSnapshot(day, user));
  };

  private static generatePDF = (day: ConferenceDay, users: UserSnapshot[], remove: UserSnapshot[]) => {
    var doc = new jsPDF({ format });
    let badges = 0;

    if (remove.length > 0) {
      let remainingUsers = remove.slice(0);
      while (remainingUsers.length > 0) {
        const tenUsers = remainingUsers.slice(0, 10);
        remainingUsers = remainingUsers.slice(10);
        const [x, y] = badgeXY[badges % 6];
        NameBadgeService.drawRemoveList(doc, x, y, tenUsers, badgeWidth, badgeHeight);
        badges++;
      }
    }

    users.forEach((user) => {
      if (badges > 0 && badges % 6 === 0) doc.addPage();
      const [x, y] = badgeXY[badges % 6];
      NameBadgeService.drawBadgeAttendee(doc, x, y, badgeWidth, badgeHeight, user, day);
      badges++;
    });

    doc.save(`${day}Passes2025.pdf`);
  };

  private static mapUserSnapshot = (day: ConferenceDay, user: User): UserSnapshot => {
    const snapshot: UserSnapshot = {
      id: user.id,
      name: `${user.firstName} ${user.lastName}`,
      role: user.role?.roleName,
    };
    if (day === ConferenceDay.Thursday) {
      return snapshot;
    }
    if (day === ConferenceDay.Friday) {
      snapshot.b = user.fridayBreakfast ? 1 : undefined;
      snapshot.l = user.fridayLunch ? 1 : undefined;
      snapshot.d = user.fridayDinner ? 1 : undefined;
      snapshot.w = user.fridayWorkshop ? 1 : undefined;
      snapshot.r = user.fridayReception ? 1 : undefined;
      return snapshot;
    }
    if (day === ConferenceDay.Saturday) {
      snapshot.b = user.saturdayBreakfast ? 1 : undefined;
      snapshot.l = user.saturdayLunch ? 1 : undefined;
      snapshot.d = user.saturdayDinner ? 1 : undefined;
      return snapshot;
    }
    return snapshot;
  };

  private static isBadgeEligible = (day: ConferenceDay, user: User): boolean => {
    const eventType = day === ConferenceDay.Thursday ? 'Reception' : 'Speakers';
    return !!user[`${day.toLowerCase()}${eventType}` as keyof User];
  };

  private static drawRemoveList = (
    doc: jsPDF,
    x: number,
    y: number,
    users: UserSnapshot[],
    width: number,
    height: number
  ) => {
    // doc.setLineWidth(0.2);
    // doc.rect(x, y, width, height);

    x = x + 0.5 * width;
    y = y + 8;

    doc.setTextColor('#d82622'); // red
    doc.setFont('helvetica', 'normal', 700);
    doc.setFontSize(14);
    doc.text('Remove:', x, y, { align: 'center' });
    y = y + 6;

    users.forEach((user) => {
      doc.setTextColor(0); // black
      doc.setFont('helvetica', 'normal', 400);
      doc.setFontSize(12);
      doc.text(user.name, x, y, { align: 'center' });
      y = y + 6;
    });
  };

  private static drawBadgeAttendee = (
    doc: jsPDF,
    x: number,
    y: number,
    width: number,
    height: number,
    user: UserSnapshot | null,
    day: ConferenceDay
  ) => {
    // doc.setLineWidth(0.2);
    // doc.rect(x, y, width, height);

    // const attendeeId = { x: x + 2, y: y + 4 };
    // if (user) NameBadgeService.drawId(doc, user.id.substring(0, 4), attendeeId.x, attendeeId.y);

    const tobcle = { x: x + 0.03 * width, y: y + 4, width: 0.13 * width, height: 0.1 * width };
    if (user?.w) {
      doc.addImage(logoTOBCLE, 'JPEG', tobcle.x, tobcle.y, tobcle.width, tobcle.height);
    }

    const beer = { x: x + 0.05 * width, y: y + tobcle.height + 8, width: 0.1 * width, height: 0.1 * width };
    if (user?.r) {
      doc.addImage(beerIcon, 'JPEG', beer.x, beer.y, beer.width, beer.height);
    }

    const babtl = { x: x + 0.23 * width, y: y + 1, width: 0.55 * width, height: 0.2 * width };
    doc.addImage(logoBABTL, 'JPEG', babtl.x, babtl.y, babtl.width, babtl.height);

    const crtl = { x: x + 0.84 * width, y: y + 4, width: 0.13 * width, height: 0.1 * width };
    doc.addImage(logoCRTL, 'JPEG', crtl.x, crtl.y, crtl.width, crtl.height);

    const event = { x: x + 0.5 * width, y: babtl.y + babtl.height + 5 };
    NameBadgeService.drawEvent(
      doc,
      `2025 ${day} ${day === ConferenceDay.Thursday ? 'Reception' : 'Speakers Pass'}`,
      event.x,
      event.y
    );

    const name = { x: x + 0.5 * width, y: event.y + 12 };
    if (user) NameBadgeService.drawName(doc, user.name, name.x, name.y);

    const role = { x: x + 0.5 * width, y: name.y + 8 };
    const roleName = user?.role;
    if (roleName) NameBadgeService.drawRole(doc, roleName, role.x, role.y);

    if (day === ConferenceDay.Thursday) return;

    NameBadgeService.drawMeal(doc, 'Breakfast', !!user?.b, x + 0.2 * width, role.y + 10);
    NameBadgeService.drawMeal(doc, 'Lunch', !!user?.l, x + 0.5 * width, role.y + 10);
    NameBadgeService.drawMeal(doc, 'Dinner', !!user?.d, x + 0.8 * width, role.y + 10);
  };

  private static drawId = (doc: jsPDF, label: string, x: number, y: number) => {
    doc.setTextColor(0); // black
    doc.setFont('helvetica', 'normal', 400);
    doc.setFontSize(8);
    doc.text(label, x, y, { align: 'left' });
  };

  private static drawEvent = (doc: jsPDF, label: string, x: number, y: number) => {
    doc.setTextColor('#d82622'); // red
    doc.setFont('times', 'italic', 400);
    doc.setFontSize(13);
    doc.text(label, x, y, { align: 'center' });
  };

  private static drawName = (doc: jsPDF, label: string, x: number, y: number) => {
    doc.setTextColor(0); // black
    doc.setFont('helvetica', 'normal', 700);
    doc.setFontSize(20);
    doc.text(label, x, y, { align: 'center' });
  };

  private static drawRole = (doc: jsPDF, label: string, x: number, y: number) => {
    doc.setTextColor('#d82622'); // red
    doc.setFont('helvetica', 'italic', 400);
    doc.setFontSize(16);
    doc.text(label, x, y, { align: 'center' });
  };

  private static drawMeal = (doc: jsPDF, label: string, canAccess: boolean, x: number, y: number): void => {
    // draw label
    doc.setTextColor('#223a7e'); // blue
    doc.setFont('times', 'normal', 400);
    doc.setFontSize(12);
    doc.text(label, x, y, { align: 'center' });
    // draw red/green circle
    doc.setDrawColor(0); // black
    doc.setFillColor(canAccess ? 'green' : '#d82622'); // red
    doc.circle(x, y + 9.5, 7, 'FD');
    // add yes/no text to circle
    doc.setTextColor(0); // black
    doc.setFont('helvetica', 'normal', 400);
    doc.setFontSize(12);
    doc.text(canAccess ? 'Yes' : 'No', x, y + 11, { align: 'center' });
  };
}
