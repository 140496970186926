import { useSnackbar } from 'notistack';
import React, { FC, useContext } from 'react';
import { StateContext } from '../context';
import { ApiService, BadgeDownload } from '../core';
import { ModalConfirm } from './ModalConfirm';
import { withModal } from './withModal';

interface Props {
  data: BadgeDownload;
}

export const DownloadDelete: FC<Props> = withModal(({ data, closeModal }) => {
  const { withLoading, updateDownloads } = useContext(StateContext);
  const { enqueueSnackbar } = useSnackbar();

  const dateTime = new Date(data.createdAt).toLocaleString();

  const deleteDownload = withLoading(async () => {
    try {
      await ApiService.deleteBadgeDownload({ input: { id: data.id } });
      updateDownloads();
      closeModal();
      enqueueSnackbar(`Successfully deleted ${data.day} download from ${dateTime}`, {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar(`Failed to delete ${data.day} download from ${dateTime}`, { variant: 'error' });
    }
  });

  return (
    <ModalConfirm
      title="Delete download"
      word="Delete"
      onConfirm={deleteDownload}
      message={
        <>
          Are you sure you want to delete <b>{data.day}</b> download from <b>{dateTime}</b>?
        </>
      }
    />
  );
});
