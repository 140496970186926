import { Box, Button, TextField, Typography } from '@material-ui/core';
import React, { FC, useState } from 'react';
import { ID } from '../core';
import { withModal } from './withModal';

interface Props {
  title: string;
  message: string | JSX.Element;
  word: string;
  onConfirm: () => any;
}

export const ModalConfirm: FC<Props> = withModal(({ title, message, word, onConfirm, closeModal }) => {
  const [canConfirm, setCanConfirm] = useState(false);

  return (
    <>
      <Typography variant="overline" color="secondary">
        {title}
      </Typography>
      <Box height="10px" />
      <Typography color="primary">{message}</Typography>
      <Box height="20px" />
      <Typography color="primary">
        To confirm, type <i>{word}</i> in the box:
      </Typography>
      <Box height="10px" />
      <TextField
        variant="outlined"
        placeholder={word}
        onChange={(event) => setCanConfirm(event.target.value === word)}
        fullWidth
      />
      <Box height="30px" />
      <Box display="flex" justifyContent="flex-end">
        <Button style={{ margin: '0.5rem' }} onClick={() => closeModal()}>
          Cancel
        </Button>
        <Button
          id={ID.Modal_Confirm}
          disabled={!canConfirm}
          color="secondary"
          variant="contained"
          style={{ margin: '0.5rem' }}
          onClick={() => onConfirm()}
        >
          {word}
        </Button>
      </Box>
    </>
  );
});
