import { TextField, FilledInputProps, BaseTextFieldProps, OutlinedInputProps, InputProps } from '@material-ui/core';
import React, { FC } from 'react';
import { useFormContext, ValidationRules } from 'react-hook-form';
import NumberFormat, { FormatInputValueFunction } from 'react-number-format';

interface NumberFormatProps {
  format?: string | FormatInputValueFunction;
}

interface TextFieldProps extends BaseTextFieldProps {
  InputProps?: Partial<FilledInputProps | OutlinedInputProps | InputProps>;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => any;
}

export type InputTextProps = TextFieldProps &
  NumberFormatProps & {
    phone?: boolean;
    currency?: boolean;
    rules?: ValidationRules;
  };

export const InputText: FC<InputTextProps> = (props) => {
  const { register, errors } = useFormContext();
  const {
    phone = false,
    currency = false,
    name = phone ? 'Phone' : undefined,
    label = name
      ?.replace(/([A-Z])/g, ' $1')
      .replace(/([0-9])/g, '')
      .trim(),
    rules = {},
    variant = 'filled',
    fullWidth = true,
    inputRef,
    onChange,
    ...rest
  } = props;

  rules.required = rules.required === true ? `${label} required` : rules.required;

  const commonProps = {
    error: name && errors[name],
    fullWidth,
    label: `${label}${rules.required ? ' *' : ''}`,
    name,
    variant,
    ...rest,
  };

  return phone ? (
    <NumberFormat
      customInput={TextField}
      format={'(###) ###-####'}
      inputRef={
        inputRef ||
        register({
          validate: (value) => !value || /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/g.test(value) || 'Phone number invalid',
          ...rules,
        })
      }
      {...(commonProps as any)}
    />
  ) : currency ? (
    <NumberFormat
      customInput={TextField}
      prefix={'$ '}
      thousandSeparator={true}
      decimalScale={0}
      inputRef={inputRef || register(rules)}
      {...(commonProps as any)}
    />
  ) : (
    <TextField inputRef={inputRef || register(rules)} onChange={onChange} {...commonProps} />
  );
};
